import React, { useEffect, useRef } from 'react'

import {
  View,
  StyleSheet,
  Modal,
  Alert,
  Share,
  ActivityIndicator,
} from 'react-native';

import { useFocusEffect, useIsFocused, useNavigation} from '@react-navigation/native';
import { Text } from 'react-native';
import { FlatList, TextInput, TouchableOpacity } from 'react-native-gesture-handler';
import { useSelector } from 'react-redux';
import { RootState } from '../../services/store';
import { Account, Project, Site } from '../../services/types';
import ProjectApi from '../../services/modules/project/api';
import { AxiosResponse } from 'axios';
import Button from '../ui/Button';
import QRCode from 'react-qr-code';
import { Picker } from '@react-native-picker/picker';
import Colors from '../../constants/Colors';
import { siteReducer } from '../../services/modules/site/reducer';
import { createProject, createSite } from '../../services/helpers';
import SiteApi from '../../services/modules/site/api';
import { TraxxText } from '../ui/TraxxText';
import { TraxxTextInput } from '../ui/TraxxTextInput';

interface Props {
}

const UserAccessPanel: React.FC<Props> = ({ 
}) => {   
  const projectState = useSelector((state: RootState) => state.project);
  const authState = useSelector((state: RootState) => state.auth);

  const navigation = useNavigation();
  const isFocused = useIsFocused()

  const [users, setUsers] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const [showUser, setShowUser] = React.useState(false);
  const [showUserEditor, setShowUserEditor] = React.useState(false);
  const [showAddUser, setShowAddUser] = React.useState(false);
  const [showQRCode, setShowQRCode] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState({id: "", firstName: "", lastName: "", role: "", accessLevel: 0});
  const [newUserEmail, setNewUserEmail] = React.useState("");
  const [newUserRole, setNewUserRole] = React.useState("");
  const [newUserAccessLevel, setNewUserAccessLevel] = React.useState(0);
  const [project, setProject] = React.useState<Project>(createProject());
  const [site, setSite] = React.useState<Site>(createSite());

  let svg = useRef<any>(null);

  const showAccount = (account: any) => {
    setSelectedUser(account);

    if (projectState.accessLevel == 1) {
      setNewUserAccessLevel(account.accessLevel);
      setNewUserEmail(account.email);
      setNewUserRole(account.role);
      setShowUserEditor(true);
    } else {
      setShowUser(true);
    }
  }

  const addUser = () => {
    if (newUserEmail.trim().length == 0 || newUserRole.trim().length == 0 || newUserAccessLevel == -1) {
      alert("Please enter an email, then select a role and access level.");
      return;      
    }

    setLoading(true);
    if (projectState.id) {
      ProjectApi.addUser(projectState.id, newUserEmail, newUserRole, newUserAccessLevel, authState.id)
      .then(() => {
        setLoading(false);
        setNewUserAccessLevel(-1);
        setNewUserEmail("");
        setNewUserRole("");
        setShowAddUser(false);
        init();
        alert("Invitation sent.")
      })
    }
  }

  const saveUser = () => {
    if (newUserRole.trim().length == 0 || newUserAccessLevel == -1) {
      alert("Please select a role and access level.");
      return;      
    }

    setLoading(true);
    if (projectState.id) {
      ProjectApi.saveUser(selectedUser.id, projectState.id, newUserRole, newUserAccessLevel)
      .then(() => {
        setLoading(false);
        setNewUserAccessLevel(-1);
        setNewUserRole("");
        setShowUserEditor(false);
        init();
      })
    }
  }

  const removeUser = () => {
    if (confirm("Are you sure you want to remove this user?")) {
      if (projectState.id) {
        ProjectApi.removeUser(projectState.id, selectedUser.id)
        .then(() => {
          init();
        })
      }
    }
  }

  const init = () => {
    setLoading(true);

    if (projectState.id) {
      ProjectApi.listUsers(projectState.id)
      .then((apiResponse: AxiosResponse) => {
        setUsers(apiResponse.data);
        setLoading(false);
      });

      ProjectApi.load(projectState.id)
      .then((apiResponse: any) => {
        setProject(apiResponse.data);

        SiteApi.load(apiResponse.data.siteId)
        .then((apiResponse: AxiosResponse) => {
          setSite(apiResponse.data);
        });
      });
    }
  }

  useFocusEffect (
    React.useCallback(() => {      
      if (isFocused) {
        init();
      }
    }, [isFocused])
  );

  useEffect(() => {
    if (!loading) {
      init();
    }
  }, [projectState]);

  const drawCard = (({item} : {item: Account}) => {
    return (
    <TouchableOpacity onPress={() => showAccount(item)}>
      <View style={styles.card}>
        <View style={styles.cardInfoContainer}>
          <TraxxText style={styles.cardTitle}>{item.firstName} {item.lastName}</TraxxText>
          <TraxxText style={styles.cardSubTitle}>{item.role}</TraxxText>
        </View>
      </View>
    </TouchableOpacity>)
  })

  const downloadQRCode = async () => {
    SVGToImage({
        // 1. Provide the SVG DOM element
        svg: document.querySelector(".HpQrcode > svg"),
        // 2. Provide the format of the output image
        mimetype: "image/png",
        // 3. Provide the dimensions of the image if you want a specific size.
        //  - if they remain in auto, the width and height attribute of the svg will be used
        //  - You can provide a single dimension and the other one will be automatically calculated
        // width: "auto",
        // height: "auto",
        width: 500,
        height: 500,
        // 4. Specify the quality of the image
        quality: 1,
        // 5. Define the format of the output (base64 or blob)
        outputFormat: "base64"
    }).then(async (outputData: string) => {
        // If using base64 (outputs a DataURL)
        //  data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAfQAAAH0...
        // Or with Blob (Blob)
        //  Blob {size: 14353, type: "image/png"}
        let blob = await base64ToBlob(outputData);
        const href = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', project.title.replace(/ /g,'') + "QRCode");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }).catch(function(err){
        // Log any error
        console.log(err);
    });

    /*
    const canvas: any = document.querySelector('.HpQrcode > svg');
    console.log(canvas)
    let blob = await base64ToBlob(canvas.toDataURL());
    const href = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', project.title.replace(/ /g,'') + "QRCode");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);*/
  }
  
  const base64ToBlob = async (encoded: string) => {
    let res = await fetch(encoded);
    let blob = await res?.blob();
    return blob;
  }

  /**
   * Simple function that converts a plain SVG string or SVG DOM Node into an image with custom dimensions.
   * 
   * @param {Object} settings The configuration object to override the default settings.
   * @see https://ourcodeworld.com/articles/read/1456/how-to-convert-a-plain-svg-string-or-svg-node-to-an-image-png-or-jpeg-in-the-browser-with-javascript
   * @returns {Promise}
   */
  const SVGToImage = (settings: any) => {
    let _settings: any = {
        svg: null,
        // Usually all SVG have transparency, so PNG is the way to go by default
        mimetype: "image/png",
        quality: 0.92,
        width: "auto",
        height: "auto",
        outputFormat: "base64"
    };

    // Override default settings
    for (let key in settings) { _settings[key] = settings[key]; }

    return new Promise<string>(function(resolve, reject){
        let svgNode;

        // Create SVG Node if a plain string has been provided
        if(typeof(_settings.svg) == "string"){
            // Create a non-visible node to render the SVG string
            let SVGContainer = document.createElement("div");
            SVGContainer.style.display = "none";
            SVGContainer.innerHTML = _settings.svg;
            svgNode = SVGContainer.firstElementChild;
        }else{
            svgNode = _settings.svg;
        }

        let canvas = document.createElement('canvas');
        let context = canvas.getContext('2d'); 

        let svgXml = new XMLSerializer().serializeToString(svgNode);
        let svgBase64 = "data:image/svg+xml;base64," + btoa(svgXml);

        const image = new Image();

        image.onload = function(){
            let finalWidth, finalHeight;

            finalWidth = _settings.width;
            finalHeight = _settings.height;

            // Define the canvas intrinsic size
            canvas.width = finalWidth;
            canvas.height = finalHeight;

            // Render image in the canvas
            context!.drawImage(this, 0, 0, finalWidth, finalHeight);

            resolve(canvas.toDataURL(_settings.mimetype, _settings.quality));
        };

        // Load the SVG in Base64 to the image
        image.src = svgBase64;
    });
  }

  return (<View style={styles.componentContainer}>               
            {showUser && <Modal
              animationType="slide"
              style={styles.modalView}
              transparent={false}
              visible={showUser}
              onRequestClose={() => {
                setShowUser(!showUser);
              }}
            >
              <View style={styles.modalView}>
                <TraxxText style={styles.modalText}>Project User Access</TraxxText>  
                <TraxxText style={styles.modalSubText}>{selectedUser.firstName} {selectedUser.lastName}</TraxxText>
                <Button style={styles.entryButton} onPress={() => {setShowUser(false)}}
                  text="Close">
                </Button>
                <Button style={[styles.entryButton, {marginTop: 20}]} onPress={() => removeUser()}
                  text="Remove">
                </Button>
              </View>
            </Modal>}       
            {showUserEditor && <Modal
              animationType="slide"
              style={styles.modalView}
              transparent={false}
              visible={showUserEditor}
              onRequestClose={() => {
                setShowUserEditor(!showUserEditor);
              }}
            >
              <View style={styles.modalView}>
                <TraxxText style={styles.modalText}>Project User</TraxxText>  
                <TraxxText style={styles.modalSubText}>{selectedUser.firstName} {selectedUser.lastName}</TraxxText>         
                <View style={styles.inputView}>
                  <TraxxText style={styles.inputLabel}>Role</TraxxText>
                  <View style={styles.inputGroup}>
                    <Picker
                      selectedValue={newUserRole}
                      style={styles.dropDown}
                      onValueChange={(itemValue, itemIndex) => setNewUserRole(itemValue)}
                    >
                      <Picker.Item label="Please Select" value="" />
                      <Picker.Item label="Lead" value="Lead" />
                      <Picker.Item label="Manager" value="Manager" />
                      <Picker.Item label="Hauler" value="Hauler" />
                      <Picker.Item label="Hauling Company" value="HaulingCompany" />
                      <Picker.Item label="Reuse Site" value="Reuse Site" />
                    </Picker>
                  </View>
                </View>
                <View style={styles.inputView}>
                  <TraxxText style={styles.inputLabel}>Access Level</TraxxText>
                  <View style={styles.inputGroup}>
                    <Picker
                      selectedValue={newUserAccessLevel}
                      style={styles.dropDown}
                      onValueChange={(itemValue, itemIndex) => setNewUserAccessLevel(itemValue)}
                    >
                      <Picker.Item label="Please Select" value="-1" />
                      <Picker.Item label="Read Only" value="0" />
                      <Picker.Item label="Write" value="1" />
                    </Picker>
                  </View>
                </View>

                <Button style={styles.entryButton} onPress={() => {setShowUserEditor(false)}}
                  text="Close">
                </Button>
                <Button style={[styles.entryButton, {marginTop: 20}]} onPress={() => {saveUser()}}
                  text="Save">
                </Button>
                <Button style={[styles.entryButton, {marginTop: 20}]} onPress={() => removeUser()}
                  text="Remove">
                </Button>
              </View>
            </Modal>} 
          
            {showAddUser && <Modal
              animationType="slide"
              style={styles.modalView}
              transparent={false}
              visible={showAddUser}
              onRequestClose={() => {
                setShowAddUser(!showAddUser);
              }}
            >
              {loading &&
              <View>
                <ActivityIndicator size="large" color="#3F51B5" />
                <TraxxText style={styles.searchingText}>Saving...</TraxxText>
              </View>
              }
              {!loading && <View style={styles.modalView}>
                <TraxxText style={styles.modalText}>Add User to Project</TraxxText>        
                <View style={styles.inputView}>
                  <TraxxText style={styles.inputLabel}>Email</TraxxText>
                  <View style={styles.inputGroup}>
                    <TraxxTextInput
                      style={styles.input}
                      placeholder="Email address to invite"
                      placeholderTextColor="#003f5c"
                      value={newUserEmail}
                      onChangeText={(newUserEmail: string) => {
                        setNewUserEmail(newUserEmail);
                      }}
                    />
                  </View>
                </View>     
                <View style={styles.inputView}>
                  <TraxxText style={styles.inputLabel}>Role</TraxxText>
                  <View style={styles.inputGroup}>
                    <Picker
                      selectedValue={newUserRole}
                      style={styles.dropDown}
                      onValueChange={(itemValue, itemIndex) => setNewUserRole(itemValue)}
                    >
                      <Picker.Item label="Please Select" value="" />
                      <Picker.Item label="Lead" value="Lead" />
                      <Picker.Item label="Manager" value="Manager" />
                      <Picker.Item label="Hauler" value="Hauler" />
                      <Picker.Item label="Hauling Company" value="HaulingCompany" />
                      <Picker.Item label="Reuse Site" value="Reuse Site" />
                    </Picker>
                  </View>
                </View>
                <View style={styles.inputView}>
                  <TraxxText style={styles.inputLabel}>Access Level</TraxxText>
                  <View style={styles.inputGroup}>
                    <Picker
                      selectedValue={newUserAccessLevel}
                      style={styles.dropDown}
                      onValueChange={(itemValue, itemIndex) => setNewUserAccessLevel(itemValue)}
                    >
                      <Picker.Item label="Please Select" value="-1" />
                      <Picker.Item label="Read Only" value="0" />
                      <Picker.Item label="Write" value="1" />
                    </Picker>
                  </View>
                </View>
                <Button style={[styles.entryButton, {marginTop:20}]} onPress={() => addUser()}
                  text="Invite">
                </Button>
                <Button style={[styles.entryButton, {marginTop: 20}]} onPress={() => {setShowAddUser(false)}}
                  text="Cancel">
                </Button>
              </View>}
            </Modal>} 

            {showQRCode && <Modal
              animationType="slide"
              style={styles.modalView}
              transparent={false}
              visible={showQRCode}
              onRequestClose={() => {
                setShowQRCode(!showQRCode);
              }}
            >
              <View style={[styles.modalView, {alignItems: 'center'}]}>
                <TraxxText style={[styles.modalText, {marginBottom: 15}]}>Project QR Code</TraxxText>  
                <div className="HpQrcode"> 
                  <QRCode
                    value={"{\"projectId\":\"" + projectState.id + "\",\"siteId\":\"" + site.id + "\",\"project\":{\"id\":\"" + projectState.id + "\",\"title\":\"" + project.title + "\"},\"site\":{\"id\":\"" + site.id + "\",\"title\":\"" + site.title + "\",\"address\":\"" + site.address + "\",\"addressOptional\":\"" + site.addressOptional + "\",\"city\":\"" + site.city + "\",\"province\":\"" + site.province + "\",\"postalCode\":\"" + site.postalCode + "\",\"contactName\":\"" + site.contactName + "\",\"contactEmail\":\"" + site.contactEmail + "\",\"contactPhone\":\"" + site.contactPhone + "\"}}"}
                    size={250}
                    color="#000"
                  />
                </div>
              </View>
              <Button style={[styles.entryButton, {marginTop: 20}]} onPress={() => {downloadQRCode()}}
                text="Download">
              </Button>
              <Button style={[styles.entryButton, {marginTop: 20}]} onPress={() => {setShowQRCode(false)}}
                text="Close">
              </Button>
            </Modal>} 

            {!showQRCode && !showAddUser && !showUser && 
              <View style={styles.sectionContainer}>
                <View style={styles.sectionHeaderView}>
                  <TraxxText style={styles.sectionHeader}>PROJECT ACCESS</TraxxText>
                </View>
                <TraxxText style={styles.header}>CODE: TRAXX{projectState.id}</TraxxText>
                {projectState.accessLevel == 1 && <Button onPress={() => {setNewUserEmail(""); setNewUserRole(""); setShowAddUser(true);}}
                  style={styles.button}
                  text="Invite">
                </Button>}
                <Button onPress={() => {setShowQRCode(true);}}
                  style={styles.button}
                  text="QR Code">
                </Button>
                <View style={styles.subSectionHeaderView}>
                  <TraxxText style={styles.sectionHeader}>PEOPLE WITH ACCESS</TraxxText>
                </View>
                <FlatList
                  keyExtractor={(item: Account, index) => item.id.toString() + index}
                  data={users}
                  renderItem={drawCard}
                />
              </View>}
          </View>
  )
};
              
const styles = StyleSheet.create({
  inputContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },

  componentContainer: {   
    backgroundColor: Colors.white, 
    padding: "1%",
    borderColor: Colors.borderGrey,
    borderWidth: 1,
    borderRadius: 10,
    width: "100%"
  },

  inputView: {
    backgroundColor: "#fff",
    borderRadius: 30,
    marginBottom: 10,
  },

  input: {
    borderRadius: 5,
    borderWidth: 1,
    borderColor: Colors.borderGrey,
    fontSize: 16,
    padding: 10,
    flex: 1,
  },

  header: {
    marginTop: 10,
    fontSize: 16,
    fontWeight: 'bold',
  },

  inputGroup: {
    flexDirection: 'row',
    marginLeft: 5,
  },

  inputBox: {
    borderRadius: 5,
    borderWidth: 1,
    borderColor: Colors.borderGrey,    
    fontSize: 16,
    padding: 10,
  },

  inputText: {
    fontSize: 16,
    fontWeight: 'bold',
  },

  inputLabel: {
    fontSize: 16,
    marginTop: 10,
    marginLeft: 5,
    marginRight: 10,
  },

  searchingText: {
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold'
  },

  comingSoon: {
    fontSize: 20,
    color: "#fff"
  },

  button : {
    marginTop: 10
  },
  buttonOn: {
    backgroundColor: Colors.primary,
    margin: 4,
  },
  buttonOff: {
    backgroundColor: Colors.primaryBrown,
    margin: 4,
  },
  sectionContainer: {
    width: "100%",
    padding: 10,
  },
  sectionHeader: {
    fontWeight: 'bold',
    fontSize: 20,
    flex: 1,
  },
  sectionHeaderView: {
    flexDirection: 'row',
  },
  subSectionHeaderView: {
    flexDirection: 'row',
    marginTop: 20,
  },
  sectionHeaderButton : {
    margin: 4,
  },
  card: {
    borderColor: Colors.borderDarkGrey,
    borderRadius: 5,
    borderWidth: 1,
    padding: 10,
    marginTop: 10,
  }, 

  cardInfoContainer: {
  },

  cardTitle: {
    color: Colors.text,
    fontSize: 20,
    fontWeight: 'bold',
  },

  cardSubTitle: {
    color: Colors.text,
    fontSize: 14,
  },

  modalView: {
    width: "98%",
    margin: "1%",
    backgroundColor: Colors.white,
    borderColor: Colors.white
  },

  modalText: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 20,
    marginHorizontal: 20,
  },

  modalSubText: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 18,
    margin: 20,
  },

  entryButton: {
    marginLeft: 10,
    marginTop: 10,
  },
  stat: {
    fontSize: 26,
    fontWeight: 'bold',
  },

  statTitle: {
    fontSize: 20,
  },

  statsContainer: {
    alignItems: 'center',
    marginHorizontal: 5,
    marginVertical: 20,
  },

  container: {
    flexDirection: 'row'
  },

  mainContainer: {
    paddingLeft: "1%",
    paddingTop: "1%",
    paddingRight: "1%",
    width: "68%",
  },

  sideContainer: {
    paddingTop: "1%",
    paddingRight: "1%",
    width: "29%",
  },

  dropDown: {
    borderRadius: 5,
    borderWidth: 1,
    borderColor: Colors.borderGrey,    
    fontSize: 16,
    padding: 10,
    width: "100%"
  }
});
export default UserAccessPanel