export const LOGIN_LOADING = 'AUTH/LOGIN_LOADING';
export const LOGIN_SUCCESS = 'AUTH/LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'AUTH/LOGIN_FAILED';
export const AUTHENTICATION_ERROR = 'AUTH/AUTHENTICATION_ERROR';
export const REFRESH_TOKEN = 'AUTH/REFRESH_TOKEN';
export const UPDATE_ACCOUNT = 'AUTH/UPDATE_ACCOUNT';
export const LOG_OUT = 'AUTH/LOG_OUT';
export const UPDATE_VEHICLE = 'AUTH/UPDATE_VEHICLE';
export const UPDATE_PREFERENCES = 'ACCOUNT/UPDATE_PREFERENCES';
export const UPDATE_SUBSCRIBED = 'ACCOUNT/UPDATE_SUBSCRIBED';
export const UPDATE_ORGANIZATION = 'ACCOUNT/UPDATE_ORGANIZATION';
export const UPDATE_FIRSTTIME = 'ACCOUNT/UPDATE_FIRSTTIME';
export const UPDATE_FIRSTTIMETRUCKS = 'ACCOUNT/UPDATE_FIRSTTIMETRUCKS';

export interface AuthDispatchTypes {
  type: string;
  payload?: any;
}

export interface AuthState {
  authenticated: boolean;
  error: string;
  id: string;
  firstName: string;
  lastName: string;
  role: string;
  email: string;
  token: string;
  refreshToken: string;
  profilePhoto: string;
  vehicleId: string;
  metric: number;
  organizationId: number;
  organization: string;
  subscribed: boolean;
  subscriptionOwner: boolean;
  subscriptionId: number;
  firstTime: boolean;
  firstTimeTrucks: boolean;
}