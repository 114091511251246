import axiosInstance from "../../interceptor";

const ProjectApi = {
  delete: (id: number) => axiosInstance.get('/project/'  + id + '/delete'),
  labPending: () => axiosInstance.get('/project/documents/pending'),
  save: (title: string, address: string, city: string, province: string, postalCode: string) => axiosInstance.post('/project', {title: title, address: address, city: city, province: province, postalCode: postalCode}),
  update: (id: number, title: string, address: string, city: string, province: string, postalCode: string) => axiosInstance.post('/project', {id: id, title: title, address: address, city: city, province: province, postalCode: postalCode}),
  saveUnverified: (organizationId: number, title: string) => axiosInstance.post('/project/unverified', {title: title, organizationId: organizationId}),
  updateVolume: (projectId: number, volume: number) => axiosInstance.post('/project/volume/update', {id: projectId, targetVolume: volume}),
  load: (projectId: number) => axiosInstance.get('/project/' + projectId),
  stats: () => axiosInstance.get('/project/stats'),
  myStatsToday: () => axiosInstance.get('/project/mystats/today'),
  statsToday: (projectId: number) => axiosInstance.get('/project/stats/today/' + projectId),
  projectGraph: (id: number) => axiosInstance.get('/project/' + id + '/graph'),
  graph: () => axiosInstance.get('/project/graph'),
  projectStats: (projectId: number) => axiosInstance.get('/project/stats/' + projectId),
  my: () => axiosInstance.get('/project/my'),
  myUnverified: () => axiosInstance.get('/project/my/unverified'),
  addUser: (projectId: number, email: string, role: string, accessLevel: number, fromAccountId: string) => axiosInstance.post('/project/users/add', {projectId: projectId, email: email, role: role, accessLevel: accessLevel, fromAccountId: fromAccountId}),
  saveUser: (accountId: string, projectId: number, role: string, accessLevel: number) => axiosInstance.get('/project/' + projectId + '/users/update/' + accountId + '/-/' + role + '/' + accessLevel),
  removeUser: (projectId: number, accountId: string) => axiosInstance.get('/project/' + projectId + '/users/' + accountId + '/remove'),
  listUsers: (projectId: number) => axiosInstance.get('/project/' + projectId + '/users'),
  search: (keyword: string) => axiosInstance.get('project/search/' + keyword),
  searchSite: (keyword: string) => axiosInstance.get('project/searchsite/' + keyword),
  listUsingGPS: (latitude: number, longitude: number) => axiosInstance.get('project/list/' + latitude + '/' + longitude),
  list: () => axiosInstance.get('project/list/'),
  addDocument: (postData: FormData) => axiosInstance.post('/project/document', postData),   
  listDocuments: (projectId: number) => axiosInstance.get('project/' + projectId + '/documents'),
  listDocumentTypes: () => axiosInstance.get('project/documenttypes'),
  getDocument: (documentId: number) => axiosInstance.get('project/document/' + documentId),
  getLocation: (projectId: number) => axiosInstance.get('/project/location/' + projectId),
  markCompleted: (projectId: number) => axiosInstance.get('/project/completed/' + projectId),
  markIncomplete: (projectId: number) => axiosInstance.get('/project/incomplete/' + projectId),
  projectReuseSites: (projectId: number) => axiosInstance.get('/project/reusesites/' + projectId),
  projectCompanies: (projectId: number) => axiosInstance.get('/project/companies/' + projectId),
  projectHaulers: (projectId: number) => axiosInstance.get('/project/haulers/' + projectId),
};

export default ProjectApi;