import React, { useEffect } from 'react'

import {
  View,
  StyleSheet,
  Modal,
  Alert,
} from 'react-native';

import { Text } from 'react-native';
import { TextInput } from 'react-native-gesture-handler';
import { useSelector } from 'react-redux';
import store, { RootState } from '../../services/store';
import ProjectApi from '../../services/modules/project/api';
import Button from '../ui/Button';
import Colors from '../../constants/Colors';
import Checkbox from 'expo-checkbox';
import { convertToCubicMeters } from '../../utility/conversion';
import { useNavigation } from '@react-navigation/native';
import { TraxxText } from '../ui/TraxxText';
import { TraxxTextInput } from '../ui/TraxxTextInput';

interface Props {
  onSaved?: () => void;
  onEditing?: () => void;
}

const StatusEditor: React.FC<Props> = ({ 
  onSaved,
  onEditing
}) => {   
  const navigation = useNavigation();

  const projectState = useSelector((state: RootState) => state.project);

  const [showEditor, setShowEditor] = React.useState(false);
  const [completed, setCompleted] = React.useState(false);
  const [statusTitle, setStatusTitle] = React.useState("");
  const [volume, setVolume] = React.useState(0);
  const [volumeType, setVolumeType] = React.useState(0);
  const [volumeTypeTitle, setVolumeTypeTitle] = React.useState("Cu. Meters");

  const deleteProject = () => {
    if (confirm("Are you sure you want to delete this project?")) {
      removeProject();
    }
  };

  const removeProject = () => {
    if (projectState.id) {
      ProjectApi.delete(projectState.id)
      .then(() => {
        navigation.goBack();
      })
    }
  };

  const saveVolume = () => {
    if (volume == 0) {
      alert("Please enter a target volume.");
      return;      
    }

    let storeVolume = volume;

    if (volumeType == 0) {
      storeVolume = convertToCubicMeters(storeVolume);
      setVolume(Math.round(storeVolume));
      setVolumeType(1);
    }

    if (projectState.id) {
      ProjectApi.updateVolume(projectState.id, Math.round(storeVolume))
      .then(() => {
        setShowEditor(false);

        if (onSaved) {
          onSaved()
        }
      })

      if (completed) {
        setStatusTitle("Completed");
        ProjectApi.markCompleted(projectState.id);      
      } else {
        setStatusTitle("In progress");
        ProjectApi.markIncomplete(projectState.id);      
      }
    }
  }

  const editing = () => {
    if (onEditing) {
      onEditing()
    }
    setShowEditor(true);
  }

  useEffect(() => {    
    setVolumeTypeTitle("Cu. Meters")
    setVolumeType(1)

    if (projectState.id) {
      ProjectApi.load(projectState.id)
      .then((apiResponse: any) => {
        setCompleted(apiResponse.data.complete);

        if (apiResponse.data.complete) {
          setStatusTitle("Completed");
        } else {
          setStatusTitle("In progress");
        }

        setVolume(apiResponse.data.targetVolume);
      })
    }
  }, [projectState])

  return (<View style={styles.componentContainer}>               
            {showEditor && <Modal
              animationType="slide"
              style={styles.modalView}
              transparent={false}
              visible={showEditor}
              onRequestClose={() => {
                setShowEditor(!showEditor);
              }}
            >
              <View>     
                <TraxxText style={styles.modalText}>DETAILS</TraxxText>  
                <TraxxText style={styles.inputLabel}>Status</TraxxText>
                <View style={[styles.inputView, {flexDirection:'row'}]}>
                  <Checkbox
                    value={completed}
                    onValueChange={setCompleted}
                    style={styles.checkbox}
                  />
                  <TraxxText style={{marginTop: 8}}>Completed</TraxxText>
                </View>
                <TraxxText style={styles.inputLabel}>Target Volume</TraxxText>
                <View style={styles.inputView}>
                  <TraxxTextInput
                    style={styles.input}
                    onChangeText={(value: string) => {
                      let numericValue = 0;

                      if (value.trim().length > 0) {
                        numericValue = parseFloat(value.replace(/[^0-9.]/g, ''));
                      }
                      setVolume(numericValue)
                    }}
                    value={volume.toString()}
                    placeholder="Enter target volume"
                    keyboardType="number-pad"
                    returnKeyType="next"
                  />
                  <TraxxText style={styles.volumeType}>{volumeTypeTitle}</TraxxText>
                </View>
                <View style={{marginBottom: 10, flexDirection: 'row'}}>                
                  <Button 
                    style={volumeType == 0 ? styles.buttonOn : styles.buttonOff}
                    onPress={() => {
                      setVolumeTypeTitle("Tonne")
                      setVolumeType(0)
                    }}
                    text="Tonne">
                  </Button>
                  <Button
                    style={volumeType == 1 ? styles.buttonOn : styles.buttonOff}
                    onPress={() => {
                      setVolumeTypeTitle("Cu. Meters")
                      setVolumeType(1)
                    }}
                    text="Cu. Meter">
                  </Button>
                </View>
                <Button style={[styles.entryButton, {marginBottom: 20}]} onPress={() => saveVolume()}
                  text="Save">
                </Button>
                <Button style={styles.entryButton} onPress={() => {setShowEditor(false)}}
                  text="Cancel">
                </Button>
                <Button style={[styles.entryButton, {marginTop: 50}]} onPress={() => {deleteProject()}}
                  text="Delete Project">
                </Button>
              </View>
            </Modal>} 
          
            {!showEditor && 
              <View style={styles.sectionContainer}>
                <View style={styles.sectionHeaderView}>
                  <TraxxText style={styles.sectionHeader}>DETAILS</TraxxText>
                </View>
                <TraxxText style={styles.header}>Status</TraxxText>
                <TraxxText>{statusTitle}</TraxxText>
                <TraxxText style={styles.header}>Target Volume</TraxxText>
                <TraxxText>{volume} {volumeTypeTitle}</TraxxText>
                {projectState.accessLevel == 1 && <Button onPress={() => {editing()}}
                  style={styles.button}
                  text="Edit">
                </Button>}
              </View>}
          </View>
  )
};

const styles = StyleSheet.create({
  inputContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },

  componentContainer: {   
    backgroundColor: Colors.white, 
    padding: "1%",
    borderColor: Colors.borderGrey,
    borderWidth: 1,
    borderRadius: 10,
    width: "100%"
  },

  inputView: {
    backgroundColor: "#fff",
    borderRadius: 30,
    marginBottom: 10,
    flexDirection: 'row'
  },

  input: {
    borderRadius: 5,
    borderWidth: 1,
    borderColor: Colors.borderGrey,
    fontSize: 16,
    padding: 10,
    flex: 1,
  },

  inputGroup: {
    flexDirection: 'row',
    marginLeft: 5,
  },

  header: {
    marginTop: 10,
    fontSize: 16,
    fontWeight: 'bold',
  },

  inputBox: {
    borderRadius: 5,
    borderWidth: 1,
    borderColor: Colors.borderGrey,    
    fontSize: 16,
    padding: 10,
  },

  inputText: {
    fontSize: 16,
    fontWeight: 'bold',
  },

  inputLabel: {
    fontSize: 16,
    marginTop: 10,
    marginLeft: 5,
    marginRight: 10,
  },

  searchingText: {
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold'
  },

  comingSoon: {
    fontSize: 20,
    color: "#fff"
  },

  button : {
    marginTop: 10
  },
  buttonOn: {
    backgroundColor: Colors.primary,
    margin: 4,
  },
  buttonOff: {
    backgroundColor: Colors.primaryBrown,
    margin: 4,
  },
  sectionContainer: {
    width: "100%",
    padding: 10,
  },
  sectionHeader: {
    fontWeight: 'bold',
    fontSize: 20,
    flex: 1,
  },
  sectionHeaderView: {
    flexDirection: 'row',
  },
  subSectionHeaderView: {
    flexDirection: 'row',
    marginTop: 20,
  },
  sectionHeaderButton : {
    margin: 4,
  },
  card: {
    borderColor: Colors.borderDarkGrey,
    borderRadius: 5,
    borderWidth: 1,
    padding: 10,
    marginTop: 10,
  }, 

  cardInfoContainer: {
  },

  cardTitle: {
    color: Colors.text,
    fontSize: 20,
    fontWeight: 'bold',
  },

  cardSubTitle: {
    color: Colors.text,
    fontSize: 14,
  },

  modalView: {
    width: "98%",
    margin: "1%",
    backgroundColor: Colors.white,
    borderColor: Colors.white
  },

  modalText: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 20,
    marginHorizontal: 20,
  },

  modalSubText: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 18,
    margin: 20,
  },

  entryButton: {
    marginTop: 10,
  },
  stat: {
    fontSize: 26,
    fontWeight: 'bold',
  },

  statTitle: {
    fontSize: 20,
  },

  statsContainer: {
    alignItems: 'center',
    marginHorizontal: 5,
    marginVertical: 20,
  },

  container: {
    flexDirection: 'row'
  },

  mainContainer: {
    paddingLeft: "1%",
    paddingTop: "1%",
    paddingRight: "1%",
    width: "68%",
  },

  sideContainer: {
    paddingTop: "1%",
    paddingRight: "1%",
    width: "29%",
  },

  dropDown: {
    borderRadius: 5,
    borderWidth: 1,
    borderColor: Colors.borderGrey,    
    fontSize: 16,
    padding: 10,
    width: "100%"
  },
  volumeType: {
    marginRight: 10,
    marginTop: 10,
    marginLeft: 5,
    fontSize: 20
  },
  checkbox: {
    margin: 8,
  },
});
export default StatusEditor