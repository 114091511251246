import React, { useEffect } from 'react'

import {
  View,
  StyleSheet,
  ActivityIndicator,
  FlatList,
  TouchableOpacity,
} from 'react-native';

import { Text } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import Colors from '../../constants/Colors';
import { RootState } from '../../services/store';
import Moment from 'react-moment';
import { HaulLogEntry } from '../../services/types';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dayjs from 'dayjs'
import ReportApi from '../../services/modules/report/api';
import Button from '../ui/Button';
import { convertToTons } from '../../utility/conversion';
import Select from 'react-select';
import ProjectApi from '../../services/modules/project/api';
import { AxiosResponse } from 'axios';
import { baseURL } from '../../services/global';
import { useNavigation } from '@react-navigation/native';
import { selectEntry } from '../../services/modules/haul/actions';
import { TraxxText } from '../ui/TraxxText';

interface Props {
}

const HaulLogProjectReport: React.FC<Props> = ({ 
}) => {   
  const dispatch = useDispatch();
  const navigation = useNavigation();

  const authState = useSelector((state: RootState) => state.auth);
  const projectState = useSelector((state: RootState) => state.project);

  const [loading, setLoading] = React.useState(false);
  const [searched, setSearched] = React.useState(false);
  const [reportData, setReportData] = React.useState<any>([]);
  const [startDate, setStartDate] = React.useState<Date>(new Date());
  const [endDate, setEndDate] = React.useState<Date>(new Date());
  const [weightUnits, setWeightUnits] = React.useState('');
  const [selectedCompany, setSelectedCompany] = React.useState<any>({value: 0, label: "All Companies"});
  const [selectedReuseSite, setSelectedReuseSite] = React.useState<any>({value: 0, label: "All Sites"});
  const [selectedHauler, setSelectedHauler] = React.useState<any>({value: "", label: "All Haulers"});
  const [companies, setCompanies] = React.useState<any>([]);
  const [reuseSites, setReuseSites] = React.useState<any>([]);
  const [haulers, setHaulers] = React.useState<any>([]);
  
  useEffect(() => {
    var start = dayjs().startOf('day')
    var end = dayjs().endOf('day')

    setStartDate(start.toDate());
    setEndDate(end.toDate());

    if (authState.metric == 0) {
      setWeightUnits("tonnes");
    } else {
      setWeightUnits("cu. meters");
    }
  }, [authState]);

  useEffect(() => {
    setLoading(true);

    ProjectApi.projectCompanies(projectState.id!)
    .then((apiResponse: AxiosResponse<any>) => {
      let companySelectList: any = [{value: 0, label: "All Companies"}];

      apiResponse.data.map((val: any, index: number) => {
        companySelectList.push({value: val.id, label: val.title});
      });
        
      setCompanies(companySelectList);
    });
    
    ProjectApi.projectReuseSites(projectState.id!)
    .then((apiResponse: AxiosResponse<any>) => {
      let reuseSelectList: any = [{value: 0, label: "All Sites"}];

      apiResponse.data.map((val: any, index: number) => {
        reuseSelectList.push({value: val.id, label: val.title});
      });
        
      setReuseSites(reuseSelectList);
    });
    
    ProjectApi.projectHaulers(projectState.id!)
    .then((apiResponse: AxiosResponse<any>) => {
      let haulerSelectList: any = [{value: "", label: "All Haulers"}];

      apiResponse.data.map((val: any, index: number) => {
        haulerSelectList.push({value: val.id, label: val.firstName + " " + val.lastName});
      });
        
      setHaulers(haulerSelectList);
      setLoading(false);
    });
  }, [projectState]);

  const runReport = () => {
    setLoading(true);
    setSearched(true);

    let hauler = "-";

    if (selectedHauler.value != "") {
      hauler = selectedHauler.value;
    }
  
    ReportApi.projectHaulLog(projectState.id!, startDate!.toISOString(), endDate!.toISOString(), hauler, selectedCompany.value, selectedReuseSite.value)
    .then((apiResponse: any) => {
      setReportData(apiResponse.data)
      setLoading(false)
    });
  }

  const downloadExcel = () => {
    setLoading(true);

    let hauler = "-";

    if (selectedHauler.value != "") {
      hauler = selectedHauler.value;
    }
    
    let curDate = new Date();
    let filename = "haullog_" + curDate.getDate() + "_" + (curDate.getMonth() + 1) + "_" + curDate.getFullYear() + ".xlsx";

    fetch(baseURL + '/report/haullogprojectexcel/' + projectState.id! + '/' + startDate!.toISOString() + '/' + endDate!.toISOString() + '/' + hauler + '/' + selectedCompany.value + '/' + selectedReuseSite.value,
    { headers: new Headers({
      'Authorization': 'bearer ' + authState.token, 
      'Content-Type': 'application/x-www-form-urlencoded'
    })})    
    .then(res => res.blob()) 
    .then(blob => {
      try {
        const href = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoading(false)
      } catch(error) {
        setLoading(false)
        console.log(error)
      }
    });
  }

  const checkStartDate = (date: any) => {
    if (date != null) {
      setStartDate(date);
    }
  }
  
  const checkEndDate = (date: any) => {
    if (date != null) {
      setEndDate(date);
    }
  }

  const openHaulEntry = (entry: HaulLogEntry) => {
    dispatch(selectEntry(entry.id));
    navigation.navigate("HaulLogEntryViewer");
  };
  
  const drawCard = (({item} : {item: any}) => {
    return (<TouchableOpacity onPress={() => openHaulEntry(item)} key={item.id.toString()}>
              <View style={styles.card}>
                <View style={styles.cardInfoContainer}>
                  <View style={styles.cardDate}>
                    <Moment element={Text} format="ddd" style={styles.cardDateDay}>{item.pickUpDate}</Moment>
                    <Moment element={Text} format="D" style={styles.cardDateDate}>{item.pickUpDate}</Moment>
                    <Moment element={Text} format="MMM" style={styles.cardDateMonth}>{item.pickUpDate}</Moment>
                  </View>
                  <View style={styles.cardDetail}>
                    <TraxxText style={styles.cardDetailPickUp}>{item.pickUpSite.title}</TraxxText>
                    <TraxxText style={styles.cardDetailWeight}>{authState.metric == 0 ? convertToTons(item.weight).toFixed(2): item.weight.toFixed(2)} {weightUnits}</TraxxText>
                    <View>
                      <TraxxText style={styles.cardDetailStatus}>{item.status}</TraxxText>
                    </View>
                  </View>
                </View>
              </View>
            </TouchableOpacity>)
  })

  const customStyles = {    
    menuPortal: (provided: any, state: any) => ({
      ...provided,
      zIndex: 9999,
    }),

    control: (provided: any, state: any) => ({
      ...provided,
      borderRadius: 20,
      borderColor: Colors.borderGrey
    }),

    multiValue: (provided: any, state: any) => ({
      ...provided,
      borderRadius: 15,
      fontSize: 18,
    })
  }

  return (<View style={styles.componentContainer}>         
            {loading &&
              <View style={{marginTop: 40}}>
                <ActivityIndicator size="large" color="#3F51B5" />
                <TraxxText style={styles.loadingText}>Loading...</TraxxText>
              </View>
            }
            {!loading && <View>
              <TraxxText style={styles.header}>Enter criteria and click "Search" to search haul logs.</TraxxText>   
              <View style={{flexDirection: 'row'}}>
                <View style={styles.inputView}>
                  <TraxxText style={styles.inputLabel}>Start Date</TraxxText>
                  <DatePicker className="date-picker-wrapper" selected={startDate} onChange={(date) => checkStartDate(date)} />
                </View>      
                <View style={styles.inputView}>
                  <TraxxText style={styles.inputLabel}>End Date</TraxxText>
                  <DatePicker className="date-picker-wrapper" selected={endDate} onChange={(date) => checkEndDate(date)} /> 
                </View>    
                <View style={styles.inputView}>
                  <TraxxText style={styles.inputLabel}>Hauler</TraxxText>
                  <Select
                    menuPortalTarget={document.body} 
                    styles={customStyles}
                    name="hauler"
                    defaultValue={selectedHauler}
                    options={haulers}
                    onChange={(data: any) => setSelectedHauler(data)}
                    closeMenuOnSelect={false}/>
                </View> 
                <View style={styles.inputView}>
                  <TraxxText style={styles.inputLabel}>Hauling Company</TraxxText>
                  <Select
                    menuPortalTarget={document.body} 
                    styles={customStyles}
                    name="company"
                    defaultValue={selectedCompany}
                    options={companies}
                    onChange={(data: any) => setSelectedCompany(data)}
                    closeMenuOnSelect={false}/>
                </View>  
                <View style={styles.inputView}>
                  <TraxxText style={styles.inputLabel}>Site</TraxxText>
                  <Select
                    menuPortalTarget={document.body} 
                    styles={customStyles}
                    name="resuse"
                    defaultValue={selectedReuseSite}
                    options={reuseSites}
                    onChange={(data: any) => setSelectedReuseSite(data)}
                    closeMenuOnSelect={false}/>
                </View>   
                <View style={styles.inputViewSmall}>  
                  <Button onPress={() => {runReport();}}
                    style={[styles.button,{marginTop: 30}]}
                    text="Search">
                  </Button>
                </View>
                <View style={styles.inputViewSmall}>  
                  <Button onPress={() => {downloadExcel();}}
                    style={[styles.button,{marginTop: 30}]}
                    text="Excel">
                  </Button>
                </View>
              </View>
            </View>}
            {!loading && searched && reportData.length == 0 && <TraxxText style={{margin:20, textAlign: 'center', fontSize: 20, fontWeight:'bold'}}>No hauls in the specified period.</TraxxText>}
            {!loading && reportData.length > 0 && 
              <FlatList
                keyExtractor={(item: HaulLogEntry, index) => item.id.toString() + index}
                data={reportData}
                renderItem={drawCard}
            />}
          </View>
  )
};

const styles = StyleSheet.create({ 
  componentContainer: {   
    backgroundColor: Colors.white, 
    padding: "1%",
    margin: "1%",
    borderColor: Colors.borderGrey,
    borderWidth: 1,
    borderRadius: 10,
    width: "98%"
  },
  loadingText: {
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold'
  },
  map: {
    backgroundColor: Colors.white, 
    width: "70%",    
    height: 600,
    borderWidth: 1, 
    borderRadius: 10, 
    borderColor: Colors.borderGrey,
    padding: 10,
  },
  panel: {
    backgroundColor: Colors.white, 
    width: "28%",
    marginHorizontal: "1%",
    borderWidth: 1, 
    borderRadius: 10, 
    borderColor: Colors.borderGrey,
    padding: 10,
  },

  heading: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  
  card: {
    borderColor: Colors.borderDarkGrey,
    borderRadius: 5,
    borderWidth: 1,
    padding: 10,
    marginTop: 10,
  }, 

  cardInfoContainer: {
    flexDirection: 'row'
  },

  cardTitle: {
    color: Colors.text,
    fontSize: 20,
    fontWeight: 'bold',
  },

  cardSubTitle: {
    color: Colors.text,
    fontSize: 16,
  },

  cardContent: {
    color: Colors.text,
    fontSize: 14,
  },
  
  cardDate: {
    marginVertical: 10,
    marginLeft: 10,
    marginRight: 20,
  },

  cardDetail: {
    marginTop: 10,
    marginBottom: 10,
    paddingLeft: 20,
    borderColor: Colors.borderGrey,
    borderLeftWidth: 1,
  },

  cardDateDay: {
    color: Colors.textGrey,
    fontSize: 16,
    textAlign: 'center',
  },

  cardDateDate: {
    color: Colors.text,
    fontSize: 20,
    fontWeight: 'bold',
    textAlign: 'center',
  },

  cardDateMonth: {
    color: Colors.primary,
    fontSize: 16,
    textAlign: 'center',
  },

  cardDetailPickUp: {
    color: Colors.text,
    fontSize: 20,
    fontWeight: 'bold',
  },

  cardDetailWeight: {
    color: Colors.textGrey,
  },

  cardDetailStatus: {
    alignSelf: 'flex-start',
    marginTop: 10,
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderRadius: 10,
    backgroundColor: Colors.primary,
    color: Colors.white,
  },

  button : {
    marginTop: 10
  },
  
  inputView: {
    width: "16%",
    padding: 10,
  },
  
  inputViewSmall: {
    width: "8%",
    padding: 10,
  },

  inputGroup: {
    flexDirection: 'row',
    marginLeft: 5,
  },

  inputLabel: {
    fontSize: 16,
    marginTop: 10,
    marginLeft: 5,
    marginRight: 10,
  },
  
  input: {
    height: 50,
    paddingVertical: 10,
    paddingHorizontal: 20,
    margin: 10,
    flex: 1,
    borderColor: Colors.borderGrey,
    borderWidth: 1,
    borderRadius: 20
  },

  header: {
    fontSize: 16,
    marginLeft: 5,
  },

  tableHeader: { height: 50, backgroundColor: '#537791' },
  tableText: { textAlign: 'center', fontWeight: '100' },
  tableRow: { height: 40, backgroundColor: '#E7E6E1' }
});
export default HaulLogProjectReport