import React from 'react'

import {
  PieChart,
} from 'react-native-chart-kit'

import {
  View,
  StyleSheet,
  Dimensions,
  ActivityIndicator,
} from 'react-native';

import {useFocusEffect, useIsFocused} from '@react-navigation/native';
import { Text } from 'react-native';
import ProjectApi from '../../services/modules/project/api';
import Colors from '../../constants/Colors';
import { TraxxText } from '../ui/TraxxText';

interface Props {
}

const DashboardGraphs: React.FC<Props> = ({ 
}) => {   
  const isFocused = useIsFocused()

  const [chartData, setChartData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [noData, setNoData] = React.useState(false);
  
  const init = () => {
    setLoading(true);

    ProjectApi.graph()
    .then((apiResponse: any) => {
      setChartData(apiResponse.data);
      setLoading(false);
      
      if (apiResponse.data[0].total == 0 && apiResponse.data[1].total == 0) {
        setNoData(true);
      }
    });
  }

  useFocusEffect (
    React.useCallback(() => {      
      if (isFocused) {
        init();
      }
    }, [isFocused])
  );

  const width = Dimensions.get("window").width;

  return (<View style={styles.componentContainer}>         
            {loading &&
              <View style={{marginTop: 40}}>
                <ActivityIndicator size="large" color="#3F51B5" />
                <TraxxText style={styles.loadingText}>Loading...</TraxxText>
              </View>
            }
            {!loading && <View>  
              <TraxxText style={styles.sectionHeader}>Soil Information</TraxxText>
              <View style={styles.container}>
                {noData && <TraxxText style={{textAlign: 'center'}}>No projects</TraxxText>}
                {!noData && <PieChart
                    data={chartData}
                    chartConfig={{
                      backgroundColor: '#e26a00',
                      backgroundGradientFrom: '#fb8c00',
                      backgroundGradientTo: '#ffa726',
                      decimalPlaces: 2, // optional, defaults to 2dp
                      color: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
                      style: {
                        borderRadius: 16
                      }}}
                    width={width * 0.25}
                    height={220}
                    accessor="total"
                    backgroundColor="transparent"
                    paddingLeft="15"
                  />}
              </View>
            </View>}
          </View>
  )
};

const styles = StyleSheet.create({ 
  stat: {
    fontSize: 26,
    fontWeight: 'bold',
    textAlign: 'center'
  },
  statsHeader: {
    fontSize: 20,
    fontWeight: 'bold',
    textAlign: 'center'
  },
  statsContainer: {
    width: "50%",
  },
  sectionHeader: {
    fontWeight: 'bold',
    fontSize: 20,
    textAlign: 'center',
    marginBottom: 10,
  },
  sectionHeaderView: {
    flexDirection: 'row',
  },
  componentContainer: {   
    backgroundColor: Colors.white, 
    marginTop: "2%",
    padding: "1%",
    borderColor: Colors.borderGrey,
    borderWidth: 1,
    borderRadius: 10,
    width: "100%"
  },
  loadingText: {
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold'
  },
  container: {
    padding: 10,
  },
});
export default DashboardGraphs