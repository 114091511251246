import React from 'react'

import {
  View,
  StyleSheet,
  ActivityIndicator,
  Animated,
} from 'react-native';

import {useFocusEffect, useIsFocused} from '@react-navigation/native';
import { Text } from 'react-native';
import ProjectApi from '../../services/modules/project/api';
import Colors from '../../constants/Colors';
import { useSelector } from 'react-redux';
import { RootState } from '../../services/store';
import { AxiosResponse } from 'axios';
import { TraxxText } from '../ui/TraxxText';

interface Props {
}

const ProjectStats: React.FC<Props> = ({ 
}) => {   
  const isFocused = useIsFocused()

  const projectState = useSelector((state: RootState) => state.project);

  const [totalCompleted, setTotalCompleted] = React.useState(0);
  const [totalInProgress, setTotalInProgress] = React.useState(0);
  const [capacity, setCapacity] = React.useState(0);
  const [volume, setVolume] = React.useState(0);
  const [targetVolume, setTargetVolume] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  
  const init = () => {
    setLoading(true);

    ProjectApi.load(projectState.id!)
    .then((apiResponse: AxiosResponse<any>) => {   
      const project = apiResponse.data;

      setCapacity((project.volume / project.targetVolume) * 100);
      setVolume(project.volume);
      setTargetVolume(project.targetVolume);
      
      setLoading(false)
    })
      
    ProjectApi.projectStats(projectState.id!)
    .then((apiResponse: any) => {
      setTotalCompleted(apiResponse.data.complete);
      setTotalInProgress(apiResponse.data.inProgress);
    });
  }

  useFocusEffect (
    React.useCallback(() => {      
      if (isFocused) {
        init();
      }
    }, [isFocused])
  );

  return (<View>       
            {loading &&
              <View style={{marginTop: 40}}>
                <ActivityIndicator size="large" color="#3F51B5" />
                <TraxxText style={styles.loadingText}>Loading...</TraxxText>
              </View>
            }
            {!loading && <View>    
              <View style={styles.componentContainer}>            
                <View style={styles.sectionHeaderView}>
                  <TraxxText style={styles.sectionHeader}>PROJECT HAULS</TraxxText>
                </View>
                <View style={{flexDirection:'row'}}>
                  <View style={styles.statsContainer}>
                    <TraxxText style={styles.stat}>{totalInProgress}</TraxxText>
                    <TraxxText style={styles.statTitle}>In Progress</TraxxText>
                  </View>
                  <View style={styles.statsContainer}>
                    <TraxxText style={styles.stat}>{totalCompleted}</TraxxText>
                    <TraxxText style={styles.statTitle}>Total Completed</TraxxText>
                  </View>
                </View>                       
                <View style={styles.progressBar}>
                  {capacity >= 80 && targetVolume > 0 && <Animated.View style={[StyleSheet.absoluteFill, {backgroundColor: Colors.red, height: 31, borderRadius: 10, width: capacity + "%"}]}/>}
                  {capacity < 80 && targetVolume > 0 && <Animated.View style={[StyleSheet.absoluteFill, {backgroundColor: Colors.green, height: 31, borderRadius: 10, width: capacity + "%"}]}/>}
                  {capacity >= 80 && targetVolume > 0 && <TraxxText style={[styles.cardDetail, {color: Colors.white}]}>{volume} of {targetVolume} Cu. Meters ({Math.round(capacity)}%)</TraxxText>}
                  {capacity < 80 && targetVolume > 0 && <TraxxText style={styles.cardDetail}>{volume} of {targetVolume} Cu. Meters ({Math.round(capacity)}%)</TraxxText>}
                  {targetVolume == 0 && <TraxxText style={styles.cardDetail}>{volume} Cu. Meters (no target volume set)</TraxxText>}
                </View>   
              </View>
            </View>}
          </View>
  )
};

const styles = StyleSheet.create({ 
  stat: {
    fontSize: 22,
    fontWeight: 'bold',
    textAlign: 'center'
  },
  
  statTitle: {
    fontSize: 20,
  },

  statsContainer: {
    width: "50%",
    alignItems: 'center',
    marginVertical: 20,
  },

  statsHeader: {
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center'
  },
  sectionHeader: {
    fontWeight: 'bold',
    fontSize: 20,
    flex: 1,
    textAlign: 'center',
    marginBottom: 10,
  },
  sectionHeaderView: {
    flexDirection: 'row',
  },
  componentContainer: {   
    backgroundColor: Colors.white, 
    padding: "1%",
    borderColor: Colors.borderGrey,
    borderWidth: 1,
    borderRadius: 10,
    width: "100%"
  },
  loadingText: {
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold'
  },
  container: {
    flexDirection: 'row',
    padding: 10,
  },
  progressBar: {
    height: 33,
    flexDirection: "row",
    width: '100%',
    backgroundColor: 'white',
    borderColor: '#000',
    borderWidth: 1,
    borderRadius: 10,
    padding: 5,
  },
  cardDetail: {
    color: Colors.text,
    fontSize: 14,
    zIndex: 10,
    fontWeight: 'bold',
  },
});
export default ProjectStats