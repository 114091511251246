import React, { useEffect } from 'react'
import { ActivityIndicator, Alert, Dimensions, Modal, Platform, StyleSheet, Text, View } from 'react-native';
import { FlatList, ScrollView, TextInput } from 'react-native-gesture-handler';
import { Account, Site } from '../../services/types';
import Button from '../ui/Button';
import SiteApi from '../../services/modules/site/api';
import { createSite } from '../../services/helpers';
import PlacesSearchBar from '../location/PlacesSearchBar';
import { useSelector } from 'react-redux';
import { RootState } from '../../services/store';
import Colors from '../../constants/Colors';
import { SearchBar } from 'react-native-elements';
import { SearchBarBaseProps } from 'react-native-elements/dist/searchbar/SearchBar';
import * as Location from 'expo-location';
import { TraxxText } from '../ui/TraxxText';
import { TraxxTextInput } from '../ui/TraxxTextInput';
import OrganizationApi from '../../services/modules/organization/api';

const SafeSearchBar = (SearchBar as unknown) as React.FC<SearchBarBaseProps>;

interface Props {
  onItemSelected: (data: any) => void;
  onCancel?: () => void;
  onNoResults?: () => void;
  showCancel?: boolean;
  placeholder?: string;
}

const HaulerSearch: React.FC<Props> = ({ 
  onItemSelected,
  onCancel,
  onNoResults,
  showCancel,
  placeholder
}) => { 
  const authState = useSelector((state: RootState) => state.auth)

  const [searching, setSearching] = React.useState(false);
  const [noResults, setNoResults] = React.useState(false);
  const [results, setResults] = React.useState([]);
  const [keyword, setKeyword] = React.useState('');
  
  const itemPressed = (data: any) => {
    onItemSelected(data);
  }

  const clearSearch = () => {
      setResults([]);
  }

  const getShowCancel = () => {
      if (showCancel == undefined) {
        return false;
      }
      
      return showCancel;
  }

  const getPlaceHolder = () => {
    if (placeholder == undefined) {
      return "Search people";
    }

    return placeholder;
  }

  const checkOnCancel = () => {
      if (onCancel) {
        onCancel();
      }
  }

  const updateSearch = (text: any) => {
    setKeyword(text);
    setSearching(true);
    setNoResults(false);

    if (text.length > 0) {
      OrganizationApi.searchPeople(authState.organizationId, text)
      .then((apiResponse: any) => {  
        if (apiResponse.data.length == 0) {
          setNoResults(true);
          setResults([]);
        } else {
          setResults(apiResponse.data);
        }
        setSearching(false);
      });
    } else {
      setSearching(false);
      setResults([]);
    }
  };

  return ( 
    <View>
      <SafeSearchBar
        onChangeText={updateSearch}
        onCancel={clearSearch}
        placeholder={getPlaceHolder()}
        value={keyword}
        platform={"default"}
        inputStyle={styles.searchBarInput}
        containerStyle={styles.searchBarContainer}
        inputContainerStyle={styles.searchBarInputContainer}
      />
      
      {searching &&
        <View style={styles.activityContainer}>
          <ActivityIndicator size="large" color="#3F51B5" />
          <TraxxText style={styles.searchingText}>Searching...</TraxxText>
        </View>
      }
      
      {noResults && !searching &&
        <View style={styles.activityContainer}>
          <TraxxText style={styles.nothingText}>Nothing found</TraxxText>
        </View>
      }
      
      {!searching && !noResults && <View>
        <TraxxText style={styles.instruction}>Press the driver</TraxxText>
        {results.map((item: Account) => {
          return (<View key={item.id.toString()}>
                    <div style={{cursor: 'pointer'}} onClick={() => itemPressed(item)}>
                      <View style={styles.carouselItem}>
                        <TraxxText style={styles.title} numberOfLines={1} ellipsizeMode='tail'>{item.firstName} {item.lastName}</TraxxText>
                        <TraxxText style={styles.subTitle} numberOfLines={1} ellipsizeMode='tail'>{item.email}</TraxxText>
                      </View>
                    </div>
                  </View>)
          })}
      </View>}

      {getShowCancel() && <Button style={styles.button} onPress={() => checkOnCancel()}
        text="Cancel">
      </Button>}
    </View>)
};

const width = Dimensions.get('window').width
  
const styles = StyleSheet.create({
  category: {
    fontSize: 18,
    fontStyle: 'italic',
  }, 

  screen: {
    flex: 1
  },

  listContainer: {
    flex: 12
  },

  activityContainer: {

  },

  nothingText: {
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold'
  },

  searchingText: {
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold'
  },

  header: {
    fontSize: 32,
    backgroundColor: "#fff",
    margin: 10,
  },
 
  item: {
    backgroundColor: "#f9c2ff",
    padding: 20,
    marginVertical: 8
  },

  title: {
    fontWeight: 'bold',
    marginLeft: 10,
    fontSize: 18
  },

  subTitle: {
    marginLeft: 10,
    fontSize: 16,
    marginBottom: 10,
  },

  image: {
    height: 90,
    width: 90,
    resizeMode: 'cover',
    borderRadius: 90
  },

  carouselItem: {
    margin: 10,
    borderBottomColor: Colors.borderGrey,
    borderBottomWidth: 1,    
  },

  bookImage: {
    width: 120,
    height:120,
    resizeMode: 'cover',
    marginRight: 10
  },

  bookCount: {
    fontSize: 20,
    fontWeight: 'bold'
  },

  courseBookImage: {
    width: 40,
    height: 40,
    marginRight: 5,
    marginTop: 5,
    resizeMode: 'cover'
  },

  signUpContainer: {
    marginTop: 60,
    marginBottom: 250
  },

  subNavContainer: {
    height: 50,
    flexDirection: 'row'
  },

  button: {
    margin: 5,
    width: width - 10
  },
  resultContainer: {
    width: width - 150,
  },
  modalView: {

  },
  modalText: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 20,
    margin: 20,
  },
  inputView: {
    flexDirection: 'row',
  },

  inputLabel: {
    fontSize: 16,
    marginTop: 10,
    marginLeft: 15,
  },

  inputHeader: {
    fontSize: 16,
    marginLeft: 15,
    fontWeight: 'bold'
  },

  inputGroup: {
    flexDirection: 'row',
    marginLeft: 5,
  },
  input: {
    height: 50,
    width: width - 20,
    paddingVertical: 10,
    paddingHorizontal: 20,
    margin: 10,
    flex: 1,
    borderColor: Colors.borderGrey,
    borderWidth: 1,
    borderRadius: 20
  },
  entryButton: {
    width: width - 20,
    marginLeft: 10,
    marginTop: 10,
  },
  searchBarInput: {
    backgroundColor: Colors.white,
    fontSize: 15,
  },
  searchBarContainer: {
    backgroundColor: Colors.white,
    borderColor: Colors.black,
    borderWidth: 1,
    borderRadius: 20,
    margin: 10,
  },
  searchBarInputContainer: {
    backgroundColor: Colors.white,
  },

  instruction: {
    marginVertical: 5,
    marginHorizontal: 15,
  },
});

export default HaulerSearch
