import { useNavigation } from '@react-navigation/native';
import React, { useEffect } from 'react'

import {
  useColorScheme,
  View,
  StyleSheet,
  Dimensions,
  ActivityIndicator,
  Modal,
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import Colors from '../../constants/Colors';
import { updateFirstTime, updateFirstTimeTrucks } from '../../services/modules/auth/actions';
import { RootState } from '../../services/store';
import CompanyList from './CompanyList';
import CompanyPanel from './CompanyPanel';
import CompanyTrackingPanel from './CompanyTrackingPanel';

interface Props {
}

const CompanyDashboard: React.FC<Props> = ({ 
}) => {   
  const authState = useSelector((state: RootState) => state.auth)

  const navigation = useNavigation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (authState.firstTimeTrucks) {
      setTimeout(() => {
        navigation.navigate("Vehicles");
        dispatch(updateFirstTimeTrucks(false));
      }, 100)
    }
  }, [authState])

  return (<View style={styles.container}>
            <View style={styles.mainContainer}>
              <CompanyList />
            </View>
            <View style={styles.sideContainer}>
              <CompanyTrackingPanel />
              <View style={{marginTop: 10, marginBottom: 10}}>
                <CompanyPanel />
              </View>
            </View>
          </View>
  )
};

const width = Dimensions.get('window').width;

const styles = StyleSheet.create({ 
  stat: {
    fontSize: 26,
    fontWeight: 'bold',
  },

  statTitle: {
    fontSize: 20,
  },

  statsContainer: {
    width: width / 2 - 10,
    alignItems: 'center',
    marginHorizontal: 5,
    marginVertical: 20,
  },

  loadingText: {
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold'
  },

  button : {
    margin: 4,
  },

  modalView: {

  },

  modalText: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 20,
    margin: 20,
  },

  entryButton: {
    width: width - 20,
    marginLeft: 10,
    marginTop: 10,
  },

  inputLabel: {
    fontSize: 16,
    marginTop: 10,
    marginLeft: 15,
  },

  inputGroup: {
    flexDirection: 'row',
    marginLeft: 5,
  },

  input: {
    height: 50,
    width: width - 20,
    paddingVertical: 10,
    paddingHorizontal: 20,
    margin: 10,
    flex: 1,
    borderColor: Colors.borderGrey,
    borderWidth: 1,
    borderRadius: 20
  },

  container: {
    flexDirection: 'row'
  },

  mainContainer: {
    margin: "2%",
    width: "66%",
  },

  sideContainer: {
    marginTop: "2%",
    marginRight: "2%",
    width: "28%",
  },
});
export default CompanyDashboard