export const SELECT_ENTRY = 'HAUL/SELECT_ENTRY';
export const ADD_ENTRY = 'HAUL/ADD_ENTRY';
export const STARTED_TRACKING = 'HAUL/STARTED_TRACKING';
export const STOPPED_TRACKING = 'HAUL/STOPPED_TRACKING';
export const USE_LOCATION = 'HAUL/USE_LOCATION';

export interface HaulDispatchTypes {
  type: string;
  payload?: any;
}

export interface HaulState {
  id: number;
  weight: number;
  weightType: number;
  vehicleId: string;
  watchId: any;
  accountId: string;
  organizationId: number;
  projectId?: number;
  projectTitle?: string;
  siteId?: number;
}