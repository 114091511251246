import React from 'react'

import {
  View,
} from 'react-native';

import CompanyDashboard from '../components/organization/CompanyDashboard';

interface Props {
}

const CompanyDashboardScreen: React.FC<Props> = ({ 
}) => {   
  return (<View>          
            <CompanyDashboard />
          </View>
  )
};

export default CompanyDashboardScreen