import { useNavigation } from '@react-navigation/native';
import React, { useEffect } from 'react'

import {
  StyleSheet,
  View,
  ScrollView,
  Text,
  TextInput,
  ActivityIndicator,
} from 'react-native';
import { useSelector } from 'react-redux';

import Colors from '../../constants/Colors';
import AccountApi from '../../services/modules/account/api';
import { RootState } from '../../services/store';
import Button from '../ui/Button';
import { TraxxText } from '../ui/TraxxText';
import { TraxxTextInput } from '../ui/TraxxTextInput';

interface Props {
}

const VehicleRegistration: React.FC<Props> = ({ 
}) => { 
  const authState = useSelector((state: RootState) => state.auth)

  const navigation = useNavigation()
  
  const [loading, setLoading] = React.useState(true)
  
  const [truckCount, setTruckCount] = React.useState(0)

  const [vehicles, setVehicles] = React.useState([])
  
  const [vehicleId, setVehicleId] = React.useState("")

  const saveVehicle = () => {
    if (vehicleId.trim().length == 0) {
      alert("Please enter a vehicle licence plate number.")
      return
    }

    setLoading(true)
    AccountApi.saveSubscriptionVehicle(vehicleId)
    .then((apiResponse: any) => {
      setVehicleId("")
      loadVehicles();
    })
  }

  const loadVehicles = () => {
    setLoading(true)

    AccountApi.subscriptionVehicles()
    .then((apiResponse: any) => {
      setVehicles(apiResponse.data)
    })

    AccountApi.subscriptionTruckCount()
    .then((apiResponse: any) => {
      setTruckCount(apiResponse.data)
      setLoading(false)
    })
  }

  const close = () => {
    navigation.goBack()
  }
  
  useEffect(() => {
    loadVehicles();
  }, [])

  return ( 
    <ScrollView
      contentInsetAdjustmentBehavior="automatic"
      keyboardShouldPersistTaps='always'>
      {loading &&
          <View style={{marginTop: 40}}>
            <ActivityIndicator size="large" color="#3F51B5" />
            <TraxxText style={styles.loadingText}>Loading, please wait...</TraxxText>
          </View>
      }

      {!loading && <View style={styles.container}>
        <TraxxText style={styles.subHeader}>Add all the vehicles you will be using with Traxx</TraxxText>
        {authState.subscriptionId != 3 && <TraxxText style={[styles.header, {marginBottom: 10}]}>{vehicles.length} of {truckCount} used</TraxxText>}
        
        {vehicles.map((value: any, index: number) => {
          return (<TraxxText key={index} style={styles.subHeader}>#{index + 1} {value.vehicleId}</TraxxText>)
        })}

        {vehicles.length < truckCount && <View>
          <TraxxText style={styles.modalText}>New License Plate</TraxxText>
          <TraxxTextInput
            style={[styles.input, {padding:15, margin: 5}]}
            placeholder="Enter the license plate number"
            placeholderTextColor="#003f5c"
            autoCapitalize="characters"
            selectTextOnFocus
            value={vehicleId}
            onChangeText={(vehicleId: string) => setVehicleId(vehicleId)}
          />
          <Button text='Add' onPress={() => {saveVehicle()}} />
          <Button text='Close' onPress={() => {close()}} style={{marginTop: 20}}/>
        </View>}
        
        {authState.subscriptionId == 3 && <View>
          <TraxxText style={styles.modalText}>New License Plate</TraxxText>
          <TraxxTextInput
            style={[styles.input, {padding:15, margin: 5}]}
            placeholder="Enter the license plate number"
            placeholderTextColor="#003f5c"
            autoCapitalize="characters"
            selectTextOnFocus
            value={vehicleId}
            onChangeText={(vehicleId: string) => setVehicleId(vehicleId)}
          />
          <Button text='Add' onPress={() => {saveVehicle()}} />
          <Button text='Close' onPress={() => {close()}} style={{marginTop: 20}}/>
        </View>}
      </View>}
    </ScrollView>
  )
};

const styles = StyleSheet.create({
  container: {
    width: 400,
    alignSelf: 'center'
  },

  inputContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 80,
  },
 
  image: {
    flex: 1,
    width: 200,
    height: 38,
    resizeMode: 'contain',
    marginBottom: 40,
  },

  button: {
    marginLeft: 5,
  },

  subscriptionBox: {
    borderTopColor: Colors.primary,
    borderTopWidth: 3,
    width: "33%",
    marginRight: 10,
    padding: 10
  },

  header: {
    fontWeight: 'bold',
    fontSize: 20,
    textAlign: 'center'
  },

  subHeader: {
    fontSize: 18,
    textAlign: 'center'
  },

  subscriptionCost: {
    fontWeight: 'bold',
    fontSize: 26,
    height: 80,
    textAlign: 'center'
  },

  loadingText: {
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold'
  },
  modalText: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 20,
    margin: 20,
  },
  input: {
    borderRadius: 5,
    borderWidth: 1,
    borderColor: Colors.borderGrey,
    backgroundColor: Colors.white,
    fontSize: 16,
    paddingHorizontal: 10,
    fontWeight: 'bold',
  },
});

export default VehicleRegistration