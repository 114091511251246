import axiosInstance from "../../interceptor";

const SiteApi = {
  my: () => axiosInstance.get('/site/my'),
  myUnverified: () => axiosInstance.get('/site/my/unverified'),
  listUsingGPS: (latitude: number, longitude: number) => axiosInstance.get('site/list/' + latitude + '/' + longitude),
  list: () => axiosInstance.get('site/list/'),
  qrScanned: (siteId: number) => axiosInstance.post('site/qr/', {siteId: siteId}),
  updateContact: (id: number, name: string, email: string, phone: string) => axiosInstance.post('site/contact', {id: id, contactName: name, contactEmail: email, contactPhone: phone}),
  save: (id: number, verified: boolean, title: string, address: string, city: string, province: string, postalCode: string, projectId: number) => axiosInstance.post('site', {
    id: id,
    projectId: projectId,
    verified: verified,
    title: title,
    address: address,
    city: city,
    province: province,
    postalCode: postalCode
  }),
  addProject: (siteId: number, projectId: number) => axiosInstance.get('site/project/add/' + siteId + '/' + projectId),
  removeProject: (siteId: number, projectId: number) => axiosInstance.get('site/project/remove/' + siteId + '/' + projectId),
  load: (siteId: number) => axiosInstance.get('site/' + siteId),
  search: (keyword: string) => axiosInstance.get('site/search/' + keyword),
  saveUnverified: (title: string, address: string, city: string, province: string, postalCode: string) => axiosInstance.post('site/unverified', {
    title: title,
    address: address,
    city: city,
    province: province,
    postalCode: postalCode
  }),
};

export default SiteApi;