import React, { useEffect } from 'react'

import {
  View,
  StyleSheet,
  ActivityIndicator,
  Modal,
} from 'react-native';

import { Text } from 'react-native';
import { FlatList, TextInput, TouchableOpacity } from 'react-native-gesture-handler';
import { useSelector } from 'react-redux';
import { RootState } from '../../services/store';
import { AxiosResponse } from 'axios';
import { ProjectDocument, Site } from '../../services/types';
import ProjectApi from '../../services/modules/project/api';
import * as DocumentPicker from 'expo-document-picker';
import Button from '../ui/Button';
import Colors from '../../constants/Colors';
import { baseURL } from '../../services/global';
import { Picker } from '@react-native-picker/picker';
import { TraxxText } from '../ui/TraxxText';
import { TraxxTextInput } from '../ui/TraxxTextInput';

interface Props {
}

const Documents: React.FC<Props> = ({ 
}) => {   
  const projectState = useSelector((state: RootState) => state.project);
  const authState = useSelector((state: RootState) => state.auth);

  const [documents, setDocuments] = React.useState([]);
  const [documentTypes, setDocumentTypes] = React.useState([]);
  const [documentTitle, setDocumentTitle] = React.useState("");
  const [selectedDocument, setSelectedDocument] = React.useState<any>({type: "", name: ""});
  const [documentType, setDocumentType] = React.useState(0);
  const [showDocument, setShowDocument] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [uploading, setUploading] = React.useState(false);

  useEffect(() => {
    loadDocuments();
    
    ProjectApi.listDocumentTypes()
    .then((apiResponse: AxiosResponse) => {   
      setDocumentTypes(apiResponse.data);
    })
  }, [projectState])

  const loadDocuments = () => {
    setLoading(true);
    ProjectApi.listDocuments(projectState.id!)
    .then((apiResponse: AxiosResponse) => { 
      setDocuments(apiResponse.data);
      setLoading(false);  
    })
  };

  const addDocument = async () => {
    if (selectedDocument.type != "success" || documentTitle.length == 0 || documentType == 0) {
      alert("Please enter a document title and select a type and file.");
      return;
    }
  
    let { uri } = selectedDocument;
    
    try {
      setUploading(true);

      const fetchResponse = await fetch(uri);
      const blob = await fetchResponse.blob();
      
      let formData = new FormData();
      formData.append("id", projectState.id!.toString());
      formData.append("typeId", documentType.toString());
      formData.append("title", documentTitle);
      formData.append('filename', selectedDocument.file!.name);
      formData.append('file', blob);
            
      ProjectApi.addDocument(formData)
      .then(async () => {  
        setUploading(false);  
        setShowDocument(false)
        loadDocuments();
      })
      .catch((error) => {
        alert("Error");
        setUploading(false);
      })
    } catch (error) {
      alert("Error");
      setUploading(false);
    }
  }

  const selectDocument = async () => {
    const result = await DocumentPicker.getDocumentAsync();

    if (result.type == "success") {
      setSelectedDocument(result);
    }
  }

  const downloadDocument = async (projectDocument: ProjectDocument) => {
    fetch(baseURL + "/project/document/" + projectDocument.id,
    { headers: new Headers({
      'Authorization': 'bearer ' + authState.token, 
      'Content-Type': 'application/x-www-form-urlencoded'
    })})    
    .then(res => res.blob())
    .then(blob => {
      try {
        const href = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', projectDocument.fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch(error) {
        console.log(error)
      }
    });
  }

  const drawDocumentCard = (({item} : {item: ProjectDocument}) => {
    return (
    <TouchableOpacity onPress={() => downloadDocument(item)}>
      <View style={styles.card}>
        <View style={styles.cardInfoContainer}>
          <TraxxText style={styles.cardTitle}>{item.title}</TraxxText>
          <TraxxText style={styles.cardSubTitle}>{item.typeTitle}</TraxxText>
        </View>
      </View>
    </TouchableOpacity>)
  })

  return (<View
            style={styles.componentContainer}>     
            {showDocument && <Modal
              style={styles.modalView}
              animationType="slide"
              transparent={false}
              visible={showDocument}
              onRequestClose={() => {
                setShowDocument(!showDocument);
              }}>
              <View style={styles.modalView}>
                <TraxxText style={styles.modalText}>New Project Document</TraxxText>
                <View style={styles.inputView}>
                  <TraxxText style={styles.inputTitle}>Type</TraxxText>
                  <View style={styles.inputGroup}>
                    <Picker
                      selectedValue={documentType}
                      style={styles.dropDown}
                      onValueChange={(itemValue, itemIndex) => setDocumentType(itemValue)}
                    >
                      <Picker.Item label="Please Select" value="0" key="0" />
                      
                      {documentTypes.map((value: any, index: number) => {
                        return (<Picker.Item label={value.title} value={value.id} key={value.id} />)
                      })}

                    </Picker>
                  </View>
                </View>
                <View style={styles.inputView}>
                  <TraxxText style={styles.inputTitle}>Title</TraxxText>
                  <View style={styles.inputGroup}>
                    <TraxxTextInput
                      style={styles.input}
                      placeholder="Title"
                      placeholderTextColor="#003f5c"
                      value={documentTitle}
                      onChangeText={(documentTitle: string) => {
                        setDocumentTitle(documentTitle);
                      }}
                    />
                  </View>
                  <Button style={[styles.entryButton, {marginTop: 20}]} onPress={selectDocument}
                    text="Select Document">
                  </Button>
                  {selectedDocument.name !== "" && <TraxxText style={{fontWeight: 'bold'}}>Document selected: {selectedDocument.name}</TraxxText>}
                  <Button style={[styles.entryButton, {marginTop: 40}]} onPress={() => {addDocument()}}
                    text="Save">
                  </Button>
                  <Button style={[styles.entryButton, {marginTop: 20}]} onPress={() => {setShowDocument(false)}}
                    text="Cancel">
                  </Button>
                </View>
              </View>
            </Modal>}
            
            {loading &&
              <View>
                <ActivityIndicator size="large" color="#3F51B5" />
                <TraxxText style={styles.searchingText}>Loading...</TraxxText>
              </View>
            }
            {uploading &&
              <View>
                <ActivityIndicator size="large" color="#3F51B5" />
                <TraxxText style={styles.searchingText}>Uploading...</TraxxText>
              </View>
            }
            {!loading && !uploading && !showDocument && 
            <View style={styles.sectionContainer}>
              <View style={styles.sectionHeaderView}>
                <TraxxText style={styles.sectionHeader}>DOCUMENTS</TraxxText>
              </View>    
              {documents.length == 0 && <View>
                <TraxxText style={{marginVertical: 10}}>No documents uploaded</TraxxText>
              </View>}                  
              <FlatList
                keyExtractor={(item: ProjectDocument, index) => item.id.toString() + index}
                data={documents}
                renderItem={drawDocumentCard}
              />
              {projectState.accessLevel == 1 && <Button onPress={() => setShowDocument(true)}
                style={styles.button}
                text="Add Document">
              </Button>}
            </View>}
          </View>
  )
};

const styles = StyleSheet.create({
  inputContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },

  componentContainer: {   
    backgroundColor: Colors.white, 
    padding: "1%",
    borderColor: Colors.borderGrey,
    borderWidth: 1,
    borderRadius: 10,
    width: "100%"
  },

  inputView: {
    backgroundColor: "#fff",
    borderRadius: 30,
    marginBottom: 10,
  },

  input: {
    borderRadius: 5,
    borderWidth: 1,
    borderColor: Colors.borderGrey,
    fontSize: 16,
    padding: 10,
    flex: 1,
  },

  inputGroup: {
    flexDirection: 'row',
  },

  inputBox: {
    borderRadius: 5,
    borderWidth: 1,
    borderColor: Colors.borderGrey,    
    fontSize: 16,
    padding: 10,
  },

  inputText: {
    fontSize: 16,
    fontWeight: 'bold',
  },

  inputLabel: {
    fontSize: 16,
    marginTop: 10,
    marginLeft: 5,
    marginRight: 10,
  },

  inputTitle: {
    fontSize: 16,
    marginTop: 10,
    marginRight: 10,
    fontWeight: 'bold',
  },

  searchingText: {
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold'
  },

  comingSoon: {
    fontSize: 20,
    color: "#fff"
  },

  button : {
    marginTop: 10,
  },
  buttonOn: {
    backgroundColor: Colors.primary,
    margin: 4,
  },
  buttonOff: {
    backgroundColor: Colors.primaryBrown,
    margin: 4,
  },
  sectionContainer: {
    width: "100%",
  },
  sectionHeader: {
    fontWeight: 'bold',
    fontSize: 20,
    flex: 1,
  },
  sectionHeaderView: {
    flexDirection: 'row',
  },
  sectionHeaderButton : {
    margin: 4,
  },
  card: {
    borderColor: Colors.borderDarkGrey,
    borderRadius: 5,
    borderWidth: 1,
    padding: 10,
    marginTop: 10,
  }, 

  cardInfoContainer: {
  },

  cardTitle: {
    color: Colors.text,
    fontSize: 20,
    fontWeight: 'bold',
  },
  cardSubTitle: {
    color: Colors.textGrey,
    fontSize: 14,
    fontStyle: 'italic'
  },
  modalView: {
    width: "98%",
    margin: "1%",
    backgroundColor: Colors.white,
    borderColor: Colors.white
  },

  modalText: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 20,
    marginHorizontal: 20,
  },

  entryButton: {
    marginTop: 10,
  },

  dropDown: {
    borderRadius: 5,
    borderWidth: 1,
    borderColor: Colors.borderGrey,    
    fontSize: 16,
    padding: 10,
    width: "100%"
  },
});
export default Documents