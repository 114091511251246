import React, { useEffect } from 'react'

import {
  StyleSheet,
  View,
  ScrollView,
  Text,
} from 'react-native';
import { useSelector } from 'react-redux';
import Colors from '../../constants/Colors';
import PaymentApi from '../../services/modules/payment/api';
import { RootState } from '../../services/store';
import Button from '../ui/Button';
import { TraxxText } from '../ui/TraxxText';

interface Props {
  setPlan: (subscriptionId: number) => void;
}

const Subscriptions: React.FC<Props> = ({ 
  setPlan
}) => { 
  const authState = useSelector((state: RootState) => state.auth)

  return ( 
    <ScrollView
      contentInsetAdjustmentBehavior="automatic"
      keyboardShouldPersistTaps='always'>
      <View style={styles.container}>
        <View style={styles.subscriptionBox}>
          <TraxxText style={styles.subscriptionHeader}>Single Hauler</TraxxText>
          <TraxxText style={styles.subscriptionSubHeader}>No PM licence</TraxxText>
          <TraxxText style={styles.subscriptionCost}>$9.99/month</TraxxText>
          <Button text='Select' onPress={() => setPlan(1)} />
        </View>
        <View style={styles.subscriptionBox}>
          <TraxxText style={styles.subscriptionHeader}>Hauling Company</TraxxText>
          <TraxxText style={styles.subscriptionSubHeader}>Up to 20 trucks, no PM licence</TraxxText>
          <TraxxText style={styles.subscriptionCost}>$9.99/month + $4.99/truck</TraxxText>
          <Button text='Select' onPress={() => setPlan(2)} />
        </View>
        <View style={styles.subscriptionBox}>
          <TraxxText style={styles.subscriptionHeader}>Large Hauling Companies/Project Leaders/QPs</TraxxText>
          <TraxxText style={styles.subscriptionSubHeader}>20+ trucks, full PM licence</TraxxText>
          <TraxxText style={styles.subscriptionCost}>$199.99/month</TraxxText>
          <Button text='Select' onPress={() => setPlan(3)} />
        </View>
      </View>
      {authState.role == "Driver" && <View style={{width: "100%"}}>
        <Button text='I have a code' onPress={() => setPlan(4)} style={{width: "99%", marginLeft: "1%"}} />
      </View>}
    </ScrollView>
  )
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row'
  },

  inputContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 80,
  },
 
  image: {
    flex: 1,
    width: 200,
    height: 38,
    resizeMode: 'contain',
    marginBottom: 40,
  },

  button: {
    marginLeft: 5,
  },

  subscriptionBox: {
    borderTopColor: Colors.primary,
    borderTopWidth: 3,
    width: "33%",
    marginRight: 10,
    padding: 10
  },

  subscriptionHeader: {
    fontWeight: 'bold',
    fontSize: 22,
    height: 100,
    textAlign: 'center'
  },

  subscriptionSubHeader: {
    fontSize: 18,
    height: 40,
    textAlign: 'center'
  },

  subscriptionCost: {
    fontWeight: 'bold',
    fontSize: 26,
    height: 80,
    textAlign: 'center'
  }
});

export default Subscriptions