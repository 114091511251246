import React from 'react'

import {
  View,
  StyleSheet,
} from 'react-native';

import { useNavigation} from '@react-navigation/native';
import { Text } from 'react-native';
import Button from '../ui/Button';
import Colors from '../../constants/Colors';
import { TraxxText } from '../ui/TraxxText';

interface Props {
}

const ManagerTrackingPanel: React.FC<Props> = ({ 
}) => {   
  const navigation = useNavigation();

  const reuseSites = (() => {    
    navigation.navigate("ReuseSites");
  });

  const showMap = (() => {
    navigation.navigate("ProjectMap");
  });

  const openHaulLogReport = (() => {
    navigation.navigate("ReportHaulLogProject");
  });

  return (<View style={styles.componentContainer}>               
            <View style={styles.sectionContainer}>
              <View style={styles.sectionHeaderView}>
                <TraxxText style={styles.sectionHeader}>TRACKING AND REPORTS</TraxxText>
              </View>
              <Button onPress={() => {reuseSites();}}
                style={styles.button}
                text="Reuse Sites">
              </Button>
              <Button onPress={() => {showMap();}}
                style={styles.button}
                text="Haul Tracker Map">
              </Button>
              <Button onPress={() => {openHaulLogReport();}}
                style={styles.button}
                text="Haul Logs">
              </Button>
            </View>
          </View>
  )
};

const styles = StyleSheet.create({
  componentContainer: {   
    backgroundColor: Colors.white, 
    padding: "1%",
    borderColor: Colors.borderGrey,
    borderWidth: 1,
    borderRadius: 10,
    width: "100%"
  },

  button : {
    marginTop: 10
  },
  
  sectionContainer: {
    width: "100%",
    padding: 10,
  },
  sectionHeader: {
    fontWeight: 'bold',
    fontSize: 20,
    flex: 1,
  },
  sectionHeaderView: {
    flexDirection: 'row',
  },
  subSectionHeaderView: {
    flexDirection: 'row',
    marginTop: 20,
  },
  sectionHeaderButton : {
    margin: 4,
  },
});
export default ManagerTrackingPanel