import AsyncStorage from '@react-native-async-storage/async-storage';
import { useNavigation } from '@react-navigation/native';
import React, { useEffect } from 'react'

import {
  StyleSheet,
  View,
  ScrollView,
  ActivityIndicator,
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import Colors from '../../constants/Colors';
import AccountApi from '../../services/modules/account/api';
import { updateFirstTimeTrucks, updateOrganization, updateSubscribed } from '../../services/modules/auth/actions';
import OrganizationApi from '../../services/modules/organization/api';
import PaymentApi from '../../services/modules/payment/api';
import { RootState } from '../../services/store';
import Button from '../ui/Button';
import { TraxxText } from '../ui/TraxxText';
import { TraxxTextInput } from '../ui/TraxxTextInput';
import Subscriptions from './Subscriptions';

interface Props {
}

const SubscribeForm: React.FC<Props> = ({ 
}) => { 
  const dispatch = useDispatch()

  const authState = useSelector((state: RootState) => state.auth)
  const paymentState = useSelector((state: RootState) => state.payment)

  const [saving, setSaving] = React.useState(false)
  const [plan, setPlan] = React.useState(0)
  const [organizationCode, setOrganizationCode] = React.useState("")

  const planRef = React.useRef(plan)
  planRef.current = plan

  const saveOrganizationCode = () => {
    if (organizationCode.trim().length == 0) {
      alert("Please enter you organization code.");
      return;
    }

    setSaving(true);

    AccountApi.updateOrganizationCode(organizationCode)
    .then(async (apiResponse: any) => {
      await AsyncStorage.setItem(
        'user.organizationId',
        apiResponse.data.id
      ); 

      await AsyncStorage.setItem(
        'user.organization',
        apiResponse.data.title
      ); 
      
      await AsyncStorage.setItem(
        'user.subscribed',
        "true"
      ); 

      await AsyncStorage.setItem(
        'user.subscriptionOwner',
        'false'
      ); 

      await AsyncStorage.setItem(
        'user.subscriptionId',
        "1"
      ); 

      dispatch(updateOrganization(apiResponse.data.id, apiResponse.data.title, true, 1))  
      setSaving(false); 
    })
    .catch(() => {
      setSaving(false);
      alert("We could not find that code, please try again.");
    });
  };

  useEffect(() => {
    window.addEventListener("message", (event) => {
      //if (event.origin !== "http://example.org:8080")
      //  return;
        
      try {
        let data = JSON.parse(event.data);
  
        if ("cloverT" in data) {
          setSaving(true)
          PaymentApi.subscribe(data.cloverT, data.amount, planRef.current, data.truckCount, data.annual)
          .then(async (apiResponse: any) => {
            if (apiResponse.data) { 
              await AsyncStorage.setItem(
                'user.subscribed',
                'true'
              );

              await AsyncStorage.setItem(
                'user.subscriptionOwner',
                'true'
              );

              await AsyncStorage.setItem(
                'user.subscriptionId',
                planRef.current.toString()
              );

              if (planRef.current == 1) {
                if (authState.organizationId == 0) {
                  OrganizationApi.createHauler()
                  .then((apiResponse: any) => {
                    dispatch(updateOrganization(apiResponse.data.data, authState.firstName + " " + authState.lastName, true, planRef.current))

                    setSaving(false)
                    dispatch(updateFirstTimeTrucks(true))
                    dispatch(updateSubscribed(true, true, planRef.current))      
                  })
                }
              } else {
                setSaving(false)
  
                if (planRef.current != 3) {
                  console.log("updated trucks")
                  dispatch(updateFirstTimeTrucks(true))
                }
  
                dispatch(updateSubscribed(true, true, planRef.current))      
              }                
            } else {
              alert("There was an error processing your payment, please try again.")
            }
          })
          .catch(() => {
            setSaving(false)
            alert("There was an error processing your payment, please try again.")
          })
        } 
      } catch {
        
      }  
    }, true);
  }, [])

  useEffect(() => {
    setPlan(paymentState.subscriptionId)  
  }, [paymentState])

  return ( 
    <ScrollView
      contentInsetAdjustmentBehavior="automatic"
      keyboardShouldPersistTaps='always'>
      <View style={styles.container}>
        <View style={styles.inputContainer}>
          {saving &&
              <View style={{marginTop: 40}}>
                <ActivityIndicator size="large" color="#3F51B5" />
                <TraxxText style={styles.loadingText}>Subscribing, please wait...</TraxxText>
              </View>
          }

          {plan == 1 && !saving && <iframe id="traxxpayment" title="Traxx Payment Gateway" width="500" height="500" src="https://www.digitalgrounds.ca/payment.html?s=1&amp;v=8" style={{border: 0}}> </iframe>}
          {plan == 2 && !saving && <iframe id="traxxpayment" title="Traxx Payment Gateway" width="500" height="500" src="https://www.digitalgrounds.ca/payment.html?s=2&amp;v=8" style={{border: 0}}> </iframe>}
          {plan == 3 && !saving && <iframe id="traxxpayment" title="Traxx Payment Gateway" width="500" height="500" src="https://www.digitalgrounds.ca/payment.html?s=3&amp;v=8" style={{border: 0}}> </iframe>}
          {plan == 4 && !saving && <View style={{width: "50%"}}>
                <TraxxText style={styles.inputLabel}>Organization Code</TraxxText>
                <TraxxTextInput
                  style={styles.input}
                  placeholder="Enter your organization code"
                  placeholderTextColor="#003f5c"
                  autoCapitalize="characters"
                  selectTextOnFocus
                  onChangeText={(organizationCode: string) => setOrganizationCode(organizationCode)}
                />
                <Button text='Save' onPress={() => saveOrganizationCode()} style={{marginTop: 20}} />
            </View>}
          {plan != 0 && !saving && <Button text='Change Plan' onPress={() => setPlan(0)} style={{marginTop: 20}} />}
          {plan == 0 && !saving && <Subscriptions setPlan={(subscriptionId: number) => setPlan(subscriptionId)} />}
        </View>
      </View>
    </ScrollView>
  )
};

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    flex: 1,
    width: "100%"
  },

  inputContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 20,
  },
 
  image: {
    flex: 1,
    width: 200,
    height: 38,
    resizeMode: 'contain',
    marginBottom: 40,
  },

  button: {
    marginLeft: 5,
  },

  loadingText: {
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold'
  },
  
  input: {
    height: 50,
    width: "100%",
    paddingVertical: 10,
    paddingHorizontal: 20,
    margin: 10,
    borderColor: Colors.borderGrey,
    borderWidth: 1,
    borderRadius: 20
  },

  inputLabel: {
    textAlign: 'center',
    fontSize: 16,
    marginTop: 10,
    marginLeft: 5,
  },

});

export default SubscribeForm