import React, { useEffect } from 'react'

import {
  View,
  StyleSheet,
  Modal,
  Alert,
  Share,
  ActivityIndicator,
} from 'react-native';

import { useFocusEffect, useIsFocused, useNavigation} from '@react-navigation/native';
import { Text } from 'react-native';
import { FlatList, TextInput, TouchableOpacity } from 'react-native-gesture-handler';
import { useSelector } from 'react-redux';
import { RootState } from '../../services/store';
import { Account, Site } from '../../services/types';
import ProjectApi from '../../services/modules/project/api';
import { AxiosResponse } from 'axios';
import Button from '../ui/Button';
import QRCode from 'react-qr-code';
import { Picker } from '@react-native-picker/picker';
import Colors from '../../constants/Colors';
import SiteApi from '../../services/modules/site/api';
import OrganizationApi from '../../services/modules/organization/api';
import { createSite } from '../../services/helpers';
import { TraxxText } from '../ui/TraxxText';

interface Props {
}

const ReuseAccessPanel: React.FC<Props> = ({ 
}) => {   
  const authState = useSelector((state: RootState) => state.auth);

  const [siteId, setSiteId] = React.useState(0);

  const [site, setSite] = React.useState<Site>(createSite());

  var svg: any;

  useEffect(() => {
    OrganizationApi.loadSite(authState.organizationId)
    .then((apiResponse: AxiosResponse<any>) => {
      setSite(apiResponse.data);
      setSiteId(apiResponse.data.id);
    }).catch((error: any) => console.log(error));
  }, []);

  return (<View style={styles.componentContainer}>  
            <View style={[styles.modalView, {alignItems: 'center'}]}>
              <TraxxText style={[styles.modalText, {marginBottom: 15}]}>Project QR Code</TraxxText>  
              <QRCode
                value={"{\"projectId\":\"0\",\"siteId\":\"" + siteId + "\",\"site\":{\"id\":\"" + siteId + "\",\"title\":\"" + site.title + "\",\"address\":\"" + site.address + "\",\"addressOptional\":\"" + site.addressOptional + "\",\"city\":\"" + site.city + "\",\"province\":\"" + site.province + "\",\"postalCode\":\"" + site.postalCode + "\",\"contactName\":\"" + site.contactName + "\",\"contactEmail\":\"" + site.contactEmail + "\",\"contactPhone\":\"" + site.contactPhone + "\"}}"}
                size={250}
                color="#000"
              />
            </View>
          </View>
  )
};
              
const styles = StyleSheet.create({
  inputContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },

  componentContainer: {   
    backgroundColor: Colors.white, 
    padding: "1%",
    borderColor: Colors.borderGrey,
    borderWidth: 1,
    borderRadius: 10,
    width: "100%"
  },

  inputView: {
    backgroundColor: "#fff",
    borderRadius: 30,
    marginBottom: 10,
  },

  input: {
    borderRadius: 5,
    borderWidth: 1,
    borderColor: Colors.borderGrey,
    fontSize: 16,
    padding: 10,
    flex: 1,
  },

  header: {
    marginTop: 10,
    fontSize: 16,
    fontWeight: 'bold',
  },

  inputGroup: {
    flexDirection: 'row',
    marginLeft: 5,
  },

  inputBox: {
    borderRadius: 5,
    borderWidth: 1,
    borderColor: Colors.borderGrey,    
    fontSize: 16,
    padding: 10,
  },

  inputText: {
    fontSize: 16,
    fontWeight: 'bold',
  },

  inputLabel: {
    fontSize: 16,
    marginTop: 10,
    marginLeft: 5,
    marginRight: 10,
  },

  searchingText: {
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold'
  },

  comingSoon: {
    fontSize: 20,
    color: "#fff"
  },

  button : {
    marginTop: 10
  },
  buttonOn: {
    backgroundColor: Colors.primary,
    margin: 4,
  },
  buttonOff: {
    backgroundColor: Colors.primaryBrown,
    margin: 4,
  },
  sectionContainer: {
    width: "100%",
    padding: 10,
  },
  sectionHeader: {
    fontWeight: 'bold',
    fontSize: 20,
    flex: 1,
  },
  sectionHeaderView: {
    flexDirection: 'row',
  },
  subSectionHeaderView: {
    flexDirection: 'row',
    marginTop: 20,
  },
  sectionHeaderButton : {
    margin: 4,
  },
  card: {
    borderColor: Colors.borderDarkGrey,
    borderRadius: 5,
    borderWidth: 1,
    padding: 10,
    marginTop: 10,
  }, 

  cardInfoContainer: {
  },

  cardTitle: {
    color: Colors.text,
    fontSize: 20,
    fontWeight: 'bold',
  },

  cardSubTitle: {
    color: Colors.text,
    fontSize: 14,
  },

  modalView: {
    width: "98%",
    margin: "1%",
    backgroundColor: Colors.white,
    borderColor: Colors.white
  },

  modalText: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 20,
    marginHorizontal: 20,
  },

  modalSubText: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 18,
    margin: 20,
  },

  entryButton: {
    marginLeft: 10,
    marginTop: 10,
  },
  stat: {
    fontSize: 26,
    fontWeight: 'bold',
  },

  statTitle: {
    fontSize: 20,
  },

  statsContainer: {
    alignItems: 'center',
    marginHorizontal: 5,
    marginVertical: 20,
  },

  container: {
    flexDirection: 'row'
  },

  mainContainer: {
    paddingLeft: "1%",
    paddingTop: "1%",
    paddingRight: "1%",
    width: "68%",
  },

  sideContainer: {
    paddingTop: "1%",
    paddingRight: "1%",
    width: "29%",
  },

  dropDown: {
    borderRadius: 5,
    borderWidth: 1,
    borderColor: Colors.borderGrey,    
    fontSize: 16,
    padding: 10,
    width: "100%"
  }
});
export default ReuseAccessPanel