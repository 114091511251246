import React from 'react';
import { StyleSheet, View, TextInput, Dimensions, ActivityIndicator } from 'react-native';
import AccountApi from "../../services/modules/account/api";
import Colors from '../../constants/Colors';
import Button from '../ui/Button';
import { TraxxText } from '../ui/TraxxText';
import { TraxxTextInput } from '../ui/TraxxTextInput';

interface Props {
  onComplete: () => void;
}

const ForgotPasswordForm: React.FC<Props> = ({ 
  onComplete
}) => { 
  const [email, setEmail] = React.useState('');
  const [code, setCode] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [saving, setSaving] = React.useState(false);
  const [sending, setSending] = React.useState(false);

  const updatePassword = () => {
    if (password.trim().length == 0 || code.trim().length == 0 || email.trim().length == 0) {
      alert("Please fill in all fields");
      return;
    }

    if (password != confirmPassword) {
      alert("Passwords do not match");
      return;
    }

    setSaving(true);
    
    AccountApi.updatePassword(email, password, code)
    .then(() => {
      setSaving(false);
      alert("Your password has been updated. Please login using your new password.");
      onComplete();
    })
    .catch((error) => {
      setSaving(false);
      alert(error.data.message);
    });
  };

  const sendResetCode = () => {
    if (email.trim().length == 0) {
      alert("Please enter your email address");
      return;
    }

    setSending(true);
    AccountApi.sendResetCode(email)
    .then(() => {
      setSending(false);
      alert("Check your email for the code to reset your password.");
    })
    .catch((error) => {
      setSending(false);
      alert("Error Sending Reset Code");
    });
  }
  
  return (
    <View>      
      {saving && <View>
        <ActivityIndicator size="large" color="#000000" />
        <TraxxText style={styles.loadingText}>Saving new password, one moment...</TraxxText>
      </View>}
      {sending && <View>
        <ActivityIndicator size="large" color="#000000" />
        <TraxxText style={styles.loadingText}>Sending code, one moment...</TraxxText>
      </View>}


      {!saving && !sending && <View style={styles.inputContainer}>
        <View style={[styles.panel, {marginTop: 0}]}>
          <TraxxText>To reset your password, enter your email address and press "Send Reset Code". We will email you the code required to reset your password.</TraxxText>
        </View>

        <View style={styles.inputView}>
          <TraxxTextInput
            style={styles.input}
            value={email}
            placeholder="Enter your email address"
            placeholderTextColor="#003f5c"
            onChangeText={(email: string) => setEmail(email)}
          />
        </View>
        
        <Button onPress={sendResetCode}
          text="Send Reset Code">
        </Button>

        <View style={[styles.panel, {marginTop: 40}]}>
          <TraxxText>Enter the new password and your reset code below to change your password.</TraxxText>
        </View>

        <View style={styles.inputView}>
          <TraxxTextInput
            style={styles.input}
            value={code}
            placeholder="Enter your reset code"
            placeholderTextColor="#003f5c"
            onChangeText={(resetCode: string) => setCode(resetCode)}
          />
        </View>

        <View style={styles.inputView}>
          <TraxxTextInput
            style={styles.input}
            placeholder="Enter a password"
            placeholderTextColor="#003f5c"
            secureTextEntry={true}
            onChangeText={(password: string) => setPassword(password)}
          />
        </View>
        
        <View style={styles.inputView}>
          <TraxxTextInput
            style={styles.input}
            placeholder="Confirm your password"
            placeholderTextColor="#003f5c"
            secureTextEntry={true}
            onChangeText={(confirmPassword: string) => setConfirmPassword(confirmPassword)}
          />
        </View>
        
        <Button onPress={updatePassword}
          text="Save New Password">
        </Button>
      </View>}
    </View>
  )
};

const width = Dimensions.get('window').width

const styles = StyleSheet.create({
  inputContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },

  inputView: {
    backgroundColor: "#fff",
    borderRadius: 30,
    height: 45,
    width: 300,
    marginBottom: 20,
    alignItems: "center",
  },

  input: {
    height: 50,
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderColor: Colors.borderGrey,
    borderWidth: 1,
    width: 300,
    borderRadius: 20
  },

  panel: {
    width: "95%",
    marginBottom: 20,
    backgroundColor:  Colors.white,
    borderRadius: 10,
    padding: 10,
  },

  loadingText: {
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold',
    color: Colors.black
  },
});

export default ForgotPasswordForm