import { combineReducers } from "redux";
import { accountReducer } from "./modules/account/reducer";
import { appReducer } from "./modules/app/reducer";
import { authReducer } from './modules/auth/reducer'
import { haulReducer } from "./modules/haul/reducer";
import { paymentReducer } from "./modules/payment/reducer";
import { projectReducer } from "./modules/project/reducer";

export const rootReducer = combineReducers({
  auth: authReducer,
  project: projectReducer,
  app: appReducer,
  account: accountReducer,
  haul: haulReducer,
  payment: paymentReducer
})