import { Dispatch } from 'redux';
import { SELECT_SUBSCRIPTION, PaymentDispatchTypes, SET_CODE, SET_INVITE_ID } from './types';

export const selectSubscription = (subscriptionId: number, accountTypeId: number) => async (dispatch: Dispatch<PaymentDispatchTypes>) => {
  dispatch({type: SELECT_SUBSCRIPTION, payload: {subscriptionId: subscriptionId, accountTypeId: accountTypeId}});
}

export const setCode = (code: string) => async (dispatch: Dispatch<PaymentDispatchTypes>) => {
  dispatch({type: SET_CODE, payload: {code: code}});
}

export const setInviteId = (inviteId: number) => async (dispatch: Dispatch<PaymentDispatchTypes>) => {
  dispatch({type: SET_INVITE_ID, payload: {inviteId: inviteId}});
}