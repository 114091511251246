import { Account } from "../../types";
import { UPDATE_ACCOUNT, REGISTER_FAILURE, REGISTER_LOADING, REGISTER_SUCCESS, AccountDispatchTypes, VIEW_ACCOUNT } from "./types";

export const initialState: Account = { id: '', firstName: '', lastName: '', email: '', password: '', refreshToken: '', token: '', photo: '', vehicleId: '', role: '', metric: 0 };

export const accountReducer = (
  state: Account = initialState,
  action: AccountDispatchTypes
) => {
  switch (action.type){
    case UPDATE_ACCOUNT:
      return {
        ...state,
      }
    case REGISTER_LOADING:
      return {
        ...state,
        loading: true
      }
    case REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        account: action.payload,
      }
    case REGISTER_FAILURE:
      return {
        ...state,
        loading: false,
        message: "Could not create your account",
      }
    case VIEW_ACCOUNT:
      return {
        ...state,
        loading: false,
        id: action.payload.id,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        email: action.payload.userName,
        vehicleId: action.payload.vehicleId,
      }
    default:
        return state;
  }
}