import axiosInstance from "../../interceptor";

const ReportApi = {
  projectHaulLog: (projectId: number, startDate: string, endDate: string, selectedHauler: string, selectedCompany: number, selectedResuse: number) => axiosInstance.get('/report/haullogproject/' + projectId + '/' + startDate + '/' + endDate + '/' + selectedHauler + '/' + selectedCompany + '/' + selectedResuse),
  reuseHaulLog: (startDate: string, endDate: string, selectedHauler: string, selectedCompany: number, selectedResuse: number) => axiosInstance.get('/report/haullogreuse/' + startDate + '/' + endDate + '/' + selectedHauler + '/' + selectedCompany + '/' + selectedResuse),
  companyHaulLog: (startDate: string, endDate: string, selectedHauler: string, selectedCompany: number, selectedResuse: number) => axiosInstance.get('/report/haullogcompany/' + startDate + '/' + endDate + '/' + selectedHauler + '/' + selectedCompany + '/' + selectedResuse),
  projectHaulLogExcel: (projectId: number, startDate: string, endDate: string, selectedHauler: string, selectedCompany: number, selectedResuse: number) => axiosInstance.get('/report/haullogprojectexcel/' + projectId + '/' + startDate + '/' + endDate + '/' + selectedHauler + '/' + selectedCompany + '/' + selectedResuse),
  reuseHaulLogExcel: (startDate: string, endDate: string, selectedHauler: string, selectedCompany: number, selectedResuse: number) => axiosInstance.get('/report/haullogreuseexcel/' + startDate + '/' + endDate + '/' + selectedHauler + '/' + selectedCompany + '/' + selectedResuse),
  companyHaulLogExcel: (startDate: string, endDate: string, selectedHauler: string, selectedCompany: number, selectedResuse: number) => axiosInstance.get('/report/haullogcompanyexcel/' + startDate + '/' + endDate + '/' + selectedHauler + '/' + selectedCompany + '/' + selectedResuse),
};

export default ReportApi;