import { FontAwesome } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/core';
import React from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { useDispatch } from 'react-redux';
import { logout } from '../../services/modules/auth/actions';
import Button from '../ui/Button';

const ProjectButton: React.FC = ({ 
}) => { 
  const navigation = useNavigation();

  return (<View style={styles.drawerButtonContainer}>   
            <Button onPress={() => navigation.goBack()} text="Back to Project" />
          </View>);
}

const styles = StyleSheet.create({
  drawerButtonContainer: {
    marginLeft: 10
  },
});

export default ProjectButton;