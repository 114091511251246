import React, { useEffect } from 'react'

import {
  StyleSheet,
  Image,
  View,
  ScrollView,
  Dimensions,
  KeyboardAvoidingView,
  Platform,
  Linking,
  Text,
} from 'react-native';

import {useNavigation} from '@react-navigation/native';
import HaulerSignUpForm from '../components/hauler/HaulerSignUpForm'
import { useDispatch, useSelector } from 'react-redux';
import Button from '../components/ui/Button';
import AppApi from '../services/modules/app/api';
import { loadSettings } from '../services/modules/app/actions';
import ReuseSignUpForm from '../components/reuse/ReuseSignUpForm';
import CompanySignUpForm from '../components/company/CompanySignUpForm';
import ManagerSignUpForm from '../components/manager/ManagerSignUpForm';
import Constants from 'expo-constants';
import Colors from '../constants/Colors';
import { RootState } from '../services/store';
import { TraxxText } from '../components/ui/TraxxText';

interface Props {
}

const SignUpScreen: React.FC<Props> = ({ 
}) => {   
  const navigation = useNavigation();
  const dispatch = useDispatch();
  
  const paymentState = useSelector((state: RootState) => state.payment);

  const [signUpType, setSignUpType] = React.useState("");

  useEffect(() => {
    AppApi.loadSettings()
    .then((apiResponse: any) => {
      dispatch(loadSettings(apiResponse.data[0].value))
    }) 
  }, []);

  useEffect(() => {
     switch(paymentState.accountTypeId) {
        case 1:
          setSignUpType("Driver");
          break;

        case 2:
          setSignUpType("Company")
          break;

        default:
          break;
     }

     if (paymentState.code.length > 0) {
      setSignUpType("Driver")
     }
  }, [paymentState]);

  return (<KeyboardAvoidingView behavior={Platform.OS === "ios" ? "padding" : undefined} style={{backgroundColor: Colors.white,}}>           
      <ScrollView
        contentInsetAdjustmentBehavior="automatic">
        <View style={styles.container}>
          <View style={styles.inputContainer}>
            {signUpType == "" && <View>
              <TraxxText style={styles.title}>What is your primary role?</TraxxText>
              <TraxxText style={styles.subTitle}>Hauler</TraxxText>
              {paymentState.accountTypeId == 0 && <Button
                style={styles.button}
                text="Driver"
                onPress={() => setSignUpType("Driver")}
                />}
              <Button
                style={styles.button}
                text="Hauling Company"
                onPress={() => setSignUpType("Company")}
                />
              <TraxxText style={styles.subTitle}>Project Administrator</TraxxText>
              <Button
                style={styles.button}
                text="Project Lead"
                onPress={() => setSignUpType("Manager")}
                />
              <Button
                style={styles.button}
                text="Project Manager"
                onPress={() => setSignUpType("Manager")}
                />
              <Button
                style={styles.button}
                text="Qualified Person"
                onPress={() => setSignUpType("Manager")}
                />
            </View>}
            
            {signUpType != "" && <View>
              {signUpType == "Driver" && 
                <HaulerSignUpForm 
                  role={signUpType} />}
              {signUpType == "Manager" && 
                <ManagerSignUpForm 
                  role={signUpType} />}
              {signUpType == "Reuse" && 
                <ReuseSignUpForm 
                  role={signUpType} />}
              {signUpType == "Company" && 
                <CompanySignUpForm 
                  role={signUpType} />}

              <Button
                style={[styles.button, {marginTop: 20}]}
                text="Go Back"
                onPress={() => setSignUpType("")}
                />
            </View>}

            <Button
              style={[styles.button, {marginTop: 20}]}
              text="Cancel"
              onPress={() => navigation.goBack()}
              />
            
            <TraxxText style={styles.versionText}>Traxx Version {Constants?.manifest?.version}</TraxxText>
            <TraxxText style={{color:Colors.primaryBrown, textDecorationLine: 'underline', marginTop: 10, marginBottom: 10, alignSelf: 'center'}} onPress={() => Linking.openURL('http://traxxprivacy.digitalgrounds.ca/')}>View our privacy policy</TraxxText>
          </View>
        </View>
      </ScrollView>
    </KeyboardAvoidingView>
  )
};

/*      
  <TraxxText style={styles.subTitle}>Site</TraxxText>
  <Button
    style={styles.button}
    text="Reuse Site"
    onPress={() => setSignUpType("Reuse")}
    />
*/

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    width: "50%",
    marginTop: 20
  },

  inputContainer: {
    width: "100%",
  },
 
  button: {
    width: "100%",
    marginVertical: 5,
    marginHorizontal: 10,
  },

  signUpContainer: {
    marginTop: 60
  },

  title: {
    fontSize: 20,
    textAlign: 'center',
  },

  subTitle: {
    fontWeight: 'bold',
    marginTop: 20,
    textAlign: 'center',
    fontSize: 20,
  },

  versionText: {
    textAlign: 'center',
    marginTop: 10
  },
});

export default SignUpScreen