import axiosInstance from "../../interceptor";

const AuthApi = {
  login: (uid: string, pass: string) => axiosInstance.post('/auth/login', {userName: uid, password: pass}),
  updateVehicle: (vehicleId: string) => axiosInstance.post('/account/vehicle', {vehicleId: vehicleId}),
  updatePreferences: (metric: number) => axiosInstance.post('/account/preferences', {metric: metric, accountId: "temptillendpointfixed"}),
  updateLocation: (latitude: number, longitude: number) => axiosInstance.post('/account/location', {latitude: latitude, longitude: longitude}),
};

export default AuthApi;
