import React, { useEffect } from 'react'

import {
  View,
  StyleSheet,
  Dimensions,
  ActivityIndicator,
  RefreshControl,
  Image,
  Text,
} from 'react-native';

import {useFocusEffect, useIsFocused, useNavigation} from '@react-navigation/native';
import { ScrollView, TextInput } from 'react-native-gesture-handler';
import { useDispatch, useSelector } from 'react-redux';
import Moment from 'react-moment';
import { RootState } from '../../services/store';
import { createAccount, createHaulLogEntry, createOrganization, createProject, createSite, getAPIDate } from '../../services/helpers';
import HaulApi from '../../services/modules/haul/api';
import Button from '../ui/Button';
import GoogleMapReact from 'google-map-react';
import Colors from '../../constants/Colors';
import { selectEntry } from '../../services/modules/haul/actions';
import { convertToTons } from '../../utility/conversion';
import { TraxxText } from '../ui/TraxxText';

interface Props {
}

const HaulLogEntryViewerScene: React.FC<Props> = ({ 
}) => {   
  const dispatch = useDispatch()
  const isFocused = useIsFocused()
  const navigation = useNavigation()

  const appState = useSelector((state: RootState) => state.app);
  const haulState = useSelector((state: RootState) => state.haul);
  const authState = useSelector((state: RootState) => state.auth);

  const [loaded, setLoaded] = React.useState(false);
  const [haulLogEntry, setHaulLogEntry] = React.useState(createHaulLogEntry());
  const [project, setProject] = React.useState(createProject());
  const [pickUpSite, setPickUpSite] = React.useState(createSite());
  const [dropOffSite, setDropOffSite] = React.useState(createSite());
  const [pickUpLongitude, setPickUpLongitude] = React.useState(0);
  const [pickUpLatitude, setPickUpLatitude] = React.useState(0);
  const [dropOffLongitude, setDropOffLongitude] = React.useState(0);
  const [dropOffLatitude, setDropOffLatitude] = React.useState(0);
  const [refreshing, setRefreshing] = React.useState(false);
  const [entryView, setEntryView] = React.useState(0);

  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    HaulApi.load(haulState.id)
    .then((apiResponse: any) => {   
      const haulEntry = apiResponse.data;

      setProject(haulEntry.project);
      setPickUpSite(haulEntry.pickUpSite);

      if (haulEntry.dropOffSite != null) {
        setDropOffSite(haulEntry.dropOffSite);
      }

      setHaulLogEntry({id: haulEntry.id,
        accountId: haulEntry.accountId,
        weight: haulEntry.weight,
        weightType: haulEntry.weightType,
        statusId: haulEntry.statusId,
        createdBy: haulEntry.createdBy,
        dateCreated: haulEntry.dateCreated,
        pickUpDate: new Date(haulEntry.pickUpDate),
        pickUpSiteId: haulEntry.pickUpSiteId,
        pickUpSite: haulEntry.pickUpSite,
        dropOffDate: new Date(haulEntry.dropOffDate),
        dropOffSiteId: haulEntry.dropOffSiteId,
        dropOffSite: haulEntry.dropOffSite,
        projectId: haulEntry.projectId,
        vehicleId: haulEntry.vehicleId,
        project: {id: apiResponse.data.projectId, title: apiResponse.data.project.title},
        account: haulEntry.account,
        organization: createOrganization(),
        organizationId: 0,
        longitude: 0,
        latitude: 0,
        dropOffConfirmedName: haulEntry.dropOffConfirmedName,
        dropOffConfirmedDate: haulEntry.dropOffConfirmedDate
      });

      setRefreshing(false);
    })
  }, []);

  const close = (() => {
    navigation.goBack();
  });

  const updateEntryView = (selectedIndex: number) => {
    setEntryView(selectedIndex);
  }

  const confirmDeposit = () => {
    //setSaving(true);
    HaulApi.confirmDeposit(haulLogEntry.id)
    .then(() => {
      //setSaving(false);
      
      let haulInfo = {
        ...haulLogEntry,
        dropOffConfirmedName: authState.firstName + " " + authState.lastName,
        dropOffConfirmedDate: new Date()
      } 

      setHaulLogEntry(haulInfo)
    });
  }

  const editHaulLog = () => {      
    dispatch(selectEntry(haulState.id));
    navigation.navigate("HaulLogEntryEditor");
  }

  const MapPin = ({lat, lng}: {lat: any, lng: any}) => {
    return (<Image
      source={require('../../assets/images/map_marker.png')} 
      style={{height: 32, width:60 }} />)
  };

  useFocusEffect (
    React.useCallback(() => {       
      if (isFocused) {
        HaulApi.load(haulState.id)
        .then((apiResponse: any) => {   
          const haulEntry = apiResponse.data;

          setProject(haulEntry.project);
          setPickUpSite(haulEntry.pickUpSite);

          let account = createAccount();

          if (haulEntry.account) {
            account = haulEntry.account;
          }

          if (haulEntry.dropOffSite != null) {
            setDropOffSite(haulEntry.dropOffSite);
          }

          if (haulEntry.pickUpLocation.longitude != 0.0 && haulEntry.pickUpLocation.latitude != 0.0) {
            setPickUpLatitude(haulEntry.pickUpLocation.latitude);
            setPickUpLongitude(haulEntry.pickUpLocation.longitude);
          }

          if (haulEntry.dropOffLocation.longitude != 0.0 && haulEntry.dropOffLocation.latitude != 0.0) {
            setDropOffLatitude(haulEntry.dropOffLocation.latitude);
            setDropOffLongitude(haulEntry.dropOffLocation.longitude);
          }
          
          setHaulLogEntry({id: haulEntry.id,
            accountId: haulEntry.accountId,
            weight: haulEntry.weight,
            weightType: haulEntry.weightType,
            statusId: haulEntry.statusId,
            createdBy: haulEntry.createdBy,
            dateCreated: haulEntry.dateCreated,
            pickUpDate: getAPIDate(haulEntry.pickUpDate),
            pickUpSiteId: haulEntry.pickUpSiteId,
            pickUpSite: haulEntry.pickUpSite,
            dropOffDate: haulEntry.dropOffDate == null ? new Date() : getAPIDate(haulEntry.dropOffDate),
            dropOffSiteId: haulEntry.dropOffSiteId,
            dropOffSite: haulEntry.dropOffSite,
            projectId: haulEntry.projectId,
            vehicleId: haulEntry.vehicleId,
            project: {id: apiResponse.data.projectId, title: apiResponse.data.project.title},
            account: account,
            organization: haulEntry.organization,
            organizationId: 0,
            longitude: 0,
            latitude: 0,
            dropOffConfirmedName: haulEntry.dropOffConfirmedName,
            dropOffConfirmedDate: haulEntry.dropOffConfirmedDate
          });

          setLoaded(true)
        })
      }
    }, [isFocused])
  )
  
  return (<ScrollView
            contentInsetAdjustmentBehavior="automatic"
            refreshControl={
              <RefreshControl
                refreshing={refreshing}
                onRefresh={onRefresh}
              />
            }>         

            {!loaded &&
              <View>
                <ActivityIndicator size="large" color="#3F51B5" />
                <TraxxText style={styles.searchingText}>Loading...</TraxxText>
              </View>
            }

            {loaded && <View>   
             {authState.role != "Reuse" && authState.role != "Manager" && <Button onPress={() => editHaulLog()} text="Edit" style={styles.headerButton} />}
             {authState.role == "Reuse" && <View style={styles.sectionContainer}>
                  <View style={styles.sectionHeaderView}>
                    <TraxxText style={styles.sectionHeader}>DEPOSIT CONFIRMATION</TraxxText>
                  </View>
                  {haulLogEntry.dropOffConfirmedName != "" && <View style={{marginTop: 10}}>
                    <View style={{flexDirection: "row"}}><TraxxText style={styles.inputLabel}>Confirmed by </TraxxText><TraxxText style={{fontWeight: 'bold', fontSize: 16, marginTop: 10}}>{haulLogEntry.dropOffConfirmedName}</TraxxText></View>
                    <TraxxText style={styles.inputLabel}>On <Moment element={Text} format="D-MMM-yyyy H:mm A" style={styles.inputText}>{haulLogEntry.dropOffConfirmedDate}</Moment></TraxxText>
                  </View>}
                  {haulLogEntry.dropOffConfirmedName == "" && <View style={{marginTop: 10}}>
                    <Button text='Confirm Deposit' onPress={() => confirmDeposit()} />
                  </View>}
                </View>}
              <View style={styles.inputContainer}>
                <View style={styles.sectionContainer}>
                  <View style={styles.sectionHeaderView}>
                    <TraxxText style={styles.sectionHeader}>DETAILS</TraxxText>
                  </View>
                  <TraxxText style={styles.inputLabel}>Weight</TraxxText>
                  <View style={styles.inputGroup}>
                    <View style={styles.inputBox}>
                      <TraxxText style={styles.inputText}>{authState.metric == 0 ? convertToTons(haulLogEntry.weight).toFixed(2): haulLogEntry.weight.toFixed(2)}</TraxxText>
                    </View>
                    {authState.metric == 0 && <Button
                      style={authState.metric == 0 ? styles.buttonOn : styles.buttonOff}
                      text="Tonne"
                      onPress={() => {}} />}
                    {authState.metric == 1 && <Button
                      style={authState.metric == 1 ? styles.buttonOn : styles.buttonOff}  
                      text="Cu. Meter"
                      onPress={() => {}} />}
                  </View>
                  <TraxxText style={styles.inputLabel}>Pick Up Date</TraxxText>
                  <View style={styles.inputGroup}>
                    <View style={styles.inputBox}>
                      <Moment element={Text} format="D-MMM-yyyy H:mm A" style={styles.inputText}>{haulLogEntry.pickUpDate}</Moment>
                    </View>
                  </View>
                  {haulLogEntry.statusId > 1 && <View>
                    <TraxxText style={styles.inputLabel}>Drop Off Date</TraxxText>
                    <View style={styles.inputGroup}>
                      <View style={styles.inputBox}>
                        <Moment element={Text} format="D-MMM-yyyy H:mm A" style={styles.inputText}>{haulLogEntry.dropOffDate}</Moment>
                      </View>
                    </View>
                  </View>}
                </View>
                <View style={styles.sectionContainer}>
                  <View style={styles.sectionHeaderView}>
                    <TraxxText style={styles.sectionHeader}>VEHICLE</TraxxText>
                  </View>
                  <TraxxText style={styles.inputLabel}>Driver</TraxxText>
                  <View style={styles.inputBox}>
                    <TraxxText style={styles.inputText}>{haulLogEntry.account.firstName} {haulLogEntry.account.lastName}</TraxxText>
                  </View>
                  <TraxxText style={styles.inputLabel}>License Plate</TraxxText>
                  <View style={styles.inputBox}>
                    <TraxxText style={styles.inputText}>{haulLogEntry.vehicleId.toString()}</TraxxText>
                  </View>
                  <TraxxText style={styles.inputLabel}>Hauling Company</TraxxText>
                  <View style={styles.inputBox}>
                    <TraxxText style={styles.inputText}>{haulLogEntry.organization.title}</TraxxText>
                  </View>
                </View>
                <View style={styles.sectionContainer}>
                  <View style={styles.sectionHeaderView}>
                    <TraxxText style={styles.sectionHeader}>PROJECT</TraxxText>
                  </View>
                  {project.id != 0 && <View>
                    <TraxxText style={styles.inputLabel}>Name</TraxxText>
                    <View style={styles.inputBox}>
                      <TraxxText style={{marginBottom:4, fontWeight:'bold', fontSize: 16}}>{project.title}</TraxxText>
                    </View>
                  </View>}
                </View>
                <View style={styles.sectionContainer}>
                  <View style={styles.sectionHeaderView}>
                    <TraxxText style={styles.sectionHeader}>PICK UP SITE</TraxxText>
                  </View>
                  {pickUpLatitude != 0 && pickUpLongitude != 0 && <View>
                    <GoogleMapReact 
                      style={{width: "100%", height: 200}}
                      bootstrapURLKeys={{ key: appState.googleKey }}
                      defaultCenter={{lat: pickUpLatitude, lng: pickUpLongitude}}
                      defaultZoom={16}>
                      <MapPin
                        lat={pickUpLatitude}
                        lng={pickUpLongitude}
                      />
                    </GoogleMapReact>
                  </View>}
                  {pickUpSite.id == 0 && <TraxxText style={{fontSize: 16, padding: 10}}>No pick-up site selected.</TraxxText>}
                  {pickUpSite.id != 0 && <View>
                    <TraxxText style={styles.inputLabel}>Address</TraxxText>
                    <View style={styles.inputBox}>
                      <TraxxText style={styles.inputText}>{pickUpSite.title}</TraxxText>
                      <TraxxText>{pickUpSite.address}</TraxxText>
                      {pickUpSite.addressOptional != null && <TraxxText>{pickUpSite.addressOptional}</TraxxText>}
                      <TraxxText>{pickUpSite.city}</TraxxText>
                      <TraxxText>{pickUpSite.province}, {pickUpSite.postalCode}</TraxxText>
                    </View>
                    <TraxxText style={styles.inputLabel}>Contact</TraxxText>
                    <View style={styles.inputBox}>          
                      {pickUpSite.contactName == null && <TraxxText>No contact specified</TraxxText>}          
                      <TraxxText style={styles.inputText}>{pickUpSite.contactName}</TraxxText>     
                      <TraxxText>{pickUpSite.contactEmail}</TraxxText>     
                      <TraxxText>{pickUpSite.contactPhone}</TraxxText>
                    </View>
                  </View>}
                </View>
                {haulLogEntry.statusId > 0 && <View style={styles.sectionContainer}>
                  <View style={styles.sectionHeaderView}>
                    <TraxxText style={styles.sectionHeader}>DROP OFF SITE</TraxxText>
                  </View>
                  {dropOffSite.id == 0 && <TraxxText style={{fontSize: 16, padding: 10}}>No drop off site selected.</TraxxText>}
                  {dropOffLatitude != 0 && dropOffLongitude != 0 && <View>
                    <GoogleMapReact 
                      style={{width: "100%", height: 200}}
                      bootstrapURLKeys={{ key: appState.googleKey }}
                      defaultCenter={{lat: dropOffLatitude, lng: dropOffLongitude}}
                      defaultZoom={16}>
                      <MapPin
                        lat={dropOffLatitude}
                        lng={dropOffLongitude}
                      />
                    </GoogleMapReact>
                  </View>}
                  {dropOffSite.id != 0 && <View>
                    <TraxxText style={styles.inputLabel}>Address</TraxxText>
                    <View style={styles.inputBox}>
                      <TraxxText style={styles.inputText}>{dropOffSite.title}</TraxxText>
                      <TraxxText>{dropOffSite.address}</TraxxText>
                      {dropOffSite.addressOptional != null && <TraxxText>{dropOffSite.addressOptional}</TraxxText>}
                      <TraxxText>{dropOffSite.city}</TraxxText>
                      <TraxxText>{dropOffSite.province}, {dropOffSite.postalCode}</TraxxText>
                    </View>
                    <TraxxText style={styles.inputLabel}>Contact</TraxxText>
                    <View style={styles.inputBox}>           
                      {dropOffSite.contactName == null && <TraxxText>No contact specified</TraxxText>}         
                      <TraxxText style={styles.inputText}>{dropOffSite.contactName}</TraxxText>     
                      <TraxxText>{dropOffSite.contactEmail}</TraxxText>     
                      <TraxxText>{dropOffSite.contactPhone}</TraxxText>
                    </View>
                  </View>}
                </View>}
              </View>

              <Button onPress={close}
                style={styles.button}
                text="Close">
              </Button>
            </View>}  
          </ScrollView>
  )
};

const width = Dimensions.get('window').width;

const styles = StyleSheet.create({
  headerButton: {
    margin: 5,
  },

  inputContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    width: width,
  },

  inputView: {
    backgroundColor: "#fff",
    borderRadius: 30,
    width: "70%",
    height: 45,
    marginBottom: 20,
    alignItems: "center",
  },

  input: {
    borderRadius: 5,
    borderWidth: 1,
    borderColor: Colors.borderGrey,
    backgroundColor: Colors.white,
    fontSize: 16,
    paddingHorizontal: 10,
    fontWeight: 'bold',
  },

  inputGroup: {
    flexDirection: 'row',
    marginLeft: 5,
  },

  inputBox: {
    borderRadius: 5,
    borderWidth: 1,
    borderColor: Colors.borderGrey,   
    backgroundColor: Colors.white, 
    fontSize: 16,
    padding: 10,
  },

  inputText: {
    fontSize: 16,
    fontWeight: 'bold',
  },

  inputLabel: {
    fontSize: 16,
    marginTop: 10,
    marginLeft: 5,
  },

  searchingText: {
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold'
  },

  comingSoon: {
    fontSize: 20,
    color: "#fff"
  },

  button : {
    width: width - 20,
    margin: 10,
  },
  buttonOn: {
    backgroundColor: Colors.primaryBrown,
    color: Colors.borderDarkGrey,
    margin: 4,
  },
  buttonOff: {
    backgroundColor: Colors.primary,
    margin: 4,
  },
  sectionContainer: {
    width: width - 20,
    margin: 10
  },
  sectionHeader: {
    fontWeight: 'bold',
    fontSize: 20,
    flex: 1,
    margin: 4,
  },
  sectionHeaderView: {
    flexDirection: 'row',
  },
  sectionHeaderButton : {
    margin: 4,
  },
});
export default HaulLogEntryViewerScene