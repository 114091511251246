import React from 'react'

import {
  View,
  StyleSheet,
  Text,
} from 'react-native';
import { useSelector } from 'react-redux';
import HaulerInfo from '../components/hauler/HaulerInfo';
import HaulerList from '../components/hauler/HaulerList';
import { TraxxText } from '../components/ui/TraxxText';
import { RootState } from '../services/store';


interface Props {
}

const HaulerInfoScreen: React.FC<Props> = ({ 
}) => {   
  const accountState = useSelector((state: RootState) => state.account);
  const authState = useSelector((state: RootState) => state.auth);
  
  return (<View style={styles.componentContainer}>          
            <HaulerInfo />    
            {authState.role == "Manager" && <HaulerList title="Latest Hauls" accountId={accountState.id} />}
            {authState.role == "Reuse" && <HaulerList title="Latest Hauls" accountId={accountState.id} organizationId={authState.organizationId} />}
            {authState.role == "Company" && <HaulerList title="Latest Hauls" accountId={accountState.id} organizationId={authState.organizationId} />}    
          </View>
  )
};

const styles = StyleSheet.create({ 
  loadingText: {
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold'
  },
  componentContainer: {   
    padding: "1%",
    width: "100%"
  },
  subHeader: {
    fontWeight: 'bold',
    fontSize: 18,
    flex: 1,
  },
});

export default HaulerInfoScreen