import { useNavigation } from '@react-navigation/native';
import React, { useEffect } from 'react'

import {
  useColorScheme,
  View,
  StyleSheet,
  Dimensions,
  ActivityIndicator,
  Modal,
  Image,
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import Colors from '../../constants/Colors';
import { updateFirstTime, updateFirstTimeTrucks } from '../../services/modules/auth/actions';
import { RootState } from '../../services/store';
import Button from '../ui/Button';
import { TraxxText } from '../ui/TraxxText';
import HaulerList from './HaulerList';

interface Props {
}

const HaulerDashboard: React.FC<Props> = ({ 
}) => {   
  const authState = useSelector((state: RootState) => state.auth)

  const navigation = useNavigation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (authState.firstTimeTrucks) {
      setTimeout(() => {
        navigation.navigate("Vehicles");
        dispatch(updateFirstTimeTrucks(false));
      }, 100)
    }
  }, [authState])

  return (<View style={styles.container}>
            <View style={styles.mainContainer}>              
              {authState.firstTime && <View style={{borderRadius: 10, borderWidth: 1, borderColor: Colors.borderDarkGrey, padding: 10}}>
                <TraxxText style={{textAlign: 'center', fontSize: 18, fontWeight: 'bold'}}>Download the Traxx app to manage your hauls much easier</TraxxText>
                <View style={{flexDirection: 'row', alignSelf: 'center'}}>
                  <Image style={[styles.image, {marginTop: 10}]} source={require('../../assets/images/getongoogle.png')} />
                  <Image style={[styles.image, {marginTop: 10, marginLeft: 10}]} source={require('../../assets/images/getonapple.png')} />
                </View>
                <Button text='Close' onPress={() => dispatch(updateFirstTime(false))} style={{marginTop: 10}} />
              </View>}  
              <HaulerList title="Haul Log" showAdd={true} />
            </View>
          </View>
  )
};

const width = Dimensions.get('window').width;

const styles = StyleSheet.create({ 
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    width: "100%",
    marginTop: 20
  },

  mainContainer: {
    width: "50%",
  },

  image: {
    width: 200,
    height: 71
  },

  stat: {
    fontSize: 26,
    fontWeight: 'bold',
  },

  statTitle: {
    fontSize: 20,
  },

  statsContainer: {
    width: width / 2 - 10,
    alignItems: 'center',
    marginHorizontal: 5,
    marginVertical: 20,
  },

  loadingText: {
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold'
  },

  button : {
    margin: 4,
  },

  modalView: {

  },

  modalText: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 20,
    margin: 20,
  },

  entryButton: {
    width: width - 20,
    marginLeft: 10,
    marginTop: 10,
  },

  inputLabel: {
    fontSize: 16,
    marginTop: 10,
    marginLeft: 15,
  },

  inputGroup: {
    flexDirection: 'row',
    marginLeft: 5,
  },

  input: {
    height: 50,
    width: width - 20,
    paddingVertical: 10,
    paddingHorizontal: 20,
    margin: 10,
    flex: 1,
    borderColor: Colors.borderGrey,
    borderWidth: 1,
    borderRadius: 20
  },

  sideContainer: {
    marginTop: "2%",
    marginRight: "2%",
    width: "28%",
  },
});
export default HaulerDashboard