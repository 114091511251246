import React, { useEffect } from 'react'
import { ActivityIndicator, Alert, Dimensions, Modal, Platform, StyleSheet, Text, View } from 'react-native';
import { FlatList, ScrollView, TextInput } from 'react-native-gesture-handler';
import { Site } from '../../services/types';
import Button from '../ui/Button';
import SiteApi from '../../services/modules/site/api';
import { createSite } from '../../services/helpers';
import PlacesSearchBar from '../location/PlacesSearchBar';
import { useSelector } from 'react-redux';
import { RootState } from '../../services/store';
import Colors from '../../constants/Colors';
import { SearchBar } from 'react-native-elements';
import { SearchBarBaseProps } from 'react-native-elements/dist/searchbar/SearchBar';
import * as Location from 'expo-location';
import { TraxxText } from '../ui/TraxxText';
import { TraxxTextInput } from '../ui/TraxxTextInput';

const SafeSearchBar = (SearchBar as unknown) as React.FC<SearchBarBaseProps>;

interface Props {
  onItemSelected: (data: any) => void;
  onCancel?: () => void;
  onNoResults?: () => void;
  verified?: boolean;
  showCancel?: boolean;
  showAdd?: boolean;
  showPressTitle?: boolean;
  placeholder?: string;
  admin?: boolean;
}

const SiteSearch: React.FC<Props> = ({ 
  onItemSelected,
  onCancel,
  onNoResults,
  verified,
  showCancel,
  showAdd,
  showPressTitle,
  placeholder,
  admin
}) => { 
  const appState = useSelector((state: RootState) => state.app)
  const projectState = useSelector((state: RootState) => state.project)

  const [searching, setSearching] = React.useState(false);
  const [noResults, setNoResults] = React.useState(false);
  const [results, setResults] = React.useState([]);
  const [siteModalVisible, setSiteModalVisible] = React.useState(false);
  const [newSite, setSite] = React.useState(false);
  const [newSiteTitle, setNewSiteTitle] = React.useState("");
  const [newSiteAddress, setNewSiteAddress] = React.useState("");
  const [newSiteCity, setNewSiteCity] = React.useState("");
  const [newSiteProvince, setNewSiteProvince] = React.useState("");
  const [newSitePostalCode, setNewSitePostalCode] = React.useState("");
  const [newSiteId, setNewSiteId] = React.useState(0);
  const [noNearby, setNoNearby] = React.useState(false);
  const [keyword, setKeyword] = React.useState('');
  const [findingLocation, setFindingLocation] = React.useState(false);

  const itemPressed = (data: any) => {
    onItemSelected(data);
  }

  const clearSearch = () => {
      setResults([]);
  }

  const getVerified = () => {
      if (verified == undefined) {
        return false;
      }
      
      return verified;
  }

  const getShowCancel = () => {
      if (showCancel == undefined) {
        return false;
      }
      
      return showCancel;
  }

  const getShowAdd = () => {
      if (showAdd == undefined) {
        return false;
      }
      
      return showAdd;
  }

  const getAdmin = () => {
      if (admin == undefined) {
        return false;
      }
      
      return admin;
  }

  const getShowPressTitle = () => {
      if (showPressTitle == undefined) {
        return false;
      }
      
      return showPressTitle;
  }

  const getPlaceHolder = () => {
    if (placeholder == undefined) {
      return "Search sites";
    }

    return placeholder;
  }

  const checkOnCancel = () => {
      if (onCancel) {
        onCancel();
      }
  }

  const updateSearch = (text: any) => {
    setKeyword(text);
    setSearching(true);
    setNoResults(false);
    setNoNearby(false);

    if (text.length > 2) {
      SiteApi.search(text)
      .then((apiResponse: any) => {  
        if (apiResponse.data.length == 0) {
          setNoResults(true);
          setResults([]);
        } else {
          setResults(apiResponse.data);
        }
        setSearching(false);
      });
    } else {
      setSearching(false);
      setResults([]);
    }
  };

  const loadResults = async () => {
    let finalStatus = true;

    let { status } = await Location.getForegroundPermissionsAsync();
    
    if (status !== 'granted') {
      let { status } = await Location.requestForegroundPermissionsAsync();

      if (status !== 'granted') {
        finalStatus = false;
      }
    }
    
    setSearching(true);

    if (!finalStatus) {
      SiteApi.list()
      .then((apiResponse: any) => {  
        if (apiResponse.data.length == 0) {
          if (onNoResults) {
            onNoResults();
          }
        } else {
          setResults(apiResponse.data);
        }
        setSearching(false);
      })
      return;
    }
    
    let location = await Location.getCurrentPositionAsync({accuracy: Location.Accuracy.Balanced,});
    const {latitude, longitude} = location.coords;

    SiteApi.listUsingGPS(latitude, longitude)
    .then((apiResponse: any) => { 
      if (apiResponse.data.length == 0) {
        if (onNoResults) {
          onNoResults();
        }
        setNoNearby(true);
      } else { 
        setResults(apiResponse.data);
      }
      setSearching(false);
    })
  }

  const checkPermission = async () => {    
    let { status } = await Location.getForegroundPermissionsAsync();

    if (status !== 'granted') {
      await Location.requestForegroundPermissionsAsync();
    }

    loadResults();
  };

  const saveNewSite = () => {
    if (newSiteTitle.trim().length == 0 || newSiteAddress.trim().length == 0 || newSiteCity.trim().length == 0 || newSiteProvince.trim().length == 0 || newSitePostalCode.trim().length == 0) {
      alert("Please enter the site title and address.");
      return;
    }

    SiteApi.save(newSiteId, getVerified(), newSiteTitle, newSiteAddress, newSiteCity, newSiteProvince, newSitePostalCode, projectState.id)
    .then((apiResponse: any) => {
      var site = createSite();

      site = {
        ...site,
        id: apiResponse.data.id,
        title: newSiteTitle,
        address: newSiteAddress,
        city: newSiteCity,
        province: newSiteProvince,
        postalCode: newSitePostalCode
      };

      setSiteModalVisible(false);

      if (getAdmin()) {
        if (keyword.length > 0) {
          updateSearch(keyword)
        } else {
          loadResults()
        }
      } else {
        onItemSelected(site);
      }
    })
    .catch(() => {
      alert("The site you are adding already exists. Please cancel adding a new site and go back to search for this site.");
    })
  }

  const addSite = () => {
    setNewSiteAddress("")
    setNewSiteCity("")
    setNewSitePostalCode("")
    setNewSiteProvince("")
    setNewSiteTitle("")
    setNewSiteId(0)
    setSiteModalVisible(true)
  }

  const editSite = (site: Site) => {
    setNewSiteAddress(site.address)
    setNewSiteCity(site.city)
    setNewSitePostalCode(site.postalCode)
    setNewSiteProvince(site.province)
    setNewSiteTitle(site.title)
    setNewSiteId(site.id)
    setSiteModalVisible(true)
  }

  const addProject = (site: Site) => {
    SiteApi.addProject(site.id, projectState.id)
    .then(() => {
      if (keyword.length > 0) {
        updateSearch(keyword)
      } else {
        loadResults()
      }
    })
  }

  const removeProject = (site: Site) => {
    SiteApi.removeProject(site.id, projectState.id)
    .then(() => {
      if (keyword.length > 0) {
        updateSearch(keyword)
      } else {
        loadResults()
      }
    })
  }

  useEffect(() => {
    checkPermission();
  }, []);

  useEffect(() => {
    if (appState.googleKey != "") {
      Location.setGoogleApiKey(appState.googleKey);
    }
  }, [appState]);

  /*
    <PlacesSearchBar
      onPlaceSelected={placeSelected}
      placeholder="Quick find (start typing address or place)"
    />
  */

  return ( 
    <View style={{backgroundColor: Colors.white}}>
      {siteModalVisible && <ScrollView style={styles.modalView}>
        {newSiteId == 0 && <TraxxText style={styles.modalText}>New Site</TraxxText>}
        <TraxxText style={styles.inputHeader}>Site Title</TraxxText>
        <View style={styles.inputGroup}>
          <TraxxTextInput
            style={styles.input}
            placeholder="Site title"
            placeholderTextColor="#003f5c"
            value={newSiteTitle}
            onChangeText={(newSiteTitle: string) => {
              setNewSiteTitle(newSiteTitle);
            }}
          />
        </View>       
        <TraxxText style={[styles.inputHeader,{marginTop: 10}]}>Site Address</TraxxText>   
        {!findingLocation && <View>
          <TraxxText style={styles.inputLabel}>Street</TraxxText>
          <View style={styles.inputGroup}>
            <TraxxTextInput
              style={styles.input}
              placeholder="Address"
              placeholderTextColor="#003f5c"
              value={newSiteAddress}
              onChangeText={(newSiteAddress: string) => {
                setNewSiteAddress(newSiteAddress);
              }}
            />
          </View>  
          <TraxxText style={styles.inputLabel}>City</TraxxText>
          <View style={styles.inputGroup}>
            <TraxxTextInput
              style={styles.input}
              placeholder="City"
              placeholderTextColor="#003f5c"
              value={newSiteCity}
              onChangeText={(newSiteCity: string) => {
                setNewSiteCity(newSiteCity);
              }}
            />
          </View>
          <TraxxText style={styles.inputLabel}>Province</TraxxText>
          <View style={styles.inputGroup}>
            <TraxxTextInput
              style={styles.input}
              placeholder="Province"
              placeholderTextColor="#003f5c"
              value={newSiteProvince}
              onChangeText={(newSiteProvince: string) => {
                setNewSiteProvince(newSiteProvince);
              }}
            />
          </View>
          <TraxxText style={styles.inputLabel}>Postal Code</TraxxText>
          <View style={styles.inputGroup}>
            <TraxxTextInput
              style={styles.input}
              placeholder="Postal code"
              placeholderTextColor="#003f5c"
              value={newSitePostalCode}
              onChangeText={(newSitePostalCode: string) => {
                setNewSitePostalCode(newSitePostalCode);
              }}
            />
          </View>
          <Button style={styles.entryButton} onPress={() => saveNewSite()}
            text="Save">
          </Button>
          <Button style={styles.entryButton} onPress={() => setSiteModalVisible(!siteModalVisible)}
            text="Cancel">
          </Button>
        </View>}
    
        {findingLocation &&
          <View style={styles.activityContainer}>
            <ActivityIndicator size="large" color="#3F51B5" />
            <TraxxText style={styles.searchingText}>Finding location...</TraxxText>
          </View>
        }
      </ScrollView>}
      {!siteModalVisible && <View>
        <SafeSearchBar
          onChangeText={updateSearch}
          onCancel={clearSearch}
          placeholder={getPlaceHolder()}
          value={keyword}
          platform={"default"}
          inputStyle={styles.searchBarInput}
          containerStyle={styles.searchBarContainer}
          inputContainerStyle={styles.searchBarInputContainer}
        />
      </View>}
      
      {searching &&
        <View style={styles.activityContainer}>
          <ActivityIndicator size="large" color="#3F51B5" />
          <TraxxText style={styles.searchingText}>Searching...</TraxxText>
        </View>
      }
      
      {!siteModalVisible && noResults && !searching &&
        <View style={styles.activityContainer}>
          <TraxxText style={styles.nothingText}>Nothing found</TraxxText>
        </View>
      }
      
      {!siteModalVisible && !noResults && !searching && results.length == 0 &&
        <View style={styles.activityContainer}>
          <TraxxText style={styles.nothingText}>Enter at least three characters</TraxxText>
        </View>
      }
      
      {!siteModalVisible && noNearby && !searching &&
        <View style={styles.activityContainer}>
          <TraxxText style={styles.nothingText}>Nothing nearby found, please search for a site.</TraxxText>
        </View>
      }
      
      {!siteModalVisible && !searching && !noResults && !noNearby && <View>
        {results.length > 0 && getShowPressTitle() && <TraxxText style={styles.instruction}>Press a site</TraxxText>}
        {results.map((item: Site) => {
          console.log(item)
          return (<View key={item.id.toString()}>
                    <div style={{cursor: 'pointer'}} onClick={() => itemPressed(item)}>
                      <View style={styles.carouselItem}>
                        <TraxxText style={styles.title} numberOfLines={1} ellipsizeMode='tail'>{item.title}</TraxxText>
                        <TraxxText style={styles.subTitle} numberOfLines={1} ellipsizeMode='tail'>{item.address}, {item.city}</TraxxText>
                        {getAdmin() && <View style={{flexDirection: 'row', marginBottom: 10}}>
                          {item.projectId == projectState.id && <Button
                            text="Edit"
                            onPress={() => { editSite(item) }} />}
                          {item.projects.indexOf(projectState.id) == -1 && <Button
                            style={{marginLeft: 10}}
                            text="Add to Project"
                            onPress={() => { addProject(item) }} />}
                          {item.projects.indexOf(projectState.id) > -1 && <Button
                            style={{marginLeft: 10}}
                            text="Remove from Project"
                            onPress={() => { removeProject(item) }} />}
                        </View>}
                      </View>
                    </div>
                  </View>)
          })}
      </View>}

      {!siteModalVisible && getShowAdd() && <Button style={styles.button} onPress={() => addSite()}
        text="Add New Site">
      </Button>}

      {getShowCancel() && <Button style={styles.button} onPress={() => checkOnCancel()}
        text="Cancel">
      </Button>}
    </View>)
};

const width = Dimensions.get('window').width
  
const styles = StyleSheet.create({
  category: {
    fontSize: 18,
    fontStyle: 'italic',
  }, 

  screen: {
    flex: 1
  },

  listContainer: {
    flex: 12
  },

  activityContainer: {

  },

  nothingText: {
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold'
  },

  searchingText: {
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold'
  },

  header: {
    fontSize: 32,
    backgroundColor: "#fff",
    margin: 10,
  },
 
  item: {
    backgroundColor: "#f9c2ff",
    padding: 20,
    marginVertical: 8
  },

  title: {
    fontWeight: 'bold',
    marginLeft: 10,
    fontSize: 18
  },

  subTitle: {
    marginLeft: 10,
    fontSize: 16,
    marginBottom: 10,
  },

  image: {
    height: 90,
    width: 90,
    resizeMode: 'cover',
    borderRadius: 90
  },

  carouselItem: {
    margin: 10,
    borderBottomColor: Colors.borderGrey,
    borderBottomWidth: 1,    
  },

  bookImage: {
    width: 120,
    height:120,
    resizeMode: 'cover',
    marginRight: 10
  },

  bookCount: {
    fontSize: 20,
    fontWeight: 'bold'
  },

  courseBookImage: {
    width: 40,
    height: 40,
    marginRight: 5,
    marginTop: 5,
    resizeMode: 'cover'
  },

  signUpContainer: {
    marginTop: 60,
    marginBottom: 250
  },

  subNavContainer: {
    height: 50,
    flexDirection: 'row'
  },

  button: {
    margin: 5,
    width: width - 10
  },
  resultContainer: {
    width: width - 150,
  },
  modalView: {

  },
  modalText: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 20,
    margin: 20,
  },
  inputView: {
    flexDirection: 'row',
  },

  inputLabel: {
    fontSize: 16,
    marginTop: 10,
    marginLeft: 15,
  },

  inputHeader: {
    fontSize: 16,
    marginLeft: 15,
    fontWeight: 'bold'
  },

  inputGroup: {
    flexDirection: 'row',
    marginLeft: 5,
  },
  input: {
    height: 50,
    width: width - 20,
    paddingVertical: 10,
    paddingHorizontal: 20,
    margin: 10,
    flex: 1,
    borderColor: Colors.borderGrey,
    borderWidth: 1,
    borderRadius: 20
  },
  entryButton: {
    width: width - 20,
    marginLeft: 10,
    marginTop: 10,
  },
  searchBarInput: {
    backgroundColor: Colors.white,
    fontSize: 15,
  },
  searchBarContainer: {
    backgroundColor: Colors.white,
    borderColor: Colors.black,
    borderWidth: 1,
    borderRadius: 20,
    margin: 10,
  },
  searchBarInputContainer: {
    backgroundColor: Colors.white,
  },

  instruction: {
    marginVertical: 5,
    marginHorizontal: 15,
  },
});

export default SiteSearch
