import { AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { Account } from '../../types';
import AccountApi from './api'
import { REGISTER_SUCCESS, REGISTER_FAILURE, REGISTER_LOADING, AccountDispatchTypes, VIEW_ACCOUNT } from './types';

export const updateAccount = (account: Account) => async (dispatch: Dispatch<AccountDispatchTypes>) => {
  dispatch({type: REGISTER_LOADING});

  await AccountApi.update(account.firstName, account.lastName)
  .then((apiResponse: AxiosResponse<any>) => {
    dispatch({type: REGISTER_SUCCESS, payload: apiResponse.data});
  })
  .catch((e) => {
    dispatch({type: REGISTER_FAILURE, payload: e.getMessage()});
  });
}

export const selectAccount = (account: Account) => async (dispatch: Dispatch<AccountDispatchTypes>) => {
  dispatch({type: VIEW_ACCOUNT, payload: account});
}