import { FontAwesome } from '@expo/vector-icons';
import { DrawerActions, useNavigation } from '@react-navigation/native';
import React from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { useDispatch } from 'react-redux';
import { logout } from '../../services/modules/auth/actions';
import Button from '../ui/Button';

const HamburgerMenu: React.FC = ({ 
}) => { 
  const dispatch = useDispatch();

  const openDrawer = () => {
    dispatch(logout());
  }

  //<TouchableOpacity onPress={openDrawer}><FontAwesome name="bars" color="#000000" size={30}/></TouchableOpacity>

  return (<View style={styles.drawerButtonContainer}>   
            <Button onPress={openDrawer} text="Log Out" />
          </View>);
}

/*
 <TouchableOpacity onPress={openDrawer}>
              <FontAwesome name="bars" color="#000000" size={30}/>
            </TouchableOpacity>
            */
           
const styles = StyleSheet.create({
  drawerButtonContainer: {
    marginRight: 10
  },
});

export default HamburgerMenu;