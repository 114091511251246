import axiosInstance from "../../interceptor";
import { HaulLogEntry } from "../../types";

const HaulApi = {
  logForAccount: (accountId: string, page: number, filter: number) => axiosInstance.get('/haul/account/list/' + accountId + '/' + page + '/' + filter),
  logForOrganizationAccount: (accountId: string, organizationId: number, page: number, filter: number) => axiosInstance.get('/haul/organization/list/' + accountId + '/' + organizationId + '/' + page + '/' + filter),
  latestLogs: (page: number, filter: number) => axiosInstance.get('/haul/list/' + page + '/' + filter),
  companyLogsNoProject: () => axiosInstance.get('/haul/company/noprojectlist'),
  reuseLogs: (page: number) => axiosInstance.get('/haul/reuse/list/' + page),
  companyLogs: (page: number, filter: number) => axiosInstance.get('/haul/company/list/' + page + '/' + filter),
  inProgress: () => axiosInstance.get('/haul/list/inprogress'),
  confirmDeposit: (id: number) => axiosInstance.get('/haul/confirmdeposit/' + id),
  projectLog: (id: number) => axiosInstance.get('/haul/' + id + '/project'),
  open: () => axiosInstance.get('/haul/entry/open'),
  load: (id: number) => axiosInstance.get('/haul/' + id),
  update: (entry: HaulLogEntry) => axiosInstance.post('/haul', {id: entry.id, accountId: entry.accountId, projectId: entry.projectId, pickUpSiteId: entry.pickUpSiteId, pickUpDate: entry.pickUpDate, dropOffSiteId: entry.dropOffSiteId, dropOffDate: entry.dropOffDate, statusId: entry.statusId, weight: entry.weight, weightType: entry.weightType, vehicleId: entry.vehicleId, organizationId: entry.organizationId}),
};

export default HaulApi;