import React from 'react'
import { Text, View, TouchableOpacity, Dimensions, StyleSheet } from 'react-native'
import Colors from '../../constants/Colors';

interface Props {
  text: string;
  onPress: () => void;
  style?: {};
}

const Button: React.FC<Props> = ({ 
  text,
  onPress, 
  style
}) => { 
return (
  <TouchableOpacity onPress={onPress}>
    <View style={[styles.btnContainerStyle, style]}>
      <Text style={styles.btnTextStyle} numberOfLines={1} ellipsizeMode="tail">{text}</Text>
    </View>
  </TouchableOpacity>
)};

const width = Dimensions.get('window').width

const styles = StyleSheet.create({
  btnContainerStyle: {
    backgroundColor: Colors.primary,
    paddingVertical: 8,
    paddingHorizontal: 12,
    borderRadius: 5
  },
  btnTextStyle: {
    color: '#ffffff',
    fontSize: 14,
    textTransform: 'uppercase',
    textAlign: 'center',
    fontFamily: "PoppinsRegular",
  },

  whiteButton:{
    backgroundColor: '#ffffff',
    paddingVertical: 8,
    width: width/2,
    borderRadius:5,
  },
  btnTextStyleDashboardFirstTime:{
      color: 'blue',
      textAlign: 'center',
      textTransform: 'uppercase',
      fontSize: 16,
      fontFamily: 'Quicksand-Medium'
  }
})

export default Button;