import React from 'react'

import {
  useColorScheme,
  View,
  StyleSheet,
  ActivityIndicator,
} from 'react-native';

import {useNavigation} from '@react-navigation/native';
import { Text } from 'react-native';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../services/store';
import ManagerDashboard from '../components/manager/ManagerDashboard';
import { logout } from '../services/modules/auth/actions';
import AppApi from '../services/modules/app/api';
import { loadSettings } from '../services/modules/app/actions';
import HaulerDashboard from '../components/hauler/HaulerDashboard';
import ProjectMap from '../components/project/ProjectMap';
import Colors from '../constants/Colors';
import ReuseMap from '../components/organization/ReuseMap';

interface Props {
}

const ReuseMapScreen: React.FC<Props> = ({ 
}) => {   
  return (<View style={styles.componentContainer}>          
            <ReuseMap />
          </View>
  )
};

const styles = StyleSheet.create({ 
  loadingText: {
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold'
  },
  componentContainer: {   
    padding: "1%",
    width: "100%"
  },
});

export default ReuseMapScreen