import React, { useEffect } from 'react'
import { ActivityIndicator, Dimensions, StyleSheet, Text, View } from 'react-native';
import ProjectApi from '../../services/modules/project/api';
import { Project } from '../../services/types';
import Button from '../ui/Button';
import * as Location from 'expo-location';
import Colors from '../../constants/Colors';
import { SearchBar } from 'react-native-elements';
import { SearchBarBaseProps } from 'react-native-elements/dist/searchbar/SearchBar';
import { TraxxText } from '../ui/TraxxText';

const SafeSearchBar = (SearchBar as unknown) as React.FC<SearchBarBaseProps>;

interface Props {
  onItemSelected: (data: any) => void;
  onCancel?: () => void;
  showCancel?: boolean;
  placeholder?: string;
}

const ProjectSearch: React.FC<Props> = ({ 
  onItemSelected,
  onCancel,
  showCancel,
  placeholder
}) => { 
  const [searching, setSearching] = React.useState(false);
  const [noNearby, setNoNearby] = React.useState(false);
  const [results, setResults] = React.useState<any[]>([]);
  const [keyword, setKeyword] = React.useState('');

  const itemPressed = (data: any) => {
    onItemSelected(data);
  }

  const clearSearch = () => {
    setResults([]);
  }

  const getShowCancel = () => {
    if (showCancel == undefined) {
      return false;
    }

    return showCancel;
  }

  const checkOnCancel = () => {
    if (onCancel) {
      onCancel();
    }
  }

  const getPlaceHolder = () => {
    if (placeholder == undefined) {
      return "Search projects";
    }

    return placeholder;
  }

  const updateSearch = (text: string) => {
    setKeyword(text);
    setSearching(true);
    setNoNearby(false);
    
    if (text.length > 0) {
        ProjectApi.searchSite(text)
        .then((apiResponse: any) => { 
          const noProject = {id: 1, title: 'Project Not Listed', site: { id: 0 }};
  
          if (apiResponse.data.length > 0) {
            apiResponse.data.push(noProject);
            setResults(apiResponse.data);
          } else {
            const results: any = [];
            results.push(noProject);
            setResults(results);
          }
  
          setSearching(false);
        })
    } else {
      setSearching(false);
      setResults([]);
    }
  };

  const loadResults = async () => {   
    let finalStatus = true;

    let { status } = await Location.getForegroundPermissionsAsync();
    
    if (status !== 'granted') {
      let { status } = await Location.requestForegroundPermissionsAsync();

      if (status !== 'granted') {
        finalStatus = false;
      }
    }
    
    setSearching(true);
    
    if (!finalStatus) {     
      ProjectApi.list()
      .then((apiResponse: any) => {  
        const noProject = {id: 1, title: 'Project Not Listed', site: { id: 0 }};

        if (apiResponse.data.length > 0) {
          apiResponse.data.push(noProject);
          setResults(apiResponse.data);
        } else {
          setResults([]);
        }

        setSearching(false);
      });

      return;
    }
    
    let location = await Location.getCurrentPositionAsync({accuracy: Location.Accuracy.Balanced,});
    const {latitude, longitude} = location.coords;

    ProjectApi.listUsingGPS(latitude, longitude)
    .then((apiResponse: any) => {  
      if (apiResponse.data.length > 0) {
        apiResponse.data.push({id: 1, title: 'Project Not Listed', site: { id: 0 }});
        setResults(apiResponse.data);
      } else {
        setResults([]);
        setNoNearby(true);
      }

      setSearching(false);
    });
  }

  const checkPermission = async () => {
    let { status } = await Location.getForegroundPermissionsAsync();

    if (status !== 'granted') {
      await Location.requestForegroundPermissionsAsync();
    }

    loadResults();
  };

  useEffect(() => {
    checkPermission();
  }, []);

  return ( 
      <View>
        <View>
          <SafeSearchBar   
            platform={"default"}
            value={keyword}
            onChangeText={updateSearch}
            onCancel={clearSearch}
            placeholder={getPlaceHolder()}
            inputStyle={styles.searchBarInput}
            containerStyle={styles.searchBarContainer}
            inputContainerStyle={styles.searchBarInputContainer}
          />
        </View>
        
        {searching &&
          <View style={styles.activityContainer}>
            <ActivityIndicator size="large" color="#3F51B5" />
            <TraxxText style={styles.searchingText}>Searching...</TraxxText>
          </View>
        }
      
        {noNearby && !searching &&
          <View style={styles.activityContainer}>
            <TraxxText style={styles.nothingText}>Nothing nearby found, please search for a project.</TraxxText>
          </View>
        }
        
        {!searching && <View>
          {results.map((item: Project) => {
            return (<View key={item.id.toString()}>
                <div style={{cursor: 'pointer'}} onClick={() => itemPressed(item)}>
                  <View style={styles.carouselItem}>
                    <TraxxText style={styles.title} numberOfLines={1} ellipsizeMode='tail'>{item.title}</TraxxText>
                    {item.id == 1 && <TraxxText style={styles.subTitle} numberOfLines={1} ellipsizeMode='tail'>Select if you cannot find your project</TraxxText>}
                    {item.id != 1 && <TraxxText style={styles.subTitle} numberOfLines={1} ellipsizeMode='tail'>{item.site?.address}, {item.site?.city}</TraxxText>}
                  </View>
                </div>
              </View>)
          })}
        </View>}

        {getShowCancel() && <Button style={styles.button} onPress={() => checkOnCancel()}
            text="Cancel">
          </Button>}
      </View>)
};

/*
<Button style={styles.button} onPress={() => addNewProject()}
text="Add New Project">
</Button>*/

const width = Dimensions.get('window').width
  
const styles = StyleSheet.create({
  category: {
    fontSize: 18,
    fontStyle: 'italic',
  }, 

  activityContainer: {
    
  },

  nothingText: {
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold'
  },

  searchingText: {
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold'
  },

  header: {
    fontSize: 32,
    backgroundColor: "#fff",
    margin: 10,
  },
 
  item: {
    backgroundColor: "#f9c2ff",
    padding: 20,
    marginVertical: 8
  },

  title: {
    fontWeight: 'bold',
    marginLeft: 10,
    fontSize: 18
  },

  subTitle: {
    marginLeft: 10,
    fontSize: 16,
    marginBottom: 10,
  },

  image: {
    height: 90,
    width: 90,
    resizeMode: 'cover',
    borderRadius: 90
  },

  carouselItem: {
    margin: 10,
    borderBottomColor: Colors.textGrey,
    borderBottomWidth: 1,    
  },

  bookImage: {
    width: 120,
    height:120,
    resizeMode: 'cover',
    marginRight: 10
  },

  bookCount: {
    fontSize: 20,
    fontWeight: 'bold'
  },

  courseBookImage: {
    width: 40,
    height: 40,
    marginRight: 5,
    marginTop: 5,
    resizeMode: 'cover'
  },

  signUpContainer: {
    marginTop: 60,
    marginBottom: 250
  },

  subNavContainer: {
    height: 50,
    flexDirection: 'row'
  },
  button: {
    margin: 10,
    width: width - 20,
  },
  resultContainer: {
    width: width - 150,
  },
  searchBarInput: {
    backgroundColor: Colors.white,
    fontSize: 15,
  },
  searchBarContainer: {
    backgroundColor: Colors.white,
    borderColor: Colors.black,
    borderWidth: 1,
    borderRadius: 20,
    margin: 5,
  },
  searchBarInputContainer: {
    backgroundColor: Colors.white,
  },

  instruction: {
    marginVertical: 5,
    marginHorizontal: 15,
  },
});

export default ProjectSearch
