import axiosInstance from "../../interceptor";

const OrganizationApi = {
  load: (organizationId: number) => axiosInstance.get('organization/' + organizationId),
  loadByCode: (code: string) => axiosInstance.get('organization/code/' + code),
  listHaulers: () => axiosInstance.get('/organization/hauler/list'),
  list: (page: number) => axiosInstance.get('organization/list/all/' + page),
  listUsingGPS: (latitude: number, longitude: number) => axiosInstance.get('organization/list/' + latitude + '/' + longitude),
  search: (keyword: string) => axiosInstance.get('organization/search/' + keyword),
  searchPeople: (organizationId: number, keyword: string) => axiosInstance.get('organization/search/people/' + organizationId + '/' + keyword),
  accessCode: () => axiosInstance.get('/organization/access/code'),
  getCompanyLocation: () => axiosInstance.get('/organization/location/company'),
  getReuseLocation: () => axiosInstance.get('/organization/location/reuse'),
  loadSite: (organizationId: number) => axiosInstance.get('/organization/site/' + organizationId),
  listVehicles: (organizationId: number) => axiosInstance.get('organization/vehicles/' + organizationId),
  createHauler: () => axiosInstance.post('organization/singlehauler/')
};

export default OrganizationApi;