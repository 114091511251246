import React from 'react'

import {
  useColorScheme,
  View,
  StyleSheet,
  ActivityIndicator,
} from 'react-native';

import {useNavigation} from '@react-navigation/native';
import { Text } from 'react-native';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../services/store';
import ManagerDashboard from '../components/manager/ManagerDashboard';
import { logout } from '../services/modules/auth/actions';
import AppApi from '../services/modules/app/api';
import { loadSettings } from '../services/modules/app/actions';
import HaulerDashboard from '../components/hauler/HaulerDashboard';
import ReuseDashboard from '../components/organization/ReuseDashboard';
import CompanyDashboard from '../components/organization/CompanyDashboard';
import SubscribeForm from '../components/payment/SubscribeForm';
import { TraxxText } from '../components/ui/TraxxText';

interface Props {
}

const DashboardScreen: React.FC<Props> = ({ 
}) => {   
  const navigation = useNavigation();

  const isDarkMode = useColorScheme() === 'dark';

  const dispatch = useDispatch();
  const authState = useSelector((state: RootState) => state.auth);

  const [loading, setLoading] = React.useState(false);
  
  useEffect(() => { 
    AppApi.loadSettings()
    .then((apiResponse: any) => {
      dispatch(loadSettings(apiResponse.data[0].value))
    }) 

    setLoading(false)
  }, [authState]);

  return (<View>          
            {loading &&
              <View style={{marginTop: 40}}>
                <ActivityIndicator size="large" color="#3F51B5" />
                <TraxxText style={styles.loadingText}>Loading...</TraxxText>
              </View>
            }

            {!loading && authState.subscriptionId == 1 && authState.subscribed && <HaulerDashboard />}
            {!loading && authState.subscriptionId == 2 && authState.subscribed && authState.role == "Driver" && <HaulerDashboard />}
            {!loading && authState.subscriptionId == 2 && authState.subscribed && authState.subscriptionOwner && <CompanyDashboard />}
            {!loading && authState.subscriptionId == 3 && authState.subscribed && authState.subscriptionOwner && <ManagerDashboard />}
            {!loading && authState.subscriptionId == 4 && authState.subscribed && <ManagerDashboard />}
            {!loading && authState.role == "Reuse" && authState.subscribed && <ReuseDashboard />}
          </View>
  )
};

const styles = StyleSheet.create({ 
  loadingText: {
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold'
  },
});

export default DashboardScreen