import { FontAwesome } from '@expo/vector-icons';
import { DrawerActions, useNavigation } from '@react-navigation/native';
import React from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../services/modules/auth/actions';
import { RootState } from '../../services/store';
import Button from '../ui/Button';

const PreferencesMenu: React.FC = ({ 
}) => { 
  const authState = useSelector((state: RootState) => state.auth);

  const dispatch = useDispatch();
  const navigation = useNavigation();

  const runLogout = () => {
    dispatch(logout());
  }

  const preferences = () => {
    navigation.navigate("Preferences");
  }

  const vehicles = () => {
    navigation.navigate("Vehicles");
  }

  const company = () => {
    navigation.navigate("CompanyDashboard");
  }
  
  return (<View style={styles.drawerButtonContainer}>   
            {authState.subscriptionOwner && authState.subscriptionId == 3 && authState.role == "Company" && <Button onPress={company} text="Company Dashboard" style={{marginRight: 10}} />}
            {authState.subscriptionOwner && authState.role == "Company" && <Button onPress={vehicles} text="Trucks" style={{marginRight: 10}} />}
            {authState.subscriptionOwner && authState.role == "Manager" && <Button onPress={vehicles} text="Trucks" style={{marginRight: 10}} />}
            {authState.subscriptionOwner && authState.role == "Driver" && <Button onPress={vehicles} text="Trucks" style={{marginRight: 10}} />}
            <Button onPress={preferences} text="Account" style={{marginRight: 10}} />
            <Button onPress={runLogout} text="Log Out" />
          </View>);
}

/*
 <TouchableOpacity onPress={openDrawer}>
              <FontAwesome name="bars" color="#000000" size={30}/>
            </TouchableOpacity>
            */
           
const styles = StyleSheet.create({
  drawerButtonContainer: {
    marginRight: 10,
    flexDirection: 'row'
  },
});

export default PreferencesMenu;