import React, { useEffect } from 'react';
import { StyleSheet, View, Dimensions, ActivityIndicator } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { autoLogin, loginSuccess, loginFailure, authenticationError, updateOrganization, updateFirstTime, updateFirstTimeTrucks } from '../../services/modules/auth/actions';
import { RootState } from '../../services/store';
import Button from '../ui/Button';
import AsyncStorage from '@react-native-async-storage/async-storage';
import AuthApi from '../../services/modules/auth/api';
import Colors from '../../constants/Colors';
import { useNavigation } from '@react-navigation/native';
import { TraxxTextInput } from '../ui/TraxxTextInput';
import { TraxxText } from '../ui/TraxxText';
import { selectSubscription, setCode, setInviteId } from '../../services/modules/payment/actions';
import AccountApi from '../../services/modules/account/api';

interface Props {
  onComplete?: () => void;
}

const LoginForm: React.FC<Props> = ({ 
  onComplete
}) => { 
  const dispatch = useDispatch();
  const navigation = useNavigation();

  const authenticationState = useSelector((state: RootState) => state.auth);
  const paymentState = useSelector((state: RootState) => state.payment)

  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const authenticate = () => {
    if (email.trim().length == 0 || password.trim().length == 0) {
      dispatch(authenticationError("Please enter a username and password"));
      return;
    }

    setLoading(true);
    AuthApi.login(email, password)
    .then(async (apiResponse: any) => {
      await storeAutoLogin(apiResponse.data);

      if (onComplete)
        onComplete();

      setLoading(false);
      
      dispatch(loginSuccess(apiResponse.data));

      if (paymentState.code.length > 0) {
        AccountApi.updateOrganizationCode(paymentState.code)
        .then(async (apiResponse: any) => {
          await AsyncStorage.setItem(
            'user.organizationId',
            apiResponse.data.id
          ); 
    
          await AsyncStorage.setItem(
            'user.organization',
            apiResponse.data.title
          ); 
          
          await AsyncStorage.setItem(
            'user.subscribed',
            "true"
          ); 
          
          await AsyncStorage.setItem(
            'user.subscriptionId',
            "1"
          ); 
    
          dispatch(updateOrganization(apiResponse.data.id, apiResponse.data.title, true, 1))  
        })
      }
    })
    .catch((e) => {
      dispatch(loginFailure());
      setLoading(false);
    });
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('c');
    const inviteId = urlParams.get('i');

    if (code != null) {
      dispatch(setCode(code))
    } else if (inviteId != null) {
      dispatch(setInviteId(parseInt(inviteId)))
    } else {
      dispatch(setCode(""))
    }
  }, []);

  const attemptAutoLogin = (async () => {    
    try {
      const id = await AsyncStorage.getItem('user.id');
      const firstName = await AsyncStorage.getItem('user.firstName');
      const lastName = await AsyncStorage.getItem('user.lastName');
      const role = await AsyncStorage.getItem('user.role');
      const token = await AsyncStorage.getItem('user.token');
      const refreshToken = await AsyncStorage.getItem('user.refreshToken');
      var vehicleId = await AsyncStorage.getItem('user.vehicleId');
      var organizationId = await AsyncStorage.getItem('user.organizationId');
      var organization = await AsyncStorage.getItem('user.organization');
      const metric = await AsyncStorage.getItem('user.metric');
      var subscribed = await AsyncStorage.getItem('user.subscribed');
      var subscriptionOwner = await AsyncStorage.getItem('user.subscriptionOwner');
      var subscriptionId = await AsyncStorage.getItem('user.subscriptionId');

      if (vehicleId == null) {
        vehicleId = "";
      }

      if (organizationId == null) {
        organizationId = "0";
      }
      
      if (organization == null) {
        organization = "";
      }
      
      if (subscribed == null) {
        subscribed = "false";
      }
      
      if (subscriptionOwner == null) {
        subscriptionOwner = "false";
      }
      
      if (subscriptionId == null) {
        subscriptionId = "0";
      }

      if (id != null && firstName != null && lastName != null && token != null && refreshToken != null && vehicleId != null && role != null && metric != null && organizationId != null && organization != null) {
        dispatch(autoLogin(id, firstName, lastName, token, refreshToken, vehicleId, role, parseInt(metric), parseInt(organizationId), organization, subscribed == "true", subscriptionOwner == "true", parseInt(subscriptionId)));
        return true;
      } else {
        clearAutoLogin();
        return false;
      }
    } catch(e) {
      clearAutoLogin();
      return false;
    }
  });

  const clearAutoLogin = (async() => {
    try {
      await AsyncStorage.removeItem('user.id');
      await AsyncStorage.removeItem('user.firstName');
      await AsyncStorage.removeItem('user.lastName');
      await AsyncStorage.removeItem('user.role');
      await AsyncStorage.removeItem('user.token');
      await AsyncStorage.removeItem('user.refreshToken');      
      await AsyncStorage.removeItem('user.vehicleId');      
      await AsyncStorage.removeItem('user.metric');    
      await AsyncStorage.removeItem('user.organizationId');      
      await AsyncStorage.removeItem('user.organization');       
      await AsyncStorage.removeItem('user.subscribed');      
      await AsyncStorage.removeItem('user.subscriptionOwner');  
      await AsyncStorage.removeItem('user.subscriptionId');        
      return true;
    } catch(error) {
      return false;
    }
  });

  const storeAutoLogin = (async (userData: any) => {
    try {
      await AsyncStorage.setItem(
        'user.id',
        userData.id
      );
      await AsyncStorage.setItem(
        'user.firstName',
        userData.firstName
      );
      await AsyncStorage.setItem(
        'user.lastName',
        userData.lastName
      );
      await AsyncStorage.setItem(
        'user.role',
        userData.role
      );
      await AsyncStorage.setItem(
        'user.token',
        userData.token
      );
      await AsyncStorage.setItem(
        'user.refreshToken',
        userData.refreshToken
      );  

      if (userData.profilePhoto == null) {
        await AsyncStorage.setItem(
          'user.profilePhoto',
          ""
        );  
      } else {
        await AsyncStorage.setItem(
          'user.profilePhoto',
          userData.profilePhoto
        );  
      }

      if (userData.vehicleId == null) {
        await AsyncStorage.setItem(
          'user.vehicleId',
          ""
        ); 
      } else {
        await AsyncStorage.setItem(
          'user.vehicleId',
          userData.vehicleId
        ); 
      } 

      const metric = parseInt(userData.metric).toString();

      await AsyncStorage.setItem(
        'user.metric',
        metric
      ); 

      await AsyncStorage.setItem(
        'user.organizationId',
        userData.organizationId
      ); 

      if (userData.organization == null) {
        await AsyncStorage.setItem(
          'user.organization',
          ""
        ); 
      } else {
        await AsyncStorage.setItem(
          'user.organization',
          userData.organization
        ); 
      } 

      await AsyncStorage.setItem(
        'user.subscribed',
        userData.subscribed.toString()
      );

      await AsyncStorage.setItem(
        'user.subscriptionOwner',
        userData.subscriptionOwner.toString()
      );

      await AsyncStorage.setItem(
        'user.subscriptionId',
        userData.subscriptionId.toString()
      ); 
      
      return true;
    } catch(error) {
      console.log(error)
      return false;
    }
  });
  
  const forgotPassword = () => {
    navigation.navigate('ForgotPassword');
  }

  const signUp = () => {
    navigation.navigate('SignUp');
  }
  
  return (
    <View>
      {loading &&
        <View style={{marginTop: 40}}>
          <ActivityIndicator size="large" color="#3F51B5" />
          <TraxxText style={styles.loadingText}>Authenticating, please wait...</TraxxText>
        </View>
      }
      {!loading && 
        <View style={styles.inputContainer}>
          <View style={styles.inputView}>
            <TraxxTextInput
              style={styles.input}
              placeholder="Enter your email"
              placeholderTextColor="#003f5c"
              value={email}
              onChangeText={(email: string) => setEmail(email)}
            />
          </View>
    
          <View style={styles.inputView}>
            <TraxxTextInput
              style={styles.input}
              placeholder="Enter your password"
              placeholderTextColor="#003f5c"
              secureTextEntry={true}
              onChangeText={(password: string) => setPassword(password)}
            />
          </View>

          {authenticationState.error.length > 0 &&
              <TraxxText style={styles.errorMessage}>{authenticationState.error}</TraxxText>
          }

          <Button onPress={authenticate}
            style={styles.button}
            text="Login">
          </Button>
        
          <Button 
            style={[styles.button, {marginTop: 20}]}
            onPress={forgotPassword}
            text="Forgot Password">
          </Button>

          <Button 
            style={[styles.button, {marginTop: 40}]}
            onPress={signUp}
            text="I'm New">
          </Button>
        </View>}
    </View>
  )
};

const width = Dimensions.get('window').width

const styles = StyleSheet.create({
  inputContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },

  inputView: {
    backgroundColor: "#fff",
    borderRadius: 30,
    height: 45,
    width: 300,
    marginBottom: 20,
    alignItems: "center",
  },

  input: {
    height: 50,
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderColor: Colors.borderGrey,
    borderWidth: 1,
    width: 300,
    borderRadius: 20
  },

  errorMessage: {
    borderRadius: 10,
    color: "#FF0000",
    marginBottom: 20,
    backgroundColor: "#ffffff",
    paddingTop: 10,
    paddingBottom: 10,
    fontWeight: 'bold',
    textAlign: 'center',
    width: width / 1.4,
  },

  button: {
    margin: 10,
    width: 300,
  },
  
  loadingText: {
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold'
  },
});

export default LoginForm