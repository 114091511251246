import React, { useEffect } from 'react'

import {
  View,
  StyleSheet,
  Modal,
  Alert,
  Share,
  ActivityIndicator,
} from 'react-native';

import { useFocusEffect, useIsFocused, useNavigation } from '@react-navigation/native';
import { Text } from 'react-native';
import { FlatList, TextInput, TouchableOpacity } from 'react-native-gesture-handler';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../services/store';
import { Account } from '../../services/types';
import { AxiosResponse } from 'axios';
import Button from '../ui/Button';
import Colors from '../../constants/Colors';
import OrganizationApi from '../../services/modules/organization/api';
import AccountApi from '../../services/modules/account/api';
import { selectAccount } from '../../services/modules/account/actions';
import { TraxxText } from '../ui/TraxxText';
import { TraxxTextInput } from '../ui/TraxxTextInput';
import { portalURL } from '../../services/global';

interface Props {
}

const CompanyPanel: React.FC<Props> = ({ 
}) => {   
  const authState = useSelector((state: RootState) => state.auth);

  const dispatch = useDispatch();

  const isFocused = useIsFocused();
  const navigation = useNavigation();
  
  const [users, setUsers] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [showAddUser, setShowAddUser] = React.useState(false);
  const [sending, setSending] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [accessCode, setAccessCode] = React.useState("");

  const showAccount = (account: Account) => {
    dispatch(selectAccount(account));
    navigation.navigate("HaulerInfo");
  }

  const sendInvite = () => {
    if (email.trim().length == 0) {
      alert("Please enter an email address.");
      return;      
    }

    setSending(true);

    OrganizationApi.load(authState.organizationId)
    .then((apiResponse: AxiosResponse<any>) => {
      let content = authState.organization + " has invited you to join Traxx. <a href='" + portalURL + "?c=" + apiResponse.data.code + "'>click here to access " + authState.organization + " on Traxx</a> or use the code " + accessCode + " when signing up.";

      AccountApi.sendInviteContent(email, content)
      .then(() => {
        setSending(false);
        setShowAddUser(false);
        setEmail("");
        alert("Your invitation has been sent to " + email);
      });
    });
  }

  const init = () => {
    setLoading(true);

    if (authState.id) {      
      OrganizationApi.accessCode()
      .then((apiResponse: AxiosResponse) => {
        setAccessCode(apiResponse.data);
      });

      OrganizationApi.listHaulers()
      .then((apiResponse: AxiosResponse) => {
        setUsers(apiResponse.data);
        setLoading(false);
      });
    }
  }

  useFocusEffect (
    React.useCallback(() => {      
      if (isFocused) {
        init();
      }
    }, [isFocused])
  );

  useEffect(() => {
    if (!loading) {
      init();
    }
  }, [authState]);

  const drawCard = (({item} : {item: Account}) => {
    return (
    <TouchableOpacity onPress={() => showAccount(item)}>
      <View style={styles.card}>
        <View style={styles.cardInfoContainer}>
          <TraxxText style={styles.cardTitle}>{item.firstName} {item.lastName}</TraxxText>
          <TraxxText style={styles.cardSubTitle}>{item.vehicleId}</TraxxText>
        </View>
      </View>
    </TouchableOpacity>)
  })

  return (<View style={styles.componentContainer}>      
            {showAddUser && <Modal
              animationType="slide"
              style={styles.modalView}
              transparent={false}
              visible={showAddUser}
              onRequestClose={() => {
                setShowAddUser(!showAddUser);
              }}
            >  
              {sending && <View>
                <ActivityIndicator size="large" color="#3F51B5" />
                <TraxxText style={styles.searchingText}>Sending invite...</TraxxText>
              </View>}
              {!sending && <View style={styles.modalView}>
                <TraxxText style={styles.modalText}>Send Invitation</TraxxText>        
                <TraxxText style={[styles.title, {marginTop: 10}]}>Enter an email address to send the invitation to.</TraxxText>
                <View style={styles.inputView}>
                  <TraxxText style={styles.inputLabel}>Email</TraxxText>
                  <View style={styles.inputGroup}>
                    <TraxxTextInput
                      style={styles.input}
                      placeholder="Email address to invite"
                      placeholderTextColor="#003f5c"
                      value={email}
                      onChangeText={(email: string) => {
                        setEmail(email);
                      }}
                    />
                  </View>
                </View>   
                <Button style={[styles.entryButton, {marginTop:20}]} onPress={() => sendInvite()}
                  text="Send">
                </Button>
                <Button style={[styles.entryButton, {marginTop: 20}]} onPress={() => {setShowAddUser(false)}}
                  text="Cancel">
                </Button>
              </View>}
            </Modal>}   
            {!showAddUser && <View style={styles.sectionContainer}>
              <View style={styles.sectionHeaderView}>
                <TraxxText style={styles.sectionHeader}>COMPANY ACCESS</TraxxText>
              </View>
              <TraxxText style={styles.header}>CODE: {accessCode}</TraxxText>
              <Button onPress={() => {setEmail(""); setShowAddUser(true);}}
                style={styles.button}
                text="Invite">
              </Button>
              <View style={styles.subSectionHeaderView}>
                <TraxxText style={styles.sectionHeader}>HAULERS</TraxxText>
              </View>
              {users.length > 0 && <FlatList
                keyExtractor={(item: Account, index) => item.id.toString() + index}
                data={users}
                renderItem={drawCard}
              />}
              {users.length == 0 && <TraxxText>No haulers in your company</TraxxText>}
            </View>}
          </View>
  )
};

const styles = StyleSheet.create({
  inputContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },

  componentContainer: {   
    backgroundColor: Colors.white, 
    padding: "1%",
    borderColor: Colors.borderGrey,
    borderWidth: 1,
    borderRadius: 10,
    width: "100%"
  },

  title: {
    fontSize: 20,
    textAlign: 'center',
    marginHorizontal: 10,
  },

  inputView: {
    backgroundColor: "#fff",
    borderRadius: 30,
    marginBottom: 10,
  },

  input: {
    borderRadius: 5,
    borderWidth: 1,
    borderColor: Colors.borderGrey,
    fontSize: 16,
    padding: 10,
    flex: 1,
  },

  inputGroup: {
    flexDirection: 'row',
    marginLeft: 5,
  },

  header: {
    marginTop: 10,
    fontSize: 16,
    fontWeight: 'bold',
  },

  inputBox: {
    borderRadius: 5,
    borderWidth: 1,
    borderColor: Colors.borderGrey,    
    fontSize: 16,
    padding: 10,
  },

  inputText: {
    fontSize: 16,
    fontWeight: 'bold',
  },

  inputLabel: {
    fontSize: 16,
    marginTop: 10,
    marginLeft: 5,
    marginRight: 10,
  },

  searchingText: {
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold'
  },

  comingSoon: {
    fontSize: 20,
    color: "#fff"
  },

  button : {
    marginTop: 10
  },
  buttonOn: {
    backgroundColor: Colors.primary,
    margin: 4,
  },
  buttonOff: {
    backgroundColor: Colors.primaryBrown,
    margin: 4,
  },
  sectionContainer: {
    width: "100%",
    padding: 10,
  },
  sectionHeader: {
    fontWeight: 'bold',
    fontSize: 20,
    flex: 1,
  },
  sectionHeaderView: {
    flexDirection: 'row',
  },
  subSectionHeaderView: {
    flexDirection: 'row',
    marginTop: 20,
  },
  sectionHeaderButton : {
    margin: 4,
  },
  card: {
    borderColor: Colors.borderDarkGrey,
    borderRadius: 5,
    borderWidth: 1,
    padding: 10,
    marginTop: 10,
  }, 

  cardInfoContainer: {
  },

  cardTitle: {
    color: Colors.text,
    fontSize: 20,
    fontWeight: 'bold',
  },

  cardSubTitle: {
    color: Colors.text,
    fontSize: 14,
  },

  modalView: {
    width: "98%",
    margin: "1%",
    backgroundColor: Colors.white,
    borderColor: Colors.white
  },

  modalText: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 20,
    marginHorizontal: 20,
  },

  modalSubText: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 18,
    margin: 20,
  },

  entryButton: {
    marginLeft: 10,
    marginTop: 10,
  },
  stat: {
    fontSize: 26,
    fontWeight: 'bold',
  },

  statTitle: {
    fontSize: 20,
  },

  statsContainer: {
    alignItems: 'center',
    marginHorizontal: 5,
    marginVertical: 20,
  },

  container: {
    flexDirection: 'row'
  },

  mainContainer: {
    paddingLeft: "1%",
    paddingTop: "1%",
    paddingRight: "1%",
    width: "68%",
  },

  sideContainer: {
    paddingTop: "1%",
    paddingRight: "1%",
    width: "29%",
  },

  dropDown: {
    borderRadius: 5,
    borderWidth: 1,
    borderColor: Colors.borderGrey,    
    fontSize: 16,
    padding: 10,
    width: "100%"
  }
});
export default CompanyPanel