import React, { useEffect } from 'react'

import {
  View,
  StyleSheet,
  Dimensions,
  ActivityIndicator,
  Modal,
  Platform,
} from 'react-native';

import { Text } from 'react-native';
import { FlatList, TextInput, TouchableOpacity } from 'react-native-gesture-handler';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../services/store';
import { AxiosResponse } from 'axios';
import { Project, ProjectDocument, Site } from '../../services/types';
import ProjectApi from '../../services/modules/project/api';
import * as DocumentPicker from 'expo-document-picker';
import { baseURL } from '../../services/global';
import Button from '../ui/Button';
import PlacesSearchBar from '../location/PlacesSearchBar';
import Colors from '../../constants/Colors';
import { initialState } from '../../services/modules/app/reducer';
import { TraxxText } from '../ui/TraxxText';
import { TraxxTextInput } from '../ui/TraxxTextInput';

interface Props {
}

const ProjectDetails: React.FC<Props> = ({ 
}) => {   
  const dispatch = useDispatch();

  const projectState = useSelector((state: RootState) => state.project);

  const [showEditor, setShowEditor] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [saving, setSaving] = React.useState(false);

  const [address, setAddress] = React.useState("");
  const [postalCode, setPostalCode] = React.useState("");
  const [province, setProvince] = React.useState("");
  const [city, setCity] = React.useState("");

  const [projectTitle, setProjectTitle] = React.useState("");

  const saveProject = () => {
    if (projectTitle.trim().length == 0 || address.trim().length == 0 || city.trim().length == 0 || province.trim().length == 0 || postalCode.trim().length == 0) {
      alert("Please enter a project title and address.");
      return;
    }

    setSaving(true)
    ProjectApi.update(projectState.id!, projectTitle, address, city, province, postalCode)
    .then((apiResponse: any) => {
      setShowEditor(false)
      setSaving(false)
      init();
    })
  }

  const init = () => {  
    setLoading(true);

    ProjectApi.load(projectState.id!)
    .then((apiResponse: AxiosResponse<any>) => {   
      const project = apiResponse.data;

      setProjectTitle(project.title);
      setAddress(project.site.address);
      setCity(project.site.city);
      setProvince(project.site.province);
      setPostalCode(project.site.postalCode);
      
      setLoading(false)
    })
  }

  useEffect(() => {
    init();
  }, [projectState])

  return (<View style={styles.componentContainer}>     
            {showEditor && <Modal
              animationType="slide"
              transparent={false}
              style={styles.modalView}
              visible={showEditor}
              onRequestClose={() => {
                setShowEditor(!showEditor);
              }}>
              {!saving && <View style={styles.modalView}>
                <TraxxText style={styles.modalText}>Project Information</TraxxText>
                <TraxxText style={styles.inputLabel}>Title</TraxxText>
                <View style={styles.inputGroup}>
                  <TraxxTextInput
                    style={styles.input}
                    placeholder="Title"
                    placeholderTextColor="#003f5c"
                    value={projectTitle}
                    onChangeText={(projectTitle: string) => {
                      setProjectTitle(projectTitle);
                    }}
                  />
                </View>
                <TraxxText style={styles.inputLabel}>Street</TraxxText>
                <View style={styles.inputGroup}>
                  <TraxxTextInput
                    style={styles.input}
                    placeholder="Address"
                    placeholderTextColor="#003f5c"
                    value={address}
                    onChangeText={(newAddress: string) => {
                      setAddress(newAddress);
                    }}
                  />
                </View>  
                <TraxxText style={styles.inputLabel}>City</TraxxText>
                <View style={styles.inputGroup}>
                  <TraxxTextInput
                    style={styles.input}
                    placeholder="City"
                    placeholderTextColor="#003f5c"
                    value={city}
                    onChangeText={(newCity: string) => {
                      setCity(newCity);
                    }}
                  />
                </View>
                <TraxxText style={styles.inputLabel}>Province</TraxxText>
                <View style={styles.inputGroup}>
                  <TraxxTextInput
                    style={styles.input}
                    placeholder="Province"
                    placeholderTextColor="#003f5c"
                    value={province}
                    onChangeText={(newProvince: string) => {
                      setProvince(newProvince);
                    }}
                  />
                </View>
                <TraxxText style={styles.inputLabel}>Postal Code</TraxxText>
                <View style={styles.inputGroup}>
                  <TraxxTextInput
                    style={styles.input}
                    placeholder="Postal code"
                    placeholderTextColor="#003f5c"
                    value={postalCode}
                    onChangeText={(newPostalCode: string) => {
                      setPostalCode(newPostalCode);
                    }}
                  />
                </View>
                <Button style={styles.entryButton} onPress={() => saveProject()}
                  text="Save">
                </Button>
                <Button style={styles.entryButton} onPress={() => setShowEditor(!showEditor)}
                  text="Cancel">
                </Button>
              </View>}
            </Modal>}

            {saving &&
              <View>
                <ActivityIndicator size="large" color="#3F51B5" />
                <TraxxText style={styles.searchingText}>Saving...</TraxxText>
              </View>
            }

            {loading &&
              <View>
                <ActivityIndicator size="large" color="#3F51B5" />
                <TraxxText style={styles.searchingText}>Loading...</TraxxText>
              </View>
            }

            {!loading && !saving && !showEditor && <View>
                <View style={styles.inputContainer}>
                  <View style={styles.sectionContainer}>
                    <View style={styles.sectionHeaderView}>
                      <TraxxText style={styles.sectionHeader}>{projectTitle}</TraxxText>
                    </View>    
                    
                    <TraxxText style={[styles.subHeader, {marginTop: 10}]}>Address</TraxxText>
                    <TraxxText style={styles.subText}>{address}</TraxxText>
                    <TraxxText style={styles.subText}>{city}, {province} {postalCode}</TraxxText>
                  </View>
                </View>
                {projectState.accessLevel == 1 && <Button onPress={() => { setShowEditor(true); }}
                  style={styles.button}
                  text="Edit">
                </Button>}
              </View>}
          </View>
  )
};

const styles = StyleSheet.create({
  inputContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },

  componentContainer: {   
    backgroundColor: Colors.white, 
    padding: "1%",
    borderColor: Colors.borderGrey,
    borderWidth: 1,
    borderRadius: 10,
    width: "100%"
  },

  inputView: {
    backgroundColor: "#fff",
    borderRadius: 30,
    width: "70%",
    height: 45,
    marginBottom: 20,
    alignItems: "center",
  },

  inputGroup: {
    flexDirection: 'row',
    marginLeft: 5,
  },
  input: {
    height: 50,
    width: "100%",
    paddingVertical: 10,
    paddingHorizontal: 20,
    margin: 10,
    flex: 1,
    borderColor: Colors.borderGrey,
    borderWidth: 1,
    borderRadius: 20
  },

  inputBox: {
    borderRadius: 5,
    borderWidth: 1,
    borderColor: Colors.borderGrey,    
    fontSize: 16,
    padding: 10,
  },

  inputText: {
    fontSize: 16,
    fontWeight: 'bold',
  },

  inputLabel: {
    fontSize: 16,
    marginTop: 10,
    marginLeft: 5,
  },

  searchingText: {
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold'
  },

  comingSoon: {
    fontSize: 20,
    color: "#fff"
  },

  button : {
    marginTop: 10,
  },
  buttonOn: {
    backgroundColor: Colors.primary,
    margin: 4,
  },
  buttonOff: {
    backgroundColor: Colors.primaryBrown,
    margin: 4,
  },
  sectionContainer: {
    width: "100%",
  },
  sectionHeader: {
    fontWeight: 'bold',
    fontSize: 20,
    flex: 1,
  },
  subHeader: {
    fontWeight: 'bold',
    fontSize: 18,
    flex: 1,
  },
  subText: {
    fontSize: 16
  },
  sectionHeaderView: {
    flexDirection: 'row',
  },
  sectionHeaderButton : {
    margin: 4,
  },
  modalView: {
    width: "98%",
    margin: "1%",
    backgroundColor: Colors.white,
    borderColor: Colors.white
  },
  card: {
    borderColor: Colors.borderDarkGrey,
    borderRadius: 5,
    borderWidth: 1,
    padding: 10,
  }, 

  cardInfoContainer: {
    flexDirection: 'row'
  },

  cardTitle: {
    color: Colors.text,
    fontSize: 20,
    fontWeight: 'bold',
  },
  modalText: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 20,
    margin: 20,
  },
  entryButton: {
    marginLeft: 10,
    marginTop: 10,
  },
});
export default ProjectDetails