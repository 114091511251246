import React, { useEffect } from 'react'

import {
  View,
  StyleSheet,
  Dimensions,
  Modal,
  Alert,
  Share,
} from 'react-native';

import {useNavigation, useFocusEffect, useIsFocused} from '@react-navigation/native';
import { Text } from 'react-native';
import { FlatList, ScrollView, TextInput, TouchableOpacity } from 'react-native-gesture-handler';
import { useSelector } from 'react-redux';
import { RootState } from '../services/store';
import { Account } from '../services/types';
import ProjectApi from '../services/modules/project/api';
import { AxiosResponse } from 'axios';
import Button from '../components/ui/Button';
import QRCode from 'react-qr-code';
import { Picker } from '@react-native-picker/picker';
import ProjectDetails from '../components/project/ProjectDetails';
import UserAccessPanel from '../components/project/UserAccessPanel';
import ProjectReport from '../components/reports/Project';
import StatusEditor from '../components/project/StatusEditor';
import Documents from '../components/project/Documents';
import Colors from '../constants/Colors';
import ManagerTrackingPanel from '../components/manager/ManagerTrackingPanel';
import ProjectContact from '../components/project/ProjectContact';
import ProjectStats from '../components/project/ProjectStats';

interface Props {
}

const ProjectScreen: React.FC<Props> = ({ 
}) => {   
  const navigation = useNavigation();

  const projectState = useSelector((state: RootState) => state.project);

  const isFocused = useIsFocused()

  const [users, setUsers] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const [entryView, setEntryView] = React.useState(0);
  const [showUser, setShowUser] = React.useState(false);
  const [showAddUser, setShowAddUser] = React.useState(false);
  const [showQRCode, setShowQRCode] = React.useState(false);
  const [selectedUserName, setSelectedUserName] = React.useState("");
  const [selectedUserId, setSelectedUserId] = React.useState("");
  const [newUserEmail, setNewUserEmail] = React.useState("");
  const [newUserRole, setNewUserRole] = React.useState("");
  const [totalCompleted, setTotalCompleted] = React.useState(0);
  const [totalInProgress, setTotalInProgress] = React.useState(0);
  const [statusEditing, setStatusEditing] = React.useState(false)

  var svg: any;

  const close = (() => {
    navigation.navigate("Dashboard");
  });

  const updateEntryView = (selectedIndex: number) => {
    setEntryView(selectedIndex);
  }

  const reports = () => {
    //navigation.navigate("ProjectReports");
  }

  const statusEditorSaved = ()  => {
    setStatusEditing(false)
  }

  const statusEditorEditing = ()  => {
    setStatusEditing(true)
  }

  return (<View style={styles.container}>        
            <View style={styles.mainContainer}>
              <ProjectDetails />
              <View style={{marginTop: 10, marginBottom: 10}}>
                <ProjectContact />
              </View>
              <View style={{marginTop: 10, marginBottom: 10}}>
                <Documents />
              </View>
            </View>
            <View style={styles.sideContainer}>
              {!statusEditing && <ProjectStats/>}
              <View style={{marginTop: 10, marginBottom: 10}}>
                <StatusEditor onSaved={statusEditorSaved} onEditing={statusEditorEditing} />
              </View>
              <View style={{marginTop: 10, marginBottom: 10}}>
                <ManagerTrackingPanel />
              </View>
              <View style={{marginTop: 10, marginBottom: 10}}>
                <UserAccessPanel />
              </View>
            </View>
          </View>
  )
};

/*


                <Button onPress={() => {reports();}}
                  style={styles.button}
                  text="Reports">
                </Button>
                */

const styles = StyleSheet.create({
  inputContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },

  inputView: {
    backgroundColor: "#fff",
    borderRadius: 30,
    height: 45,
    marginBottom: 20,
  },

  input: {
    borderRadius: 5,
    borderWidth: 1,
    borderColor: Colors.borderGrey,
    fontSize: 16,
    paddingLeft: 10,
    fontWeight: 'bold',
    flex: 1,
  },

  inputGroup: {
    flexDirection: 'row',
    marginLeft: 5,
  },

  header: {
    marginTop: 10,
    fontSize: 16,
    fontWeight: 'bold',
  },

  inputBox: {
    borderRadius: 5,
    borderWidth: 1,
    borderColor: Colors.borderGrey,    
    fontSize: 16,
    padding: 10,
  },

  inputText: {
    fontSize: 16,
    fontWeight: 'bold',
  },

  inputLabel: {
    fontSize: 16,
    marginTop: 10,
    marginLeft: 5,
    marginRight: 10,
  },

  searchingText: {
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold'
  },

  comingSoon: {
    fontSize: 20,
    color: "#fff"
  },

  button : {
    marginTop: 10
  },
  buttonOn: {
    backgroundColor: Colors.primary,
    margin: 4,
  },
  buttonOff: {
    backgroundColor: Colors.primaryBrown,
    margin: 4,
  },
  sectionContainer: {
    width: "100%"
  },
  sectionHeader: {
    fontWeight: 'bold',
    fontSize: 20,
    flex: 1,
  },
  sectionHeaderView: {
    flexDirection: 'row',
  },
  sectionHeaderButton : {
    margin: 4,
  },
  card: {
    borderColor: Colors.borderDarkGrey,
    borderRadius: 5,
    borderWidth: 1,
    padding: 10,
    marginTop: 10,
  }, 

  cardInfoContainer: {
  },

  cardTitle: {
    color: Colors.text,
    fontSize: 20,
    fontWeight: 'bold',
  },

  cardSubTitle: {
    color: Colors.text,
    fontSize: 14,
  },

  modalView: {

  },

  modalText: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 20,
    margin: 20,
  },

  modalSubText: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 18,
    margin: 20,
  },

  entryButton: {
    marginLeft: 10,
    marginTop: 10,
  },
  stat: {
    fontSize: 26,
    fontWeight: 'bold',
  },

  statTitle: {
    fontSize: 20,
  },

  statsContainer: {
    alignItems: 'center',
    marginHorizontal: 5,
    marginVertical: 20,
  },

  container: {
    flexDirection: 'row'
  },

  mainContainer: {
    margin: "2%",
    width: "66%",
  },

  sideContainer: {
    marginTop: "2%",
    marginRight: "2%",
    width: "28%",
  },
});
export default ProjectScreen