import React, { useEffect, useRef } from 'react'

import {
  StyleSheet,
  View,
  Image,
  ScrollView,
  useColorScheme,
  Dimensions,
  Platform,
} from 'react-native';

import {useNavigation} from '@react-navigation/native';
import LoginForm from '../components/authentication/LoginForm';
import { useDispatch } from 'react-redux';
import { selectSubscription } from '../services/modules/payment/actions';

interface Props {
}

const LoginScreen: React.FC<Props> = ({ 
}) => { 
  const navigation = useNavigation();
  const dispatch = useDispatch();

  useEffect(() => {    
    const urlParams = new URLSearchParams(window.location.search);
    const subscription = urlParams.get('s');
    const accountType = urlParams.get('t');

    if (subscription != null && accountType != null) {
      dispatch(selectSubscription(parseInt(subscription), parseInt(accountType)))
      navigation.navigate("SignUp")
    } else {
      dispatch(selectSubscription(0, 0))
    }
  }, [])

  return ( 
    <ScrollView
      contentInsetAdjustmentBehavior="automatic"
      keyboardShouldPersistTaps='always'>
      <View style={styles.container}>
        <View style={styles.inputContainer}>
          <Image style={styles.image} source={require('../assets/images/logo.png')} />
          <LoginForm />
        </View>
      </View>
    </ScrollView>
  )
};

/*
              <View style={styles.signUpContainer}>
                <Button
                  style={styles.button}
                  onPress={signUp}
                  text="I'm New">
                </Button>
              </View>
              */

const height = Dimensions.get('window').height;
const width = Dimensions.get('window').width;

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    flex: 1,
    ...Platform.select({
      ios: {
        width: "100%"
      },
      android: {
        width: "100%"
      },
      web: {
        width: "80%"
      }
    })
  },

  inputContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 80,
  },
 
  image: {
    flex: 1,
    width: 200,
    height: 38,
    resizeMode: 'contain',
    marginBottom: 40,
  },

  signUpContainer: {
    marginTop: 60,
    marginBottom: 250
  },

  button: {
    marginLeft: 5,
  }
});

export default LoginScreen