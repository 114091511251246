export const createAccount = () => {
  return {
    id: '',    
    firstName: '',
    lastName: '',
    role: '',
    email: '',
    password: '',
    token: '',
    refreshToken: '',
    photo: '',
    vehicleId: '',
    metric: 0,
  }
}

export const createHaulLogEntry = () => {
  return {
    id: 0,    
    accountId: '',
    projectId: 0,
    weight: 0,
    weightType: 0,
    statusId: 0,
    pickUpSiteId: 0,
    organizationId: 0,
    pickUpDate: new Date(),
    dropOffSiteId: 0,
    dropOffDate: new Date(),
    createdBy: '',
    dateCreated: new Date(),
    vehicleId: '',
    project: createProject(),
    pickUpSite: createSite(),
    dropOffSite: createSite(),
    account: createAccount(),
    organization: createOrganization(),
    longitude: 0,
    latitude: 0,
    dropOffConfirmedName: "",
    dropOffConfirmedDate: new Date()
  }
}

export const createOrganization = () => {
  return {
    id: 0,    
    title: '',
    address: '',
    addressOptional: '',
    city: '',
    province: '',
    postalCode: '',
    contactName: '',
    contactEmail: '',
    contactPhone: '',
  }
}

export const createSite = () => {
  return {
    id: 0,    
    title: '',
    address: '',
    addressOptional: '',
    city: '',
    province: '',
    postalCode: '',
    contactName: '',
    contactEmail: '',
    contactPhone: '',
    projectId: 0,
    projects: []
  }
}

export const createProject = () => {
  return {
    id: 0,    
    title: ''
  }
}

const padTo2Digits = (num: number) => {
  return num.toString().padStart(2, '0');
}

export const formatDate = (date: Date) => {
  return (
    [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join('-') +
    ' ' +
    [
      padTo2Digits(date.getHours()),
      padTo2Digits(date.getMinutes()),
      // padTo2Digits(date.getSeconds()),  
    ].join(':')
  );
}

export const getAPIDate = (date: string) => {
  if (date.indexOf(".") > -1) {
    return new Date(date + "Z");
  }

  return new Date(date + ".000Z");
}