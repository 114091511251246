import axiosInstance from "../../interceptor";

const AccountApi = {
  load: () => axiosInstance.get('/account'),
  register: (email: string, password: string, firstName: string, lastName: string, role: string, recaptchaToken: string, organizationId: number, inviteId: number) => axiosInstance.post('/auth/signup', {userName: email, firstName: firstName, lastName: lastName, password: password, role: role, recaptchaToken: recaptchaToken, organizationId: organizationId, inviteId: inviteId}),
  registerCompany: (email: string, password: string, firstName: string, lastName: string, role: string, recaptchaToken: string, companyName: string, address: string, city: string, province: string, postalCode: string, inviteId: number) => axiosInstance.post('/auth/signupcompany', {userName: email, firstName: firstName, lastName: lastName, password: password, role: role, recaptchaToken: recaptchaToken, title: companyName, address: address, city: city, province: province, postalCode: postalCode, inviteId: inviteId}),
  registerReuse: (email: string, password: string, firstName: string, lastName: string, role: string, recaptchaToken: string, reuseName: string, address: string, city: string, province: string, postalCode: string, contactName: string, contactEmail: string, contactPhone: string) => axiosInstance.post('/auth/signupreuse', {userName: email, firstName: firstName, lastName: lastName, password: password, role: role, recaptchaToken: recaptchaToken, title: reuseName, address: address, city: city, province: province, postalCode: postalCode, contactName: contactName, contactEmail: contactEmail, contactPhone: contactPhone}),
  updatePassword: (email: string, password: string, code: string) => axiosInstance.post('/auth/passwordreset', {userName: email, password: password, resetCode: code}),
  sendResetCode: (email: string) => axiosInstance.get('/auth/reset/' + email),
  update: (firstName: string, lastName: string) => axiosInstance.post('/account', {firstName: firstName, lastName: lastName}),
  updateFull: (firstName: string, lastName: string, email: string, organizationName: string, address: string, city: string, province: string, postalCode: string, contactName: string, contactPhone: string, contactEmail: string) => axiosInstance.post('/account/full', {firstName: firstName, lastName: lastName, email: email, title: organizationName, address: address, city: city, province: province, postalCode: postalCode, contactName: contactName, contactPhone: contactPhone, contactEmail: contactEmail}),
  getLocation: (accountId: string) => axiosInstance.get('/account/location/' + accountId),
  sendInvite: (email: string) => axiosInstance.get('/account/invite/' + email),
  sendInviteContent: (email: string, content: string) => axiosInstance.post('/account/invite/', {email: email, content: content}),
  updateOrganizationCode: (organizationCode: string) => axiosInstance.post('/account/organization/code', {organizationCode: organizationCode}),
  subscriptionVehicles: () => axiosInstance.get('/account/subscription/vehicles'),
  subscriptionTruckCount: () => axiosInstance.get('/account/subscription/truckcount'),
  saveSubscriptionVehicle: (vehicleId: string) => axiosInstance.post('/account/subscription/vehicle', {vehicleId: vehicleId}),
};

export default AccountApi;