import { Dispatch } from 'redux';
import { HaulDispatchTypes, SELECT_ENTRY, ADD_ENTRY, STARTED_TRACKING, STOPPED_TRACKING, USE_LOCATION } from './types';

export const selectEntry = (id: number) => async (dispatch: Dispatch<HaulDispatchTypes>) => {
  dispatch({type: SELECT_ENTRY, payload: id});
}

export const addEntry = (accountId: string, organizationId: number) => async (dispatch: Dispatch<HaulDispatchTypes>) => {
  dispatch({type: ADD_ENTRY, payload: { accountId: accountId, organizationId: organizationId }});
}

export const startTracking = (watchId: any) => async (dispatch: Dispatch<HaulDispatchTypes>) => {
  dispatch({type: STARTED_TRACKING, payload: watchId});
}

export const stopTracking = () => async (dispatch: Dispatch<HaulDispatchTypes>) => {
  dispatch({type: STOPPED_TRACKING});
}

export const useLocation = (id: number, address: string, city: string, province: string, postalCode: string, longitude: number, latitude: number) => async (dispatch: Dispatch<HaulDispatchTypes>) => {
  dispatch({type: USE_LOCATION, payload: { id: id, address: address, city: city, province: province, postalCode: postalCode, longitude: longitude, latitude:latitude }});
}