import { AuthDispatchTypes, AUTHENTICATION_ERROR, AuthState, LOGIN_FAILURE, LOGIN_LOADING, LOGIN_SUCCESS, LOG_OUT, REFRESH_TOKEN, UPDATE_ACCOUNT, UPDATE_FIRSTTIME, UPDATE_FIRSTTIMETRUCKS, UPDATE_ORGANIZATION, UPDATE_PREFERENCES, UPDATE_SUBSCRIBED, UPDATE_VEHICLE } from "./types";

export const initialState: AuthState = { error: '', authenticated: false, id: '', firstName: '', lastName: '', role: '', email: '', profilePhoto: '', token: '', refreshToken: '', vehicleId: '', metric: 0, organizationId: 0, organization: '', subscribed: false, subscriptionOwner: false, firstTime: false, subscriptionId: 0, firstTimeTrucks: false };

export const authReducer = (
  state: AuthState = initialState,
  action: AuthDispatchTypes
) => {
  switch (action.type){
    case LOGIN_LOADING:
      return {
        ...state,
        loading: true,
        authenticated: false,
      }
    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        authenticated: true,
        id: action.payload.id,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        email: action.payload.email,
        profilePhoto: action.payload.profilePhoto,
        token: action.payload.token,
        refreshToken: action.payload.refreshToken,
        vehicleId: action.payload.vehicleId,
        role: action.payload.role,
        metric: action.payload.metric,
        organizationId: action.payload.organizationId,
        organization: action.payload.organization,
        subscribed: action.payload.subscribed,
        subscriptionOwner: action.payload.subscriptionOwner,
        subscriptionId: action.payload.subscriptionId
      }
    case LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        authenticated: false,
        error: 'Invalid login credentials',
      }
    case REFRESH_TOKEN:
      return {
        ...state,
        token: action.payload.token,
        refreshToken: action.payload.refreshToken,
      }
    case LOG_OUT:
      return {
        ...state,
        loading: false,
        authenticated: false,
        error: '',
        id: '', 
        firstName: '', 
        lastName: '', 
        email: '', 
        profilePhoto: '', 
        token: '', 
        refreshToken: '',
        vehicleId: '',
        metric: 0,
        organizationId: 0,
        organization: '',
        subscribed: false,
        subscriptionOwner: false,
        subscriptionId: 0
      }
    case UPDATE_ACCOUNT:
      return {
        ...state,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
      }
    case UPDATE_ORGANIZATION:
      return {
        ...state,
        organizationId: action.payload.id,
        organization: action.payload.title,
        subscribed: action.payload.subscribed,
        subscriptionId: action.payload.subscriptionId
      }  
    case UPDATE_VEHICLE:
      return {
        ...state,
        vehicleId: action.payload
      }      
    case UPDATE_PREFERENCES:
      return {
        ...state,
        metric: action.payload
      }   
    case UPDATE_FIRSTTIME:
      return {
        ...state,
        firstTime: action.payload
      }    
    case UPDATE_FIRSTTIMETRUCKS:
      return {
        ...state,
        firstTimeTrucks: action.payload
      }   
    case UPDATE_SUBSCRIBED:
      return {
        ...state,
        subscribed: action.payload.subscribed,
        subscriptionOwner: action.payload.subscriptionOwner,
        subscriptionId: action.payload.subscriptionId
      }
    case AUTHENTICATION_ERROR:
      return {
        ...state,
        loading: false,
        authenticated: false,
        error: action.payload,
      }
    default:
        return state;
  }
}