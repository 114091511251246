import React from 'react'

import {
  useColorScheme,
  View,
  StyleSheet,
  Dimensions,
  TouchableOpacity,
  ActivityIndicator,
  Alert,
  Modal,
} from 'react-native';

import {useFocusEffect, useIsFocused, useNavigation} from '@react-navigation/native';
import { Text } from 'react-native';
import { FlatList } from 'react-native-gesture-handler';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../ui/Button';
import { HaulLogEntry } from '../../services/types';
import HaulApi from '../../services/modules/haul/api';
import Moment from 'react-moment';
import { useEffect } from 'react';
import Colors from '../../constants/Colors';
import { RootState } from '../../services/store';
import { convertToCubicMeters, convertToTons } from '../../utility/conversion';
import { selectEntry } from '../../services/modules/haul/actions';
import { TraxxText } from '../ui/TraxxText';

interface Props {
}

const CompanyList: React.FC<Props> = ({ 
}) => {   
  const dispatch = useDispatch();
  const navigation = useNavigation();

  const authState = useSelector((state: RootState) => state.auth);

  const isFocused = useIsFocused()

  const [haulLog, setHaulLog] = React.useState([]);
  const [haulLogNoProject, setHaulLogNoProject] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [hasMore, setHasMore] = React.useState(false);
  const [haulLogEmpty, setHaulLogEmpty] = React.useState(true);
  const [weightUnits, setWeightUnits] = React.useState('');

  const [page, setPage] = React.useState(-1)
  const [filter, setFilter] = React.useState(0)

  const openHaulEntry = (entry: HaulLogEntry) => {
    dispatch(selectEntry(entry.id));
    navigation.navigate("HaulLogEntryViewer");
  };
  
  const init = () => {
    if (authState.metric == 0) {
      setWeightUnits("tonnes");
    } else {
      setWeightUnits("cu. meters");
    }

    loadHauls(0, 0)

    HaulApi.companyLogsNoProject()
    .then((apiResponse: any) => {
      setHaulLogNoProject(apiResponse.data);
    })
    .catch(error => {
      console.log(error)
    });
  }

  const loadHauls = (page: number, filter: number) => {    
    setPage(page)
    setFilter(filter)

    let currentHaulLog = haulLog

    if (page == 0) {
      currentHaulLog = []
    }

    HaulApi.companyLogs(page, filter)
    .then((apiResponse: any) => {
      setHaulLog(currentHaulLog.concat(apiResponse.data));

      if (apiResponse.data.length == 0 && filter == 0) {
        setHaulLogEmpty(true)
      } else {
        setHaulLogEmpty(false)
      }
      
      if (apiResponse.data.length < 10) {
        setHasMore(false)
      } else {
        setHasMore(true)
      }

      setLoading(false);
    })
    .catch(error => {
      console.log(error)
    });
  }

  const addHaulLog = () => {      
    navigation.navigate("HaulLogEntryEditor");
  }

  useFocusEffect (
    React.useCallback(() => {      
      if (isFocused) {
        setLoading(true);
        init();
      }
    }, [isFocused])
  );

  return (<View style={styles.componentContainer}>     
    {loading &&
      <View style={{marginTop: 40}}>
        <ActivityIndicator size="large" color="#3F51B5" />
        <TraxxText style={styles.loadingText}>Loading...</TraxxText>
      </View>
    }
    {!loading && <View>
      <View>   
        {haulLogEmpty && <TraxxText style={styles.noLog}>Your haul log is empty</TraxxText>}                                 
      </View>
      {haulLogNoProject.length > 0 && <TraxxText style={styles.header}>No Project Selected</TraxxText>}
      {haulLogNoProject.length > 0 && haulLogNoProject.map((item: any) => {
        return (
          <TouchableOpacity onPress={() => openHaulEntry(item)} key={item.id.toString()}>
            <View style={styles.card}>
              <View style={styles.cardInfoContainer}>
                <View style={styles.cardDate}>
                  <Moment element={Text} format="ddd" style={styles.cardDateDay}>{item.pickUpDate}</Moment>
                  <Moment element={Text} format="D" style={styles.cardDateDate}>{item.pickUpDate}</Moment>
                  <Moment element={Text} format="MMM" style={styles.cardDateMonth}>{item.pickUpDate}</Moment>
                </View>
                <View style={styles.cardDetail}>
                  <TraxxText style={styles.cardDetailPickUp}>{item.pickUpSite.title}</TraxxText>
                  <TraxxText style={styles.cardDetailWeight}>{authState.metric == 0 ? convertToTons(item.weight).toFixed(2): item.weight.toFixed(2)} {weightUnits}</TraxxText>
                  <View>
                    <TraxxText style={styles.cardDetailStatus}>{item.status}</TraxxText>
                  </View>
                </View>
              </View>
            </View>
          </TouchableOpacity>
        )
      })}
      <Button text='Add Haul' onPress={() => addHaulLog()} />
      {!haulLogEmpty && <TraxxText style={styles.header}>Hauls</TraxxText>}
      {!haulLogEmpty && <View style={{flexDirection: 'row'}}>
        <Button
          text="All"
          style={filter == 0 ? styles.buttonOn : styles.buttonOff}
          onPress={() => { loadHauls(0, 0) }} />
        <Button
          text="In Progress"
          style={filter == 1 ? styles.buttonOn : styles.buttonOff}
          onPress={() => { loadHauls(0, 1) }} />
        <Button
          text="Completed"
          style={filter == 2 ? styles.buttonOn : styles.buttonOff}
          onPress={() => { loadHauls(0, 2) }} />
      </View>}
      {!haulLogEmpty && haulLog.map((item: any) => {
        return (
          <TouchableOpacity onPress={() => openHaulEntry(item)} key={item.id.toString()}>
            <View style={styles.card}>
              <View style={styles.cardInfoContainer}>
                <View style={styles.cardDate}>
                  <Moment element={Text} format="ddd" style={styles.cardDateDay}>{item.pickUpDate}</Moment>
                  <Moment element={Text} format="D" style={styles.cardDateDate}>{item.pickUpDate}</Moment>
                  <Moment element={Text} format="MMM" style={styles.cardDateMonth}>{item.pickUpDate}</Moment>
                </View>
                <View style={styles.cardDetail}>
                  <TraxxText style={styles.cardDetailPickUp}>{item.pickUpSite.title}</TraxxText>
                  <TraxxText style={styles.cardDetailWeight}>{authState.metric == 0 ? convertToTons(item.weight).toFixed(2): item.weight.toFixed(2)} {weightUnits}</TraxxText>
                  <View>
                    <TraxxText style={styles.cardDetailStatus}>{item.status}</TraxxText>
                  </View>
                </View>
              </View>
            </View>
          </TouchableOpacity>
        )
      })}

      {haulLog.length > 0 && hasMore && <Button
        text="Load More"
        onPress={() => loadHauls(page + 1, filter)} />}
    </View>}
  </View>
  )
};

const styles = StyleSheet.create({ 
  buttonOn: {
    backgroundColor: Colors.primary,
    color: Colors.borderDarkGrey,
    margin: 4,
  },

  buttonOff: {
    backgroundColor: Colors.primaryBrown,
    margin: 4,
  },

  header: {
    marginVertical: 20,
    fontSize: 20,
    fontWeight: 'bold',
  },

  title: {
    textAlign: 'center',
    margin: 20,
    fontSize: 20,
    fontWeight: 'bold',
  },

  button : {
    margin: 10,
  },

  noLog: {
    textAlign: 'center',
    margin: 20,
    fontSize: 20,
    fontWeight: 'bold',
  },
  componentContainer: {   
    backgroundColor: Colors.white, 
    padding: "1%",
    borderColor: Colors.borderGrey,
    borderWidth: 1,
    borderRadius: 10,
    width: "100%"
  },

  sectionContainer: {
    width: "100%",
  },
  sectionHeader: {
    fontWeight: 'bold',
    fontSize: 20,
    flex: 1,
    margin: 4,
  },
  sectionHeaderButton : {
    margin: 4,
  },
  sectionHeaderView: {
    flexDirection: 'row',
  },

  card: {
    backgroundColor: Colors.white,
    borderColor: Colors.borderDarkGrey,
    borderRadius: 5,
    borderWidth: 1,
    padding: 10,
    margin: 10,
  }, 

  cardInfoContainer: {
    flexDirection: 'row'
  },

  cardDetails: {

  },

  cardDate: {
    marginVertical: 10,
    marginLeft: 10,
    marginRight: 20,
  },

  cardDetail: {
    marginTop: 10,
    marginBottom: 10,
    paddingLeft: 20,
    borderColor: Colors.borderGrey,
    borderLeftWidth: 1,
  },

  cardDateDay: {
    color: Colors.textGrey,
    fontSize: 16,
    textAlign: 'center',
  },

  cardDateDate: {
    color: Colors.text,
    fontSize: 20,
    fontWeight: 'bold',
    textAlign: 'center',
  },

  cardDateMonth: {
    color: Colors.primary,
    fontSize: 16,
    textAlign: 'center',
  },

  cardDetailPickUp: {
    color: Colors.text,
    fontSize: 20,
    fontWeight: 'bold',
  },

  cardDetailWeight: {
    color: Colors.textGrey,
  },

  cardDetailStatus: {
    alignSelf: 'flex-start',
    marginTop: 10,
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderRadius: 10,
    backgroundColor: Colors.primary,
    color: Colors.white,
  },

  loadingText: {
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold'
  },
});
export default CompanyList