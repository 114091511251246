import { AxiosResponse } from 'axios';
import React, { useEffect } from 'react'
import { ActivityIndicator, Dimensions, PermissionsAndroid, Platform, StyleSheet, Text, View } from 'react-native';
import { FlatList } from 'react-native-gesture-handler';
import OrganizationApi from '../../services/modules/organization/api';
import { Organization } from '../../services/types';
import Button from '../ui/Button';
import * as Location from 'expo-location';
import { SearchBar } from 'react-native-elements';
import { SearchBarBaseProps } from 'react-native-elements/dist/searchbar/SearchBar';
import Colors from '../../constants/Colors';
import { TraxxText } from '../ui/TraxxText';

const SafeSearchBar = (SearchBar as unknown) as React.FC<SearchBarBaseProps>;

interface Props {
  onItemSelected: (data: any) => void;
  onCancel?: () => void;
  onNoResults?: () => void;
  showCancel?: boolean;
  placeholder?: string;
}

const OrganizationSearch: React.FC<Props> = ({ 
  onItemSelected,
  onCancel,
  onNoResults,
  showCancel,
  placeholder
}) => { 
  const [searching, setSearching] = React.useState(false);
  const [noResults, setNoResults] = React.useState(false);
  const [noNearby, setNoNearby] = React.useState(false);
  const [results, setResults] = React.useState([]);
  const [keyword, setKeyword] = React.useState('');

  const itemPressed = (data: any) => {
    onItemSelected(data);
  }

  const clearSearch = () => {
      setResults([]);
  }

  const getShowCancel = () => {
    if (showCancel == undefined) {
      return false;
    }

    return showCancel;
  }

  const getPlaceHolder = () => {
    if (placeholder == undefined) {
      return "Search organizations";
    }

    return placeholder;
  }

  const checkCancel = () => {
    if (onCancel) {
      onCancel();
    }
  }

  const updateSearch = (text: string) => {
    setKeyword(text);
    setSearching(true);
    setNoResults(false);
    setNoNearby(false);

    if (text.trim().length > 0) {
      OrganizationApi.search(text)
      .then((apiResponse: any) => {  
        if (apiResponse.data.length == 0) {
          setNoResults(true);
          setResults([]);
        } else {
          setResults(apiResponse.data);
        }
        setSearching(false);
      });
    } else {
      setSearching(false);
      setResults([]);
    }
  };

  const loadResults = async () => {
    let finalStatus = true;

    let { status } = await Location.getForegroundPermissionsAsync();
    
    if (status !== 'granted') {
      let { status } = await Location.requestForegroundPermissionsAsync();

      if (status !== 'granted') {
        finalStatus = false;
      }
    }
    
    setSearching(true);
    
    if (!finalStatus) {   
      OrganizationApi.list(0)
      .then((apiResponse: any) => {  
        if (apiResponse.data.length == 0) {
          if (onNoResults) {
            onNoResults();
          }
          setNoNearby(true);
        } else {
          setResults(apiResponse.data);
          setSearching(false);
        }
      })
      return;
    }
    
    let location = await Location.getCurrentPositionAsync({accuracy: Location.Accuracy.Balanced,});
    const {latitude, longitude} = location.coords;

    OrganizationApi.listUsingGPS(latitude, longitude)
    .then((apiResponse: any) => { 
      if (apiResponse.data.length == 0) {
        if (onNoResults) {
          onNoResults();
        }
        setNoNearby(true);
      } else {
        setResults(apiResponse.data);
      }
      setSearching(false);
    })
  }

  const requestPermission = async () => {
    try {
      const granted = await PermissionsAndroid.request(
        PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION,
        {
          title: "Find Organization/Project",
          message:
            "Traxx needs access to your location to find companies near you.",
          buttonNegative: "Cancel",
          buttonPositive: "OK"
        }
      );

      if (granted === PermissionsAndroid.RESULTS.GRANTED) {
        loadResults();
      } else {
        OrganizationApi.list(0)
        .then((apiResponse: any) => {  
          if (apiResponse.data.length == 0) {
            if (onNoResults) {
              onNoResults();
            }
          } else {
            setResults(apiResponse.data);
          }
          setSearching(false);
        })
      }
    } catch (err) {
      console.warn(err);
    }
  };

  const checkPermission = () => {
    loadResults();
  };

  useEffect(() => {
    checkPermission();
  }, []);

  return ( 
    <View>
        <SafeSearchBar   
          onChangeText={updateSearch}
          onCancel={clearSearch}
          placeholder={getPlaceHolder()}
          value={keyword}
          platform={"default"}
          inputStyle={styles.searchBarInput}
          containerStyle={styles.searchBarContainer}
          inputContainerStyle={styles.searchBarInputContainer}
        />

      {searching &&
        <View style={styles.activityContainer}>
          <ActivityIndicator size="large" color="#3F51B5" />
          <TraxxText style={styles.searchingText}>Searching...</TraxxText>
        </View>
      }
      
      {noResults && !searching &&
        <View style={styles.activityContainer}>
          <TraxxText style={styles.nothingText}>Nothing found</TraxxText>
        </View>
      }
      
      {noNearby && !searching &&
        <View style={styles.activityContainer}>
          <TraxxText style={styles.nothingText}>Nothing nearby found, please search for a company.</TraxxText>
        </View>
      }
      
      {!searching && !noResults && !noNearby && <View>
        <TraxxText style={styles.instruction}>Press a company</TraxxText>
        <FlatList
          data={results}
          keyExtractor={(item: Organization, index) => item.id.toString() + index}
          renderItem={({ item }: { item: Organization }) => {
            return (<View style={styles.carouselItem}>
                      <TraxxText style={styles.title} numberOfLines={1} ellipsizeMode='tail' onPress={() => itemPressed(item)}>{item.title}</TraxxText>
                      <TraxxText style={styles.subTitle} numberOfLines={1} ellipsizeMode='tail' onPress={() => itemPressed(item)}>{item.address}, {item.city}, {item.province}</TraxxText>
                    </View>)
          }} />
      </View>}

      {getShowCancel() && <Button style={styles.button} onPress={checkCancel}
        text="Cancel">
      </Button>}
    </View>)
};

const width = Dimensions.get('window').width
  
const styles = StyleSheet.create({
  category: {
    fontSize: 18,
    fontStyle: 'italic',
  }, 

  screen: {
    flex: 1
  },

  listContainer: {
    flex: 12
  },

  activityContainer: {
  },

  nothingText: {
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold'
  },

  searchingText: {
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold'
  },

  header: {
    fontSize: 32,
    backgroundColor: "#fff",
    margin: 10,
  },
 
  item: {
    backgroundColor: "#f9c2ff",
    padding: 20,
    marginVertical: 8
  },

  title: {
    fontWeight: 'bold',
    marginLeft: 10,
    fontSize: 18
  },

  subTitle: {
    marginLeft: 10,
    fontSize: 16
  },

  image: {
    height: 90,
    width: 90,
    resizeMode: 'cover',
    borderRadius: 90
  },

  carouselItem: {
    margin: 10
  },

  bookImage: {
    width: 120,
    height:120,
    resizeMode: 'cover',
    marginRight: 10
  },

  bookCount: {
    fontSize: 20,
    fontWeight: 'bold'
  },

  courseBookImage: {
    width: 40,
    height: 40,
    marginRight: 5,
    marginTop: 5,
    resizeMode: 'cover'
  },

  signUpContainer: {
    marginTop: 60,
    marginBottom: 250
  },

  subNavContainer: {
    height: 50,
    flexDirection: 'row'
  },
  button: {
    margin: 10,
    width: width - 20
  },
  resultContainer: {
    width: width - 150,
  },
  searchBarInput: {
    backgroundColor: Colors.white,
    fontSize: 15,
  },
  searchBarContainer: {
    backgroundColor: Colors.white,
    borderColor: Colors.borderGrey,
    borderWidth: 1,
    borderRadius: 20,
    margin: 10,
    padding: 2
  },
  searchBarInputContainer: {
    backgroundColor: Colors.white,
  },

  instruction: {
    marginVertical: 5,
    marginHorizontal: 15,
  },
});

export default OrganizationSearch
