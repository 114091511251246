import React, { useEffect } from 'react';
import { Alert, StyleSheet, View, TextInput, Dimensions, ActivityIndicator, Text } from 'react-native';
import Button from '../ui/Button';
import AccountApi from "../../services/modules/account/api";
import { useDispatch, useSelector } from 'react-redux';
import { loginFailure, loginSuccess } from '../../services/modules/auth/actions';
import AuthApi from '../../services/modules/auth/api';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Colors from '../../constants/Colors';
import PlacesSearchBar from '../location/PlacesSearchBar';
import * as Location from 'expo-location';
import { Site } from '../../services/types';
import { RootState } from '../../services/store';
import { TraxxText } from '../ui/TraxxText';
import { TraxxTextInput } from '../ui/TraxxTextInput';
import ReCAPTCHA from 'react-google-recaptcha';

interface Props {
  role: string;
  onComplete?: () => void;
}

const ReuseSignUpForm: React.FC<Props> = ({ 
  role,
  onComplete
}) => { 
  const dispatch = useDispatch();

  const appState = useSelector((state: RootState) => state.app)

  const [email, setEmail] = React.useState('');
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [reuseName, setReuseName] = React.useState('');  
  const [reuseAddress, setReuseAddress] = React.useState("");
  const [reuseCity, setReuseCity] = React.useState("");
  const [reuseProvince, setReuseProvince] = React.useState("");
  const [reusePostalCode, setReusePostalCode] = React.useState("");
  const [contactPhone, setContactPhone] = React.useState('');
  const [contactEmail, setContactEmail] = React.useState('');
  const [contactName, setContactName] = React.useState('');
  const [saving, setSaving] = React.useState(false);
  const [findingLocation, setFindingLocation] = React.useState(false);
  const [recaptchaToken, setRecaptchaToken] = React.useState('');

  const onRecaptchaChange = (value: any) => {
    setRecaptchaToken(value);
  }

  const signUp = () => {
    if (recaptchaToken.length == 0) {
      alert("Please check the Recaptcha.")
      return;
    }

    if (reuseName.trim().length == 0 || reuseAddress.trim().length == 0 || reuseCity.trim().length == 0 || reuseProvince.trim().length == 0 || reusePostalCode.trim().length == 0) {
      alert("Please fill in your site name and full address.");
      return;
    }
    
    if (password.trim().length == 0 || firstName.trim().length == 0 || lastName.trim().length == 0 || email.trim().length == 0) {
      alert("Please fill in all fields");
      return;
    }

    if (password != confirmPassword) {
      alert("Passwords do not match");
      return;
    }

    if (contactName.trim().length == 0 || contactPhone.trim().length == 0) {
      alert("Please fill in all required fields.");    
      return;
    }
    
    setSaving(true);

    AccountApi.registerReuse(email, password, firstName, lastName, role, recaptchaToken, reuseName, reuseAddress, reuseCity, reuseProvince, reusePostalCode, contactName, contactEmail, contactPhone)
    .then(() => {      
      AuthApi.login(email, password)
      .then(async (apiResponse: any) => {
        await storeAutoLogin(apiResponse.data);
        dispatch(loginSuccess(apiResponse.data));
      })
      .catch((e) => {
        dispatch(loginFailure());
        setSaving(false);
      });
    })
    .catch((error) => {
      setSaving(false);
      alert(error.data.message);
    });
  };

  const storeAutoLogin = (async (userData: any) => {
    try {
      await AsyncStorage.setItem(
        'user.id',
        userData.id
      );
      await AsyncStorage.setItem(
        'user.firstName',
        userData.firstName
      );
      await AsyncStorage.setItem(
        'user.lastName',
        userData.lastName
      );
      await AsyncStorage.setItem(
        'user.role',
        userData.role
      );
      await AsyncStorage.setItem(
        'user.token',
        userData.token
      );
      await AsyncStorage.setItem(
        'user.refreshToken',
        userData.refreshToken
      );  

      if (userData.profilePhoto == null) {
        await AsyncStorage.setItem(
          'user.profilePhoto',
          ""
        );  
      } else {
        await AsyncStorage.setItem(
          'user.profilePhoto',
          userData.profilePhoto
        );  
      }

      if (userData.vehicleId == null) {
        await AsyncStorage.setItem(
          'user.vehicleId',
          ""
        ); 
      } else {
        await AsyncStorage.setItem(
          'user.vehicleId',
          userData.vehicleId
        ); 
      } 

      const organizationId = parseInt(userData.organizationId).toString();

      await AsyncStorage.setItem(
        'user.organizationId',
        organizationId
      ); 

      if (userData.organization == null) {
        await AsyncStorage.setItem(
          'user.organization',
          ""
        ); 
      } else {
        await AsyncStorage.setItem(
          'user.organization',
          userData.organization
        ); 
      } 

      const metric = parseInt(userData.metric).toString();

      await AsyncStorage.setItem(
        'user.metric',
        metric
      ); 

      await AsyncStorage.setItem(
        'user.subscribed',
        userData.subscribed.toString()
      ); 

      await AsyncStorage.setItem(
        'user.subscriptionOwner',
        userData.subscriptionOwner.toString()
      ); 

      await AsyncStorage.setItem(
        'user.subscriptionId',
        userData.subscriptionId.toString()
      ); 

      return true;
    } catch(error) {
      return false;
    }
  });

  const findUsingGPS = async () => {    
    let { status } = await Location.requestForegroundPermissionsAsync();

    if (status !== 'granted') {
      alert("Location permissions must be given to use this feature.")
      return;
    }

    setFindingLocation(true);
    
    let location = await Location.getCurrentPositionAsync({accuracy: Location.Accuracy.Balanced,});
    
    Location.reverseGeocodeAsync(location.coords)
    .then(data => {
      if (data.length > 0) {
        var postalCode = data[0].postalCode!;
        var address = data[0].street!;
        var city = data[0].city!;
        var province = data[0].region!;

        setReuseAddress(address);
        setReusePostalCode(postalCode);
        setReuseProvince(province);
        setReuseCity(city);
      }
    })
    .finally(() => {
      setFindingLocation(false);
    })
  }

  const placeSelected = (site: Site) => {
    setReuseAddress(site.address);
    setReusePostalCode(site.postalCode);
    setReuseProvince(site.province);
    setReuseCity(site.city);
  }

  useEffect(() => {
    if (appState.googleKey != "") {
      Location.setGoogleApiKey(appState.googleKey);
    }
  }, [appState]);

  /*
    <PlacesSearchBar
      onPlaceSelected={placeSelected}
      placeholder="Quick find (start typing address or place)"
    />
  */

  return (
    <View style={styles.container}>
      <View style={styles.inputContainer}>
        {saving &&
            <View>
              <ActivityIndicator size="large" color="#3F51B5" />
              <TraxxText style={styles.searchingText}>Saving...</TraxxText>
            </View>
        }   

        {findingLocation &&
            <View>
              <ActivityIndicator size="large" color="#3F51B5" />
              <TraxxText style={styles.searchingText}>Finding location...</TraxxText>
            </View>
        } 

        {!saving && !findingLocation && <View style={{marginBottom: 50, width: "100%"}}>  
          <TraxxText style={styles.heading}>Account Information</TraxxText>
          <TraxxText style={styles.title}>Please provide your name, email and set a password for your account.</TraxxText>
                
          <TraxxTextInput
            style={styles.input}
            placeholder="Enter your first name"
            placeholderTextColor="#003f5c"
            value={firstName}
            onChangeText={(firstName: string) => setFirstName(firstName)}
            returnKeyType={'next'}
          />
          
          <TraxxTextInput
            style={styles.input}
            placeholder="Enter your last name"
            placeholderTextColor="#003f5c"
            value={lastName}
            onChangeText={(lastName: string) => setLastName(lastName)}
            returnKeyType={'next'}
          />

          <TraxxTextInput
            style={styles.input}
            placeholder="Enter your email"
            placeholderTextColor="#003f5c"
            keyboardType="email-address"
            value={email}
            onChangeText={(email: string) => setEmail(email)}
            returnKeyType={'next'}
          />

          <TraxxTextInput
            style={styles.input}
            placeholder="Enter a password"
            placeholderTextColor="#003f5c"
            secureTextEntry={true}
            value={password}
            onChangeText={(password: string) => setPassword(password)}
            returnKeyType={'next'}
          />
          
          <TraxxTextInput
            style={styles.input}
            placeholder="Confirm your password"
            placeholderTextColor="#003f5c"
            secureTextEntry={true}
            value={confirmPassword}
            onChangeText={(confirmPassword: string) => setConfirmPassword(confirmPassword)}
          />
          <TraxxText style={[styles.heading, {marginTop: 15}]}>Reuse Site Information</TraxxText>
          <TraxxText style={[styles.title, {marginHorizontal: 10}]}>Enter the name and address of your company.</TraxxText>
          <TraxxTextInput
            style={styles.input}
            placeholder="Enter your reuse site name"
            placeholderTextColor="#003f5c"
            value={reuseName}
            onChangeText={(reuseName: string) => setReuseName(reuseName)}
            returnKeyType={'next'}
          />
          <TraxxTextInput
            style={styles.input}
            placeholder="Address"
            placeholderTextColor="#003f5c"
            value={reuseAddress}
            onChangeText={(reuseAddress: string) => {
              setReuseAddress(reuseAddress);
            }}
          />
          <TraxxTextInput
            style={styles.input}
            placeholder="City"
            placeholderTextColor="#003f5c"
            value={reuseCity}
            onChangeText={(reuseCity: string) => {
              setReuseCity(reuseCity);
            }}
          />
          <TraxxTextInput
            style={styles.input}
            placeholder="Province"
            placeholderTextColor="#003f5c"
            value={reuseProvince}
            onChangeText={(reuseProvince: string) => {
              setReuseProvince(reuseProvince);
            }}
          />
          <TraxxTextInput
            style={styles.input}
            placeholder="Postal code"
            placeholderTextColor="#003f5c"
            value={reusePostalCode}
            onChangeText={(reusePostalCode: string) => {
              setReusePostalCode(reusePostalCode);
            }}
          />

          <TraxxText style={styles.heading}>Contact Person</TraxxText>
          <TraxxText style={styles.title}>Please provide the name and contact information for the person responsible for the site.</TraxxText>
                
          <TraxxTextInput
            style={styles.input}
            placeholder="Enter the contact person's name"
            placeholderTextColor="#003f5c"
            value={contactName}
            onChangeText={(contactName: string) => setContactName(contactName)}
            returnKeyType={'next'}
          />
          
          <TraxxTextInput
            style={styles.input}
            placeholder="Enter a phone number"
            placeholderTextColor="#003f5c"
            keyboardType="phone-pad"
            value={contactPhone}
            onChangeText={(contactPhone: string) => setContactPhone(contactPhone)}
            returnKeyType={'next'}
          />
          
          <TraxxTextInput
            style={styles.input}
            placeholder="Enter an email address (optional)"
            placeholderTextColor="#003f5c"
            keyboardType="email-address"
            value={contactEmail}
            onChangeText={(contactEmail: string) => setContactEmail(contactEmail)}
            returnKeyType={'next'}
          />
          
          <View style={{alignSelf: 'center'}}>
            <ReCAPTCHA
              sitekey="6LcP5QwhAAAAAJE-hIUahNuoVWPtFyCW6KAeX3w0"
              onChange={onRecaptchaChange}
            />
          </View>

          <Button 
            style={styles.button}
            onPress={signUp}
            text="Sign Up">
          </Button>
        </View>}
      </View>
    </View>
  )
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    width: "100%",
    marginTop: 20
  },

  inputContainer: {
    width: "100%",
  },
  
  title: {
    fontSize: 20,
    textAlign: 'center',
  },

  searchingText: {
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold'
  },

  input: {
    height: 50,
    width: "100%",
    paddingVertical: 10,
    paddingHorizontal: 20,
    margin: 10,
    borderColor: Colors.borderGrey,
    borderWidth: 1,
    borderRadius: 20
  },

  button : {
    marginTop: 20,
    width: "100%",
    marginLeft: 10,
  },

  heading: {
    fontSize: 20,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  entryButton: {
    width: "100%",
    marginLeft: 10,
    marginTop: 10,
  },
});

export default ReuseSignUpForm