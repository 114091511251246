import { Project } from "../../types";

export const SELECT_PROJECT = 'PROJECT/SELECT_PROJECT';

export interface ProjectDispatchTypes {
  type: string;
  payload?: any;
}

export interface ProjectState {
  id?: number;
  title?: string;
  accessLevel?: number;
}