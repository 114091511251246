import { FontAwesome } from '@expo/vector-icons';
import { useFocusEffect, useIsFocused, useNavigation } from '@react-navigation/core';
import { AxiosResponse } from 'axios';
import React, { useEffect } from 'react'
import { ActivityIndicator, Dimensions, StyleSheet, Text, TouchableOpacity, useColorScheme, View } from 'react-native';
import { FlatList } from 'react-native-gesture-handler';
import { useDispatch } from 'react-redux';
import Colors from '../../constants/Colors';
import { selectProject } from '../../services/modules/project/actions';
import ProjectApi from '../../services/modules/project/api';
import { Project } from '../../services/types';
import { TraxxText } from '../ui/TraxxText';

interface Props {
}

const PendingLabResults: React.FC<Props> = ({
}) => { 
    const dispatch = useDispatch();
  
    const navigation = useNavigation();
  
    const isDarkMode = useColorScheme() === 'dark';
    const isFocused = useIsFocused()
  
    const [count, setCount] = React.useState("");
    const [projects, setProjects] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [expanded, setExpanded] = React.useState(false);
    
    const openProject = (entry: Project, accessLevel: number) => {
      dispatch(selectProject(entry, accessLevel));
      navigation.navigate("Project");
    };
  
    const init = () => {
      setLoading(true);
      ProjectApi.labPending()
      .then((apiResponse: any) => {
        setProjects(apiResponse.data);
        setCount("(" + apiResponse.data.length + ")");
        setLoading(false);
      });
    }
  
    const drawCard = (({item} : {item: any}) => {
      return (
      <TouchableOpacity onPress={() => openProject(item, 1)} style={{margin:0, padding: 0}}>
        <View style={styles.card}>
          <View style={styles.cardInfoContainer}>
            <TraxxText style={styles.cardDetailPickUp}>{item.title}</TraxxText>
          </View>
        </View>
      </TouchableOpacity>)
    })
  
    const toggleExpanded = () => {
      setExpanded(!expanded);
    }
  
    useFocusEffect (
      React.useCallback(() => {      
        if (isFocused) {
          init();
        }
      }, [isFocused])
    );
  
    return (<View style={styles.componentContainer}>   
              <View style={styles.sectionContainer}>
                  <View style={styles.sectionHeaderView}>
                    <TraxxText style={styles.sectionHeader}>Pending Soil Quality Information {count}</TraxxText>
                    <TouchableOpacity onPress={toggleExpanded} style={{paddingRight: 5}}>
                      {expanded && <FontAwesome name="arrow-circle-up" color="#CCCCCC" size={30}/>}
                      {!expanded && <FontAwesome name="arrow-circle-down" color="#CCCCCC" size={30}/>}
                    </TouchableOpacity>
                  </View>       
                  {loading &&
                    <View style={{marginTop: 40}}>
                      <ActivityIndicator size="large" color="#3F51B5" />
                      <TraxxText style={styles.loadingText}>Loading...</TraxxText>
                    </View>
                  }
                  {!loading && expanded && projects.length == 0 && <TraxxText style={styles.noLog}>You do not have any projects pending soil quality information</TraxxText>} 
                  {!loading && expanded && projects.length > 0 && <FlatList
                    keyExtractor={(item: Project, index) => item.id.toString() + index}
                    data={projects}
                    renderItem={drawCard}
                    />}
                </View>
            </View>
    )
  };
  
  const styles = StyleSheet.create({ 
    componentContainer: {   
      marginBottom: "2%",
      backgroundColor: Colors.white, 
      padding: "1%",
      borderColor: Colors.borderGrey,
      borderWidth: 1,
      borderRadius: 10,
      width: "100%"
    },
    modalText: {
      textAlign: 'center',
      fontWeight: 'bold',
      fontSize: 20,
      margin: 20,
    },
    entryButton: {
      marginLeft: 10,
      marginTop: 10,
    },
  
    inputLabel: {
      fontSize: 16,
      marginTop: 10,
      marginLeft: 15,
    },
  
    inputGroup: {
      flexDirection: 'row',
      marginLeft: 5,
    },
    input: {
      height: 50,
      paddingVertical: 10,
      paddingHorizontal: 20,
      margin: 10,
      flex: 1,
      borderColor: Colors.borderGrey,
      borderWidth: 1,
      borderRadius: 20
    },
  
    noLog: {
      marginTop: 10,
      marginLeft: 5,
      fontSize: 20,
    },
  
    card: {
      borderColor: Colors.borderDarkGrey,
      borderRadius: 5,
      borderWidth: 1,
      width: "96%",
      padding: 10,
      marginTop: 5
    }, 
  
    cardInfoContainer: {
    },
  
    cardDetailPickUp: {
      color: Colors.text,
      fontSize: 20,
      fontWeight: 'bold',
    },
  
    loadingText: {
      textAlign: 'center',
      padding: 10,
      fontWeight: 'bold'
    },
    sectionContainer: {
      width: "100%",
      margin: 10
    },
    sectionHeader: {
      fontWeight: 'bold',
      fontSize: 20,
      flex: 1,
      margin: 4,
    },
    sectionHeaderButton : {
      margin: 4,
    },
    sectionHeaderView: {
      flexDirection: 'row',
      width: "96%"
    },
  });

  export default PendingLabResults