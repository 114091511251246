import React from 'react'

import {
  StyleSheet,
  View,
  Image,
  ScrollView,
  Platform,
} from 'react-native';

import {useNavigation} from '@react-navigation/native';
import Colors from '../constants/Colors';
import Button from '../components/ui/Button';
import ForgotPasswordForm from '../components/authentication/ForgotPasswordForm';

interface Props {
}

const ForgotPasswordScene: React.FC<Props> = ({ 
}) => { 
  const navigation = useNavigation();
  
  const cancel = () => {
    navigation.goBack();
  }

  return ( 
    <ScrollView
      contentInsetAdjustmentBehavior="automatic"
      keyboardShouldPersistTaps='always'>
      <View style={styles.container}>
        <View style={styles.inputContainer}>
          <Image style={styles.image} source={require('../assets/images/logo.png')} />
          <ForgotPasswordForm 
            onComplete={cancel} />
          <View style={styles.signUpContainer}>
            <Button onPress={cancel}
              text="Cancel">
            </Button>
          </View>
        </View>
      </View>
    </ScrollView>
  )
};

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    flex: 1,
    ...Platform.select({
      ios: {
        width: "100%"
      },
      android: {
        width: "100%"
      },
      web: {
        width: "80%"
      }
    })
  },
  inputContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 80,
  },
 
  image: {
    flex: 1,
    width: 200,
    height: 38,
    resizeMode: 'contain',
    marginBottom: 40,
  },

  signUpContainer: {
    marginTop: 60,
    marginBottom: 250
  }
});

export default ForgotPasswordScene