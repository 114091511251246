import { FontAwesome } from '@expo/vector-icons';
import { createDrawerNavigator } from "@react-navigation/drawer";
import { NavigationContainer, DefaultTheme, DarkTheme } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import * as React from 'react';
import { ColorSchemeName, Image } from 'react-native';
import { useSelector } from 'react-redux';
import BackButton from '../components/menu/BackButton';
import HamburgerMenu from '../components/menu/Hamburger';
import DashboardScreen from '../screens/Dashboard';
import LoginScreen from '../screens/Login';
import ProjectScreen from '../screens/Project';
import ProjectMapScreen from '../screens/ProjectMap';
import { RootState } from '../services/store';
import { RootStackParamList } from './types';
import LinkingConfiguration from './LinkingConfiguration';
import ReuseMapScreen from '../screens/ResuseMap';
import CompanyMapScreen from '../screens/CompanyMap';
import HaulerInfoScreen from '../screens/HaulerInfo';
import ForgotPasswordScene from '../screens/ForgotPassword';
import HaulLogProjectReport from '../components/reports/HaulLogProjectReport';
import HaulLogReuseReport from '../components/reports/HaulLogReuseReport';
import HaulLogCompanyReport from '../components/reports/HaulLogCompanyReport';
import HaulLogEntryViewerScene from '../components/hauler/HaulLogViewer';
import ReuseSiteScene from '../screens/ReuseSites';
import HaulLogEntryEditorScene from '../components/hauler/HaulLogEditor';
import HomeButton from '../components/menu/HomeButton';
import ProjectButton from '../components/menu/ProjectButton';
import PreferencesScene from '../screens/Preferences';
import PreferencesMenu from '../components/menu/Preferences';
import SignUpScreen from '../screens/SignUp';
import SubscribeScreen from '../screens/Subscribe';
import VehicleRegistration from '../components/payment/VehicleRegistration';
import CompanyDashboardScreen from '../screens/CompanyDashboard';

export default function Navigation({ colorScheme }: { colorScheme: ColorSchemeName }) {
  return (
    <NavigationContainer
      linking={LinkingConfiguration}
      theme={colorScheme === 'dark' ? DarkTheme : DefaultTheme}>
      <RootNavigator />
    </NavigationContainer>
  );
}

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
const LoginStack = createNativeStackNavigator();
const SubscribeStack = createNativeStackNavigator();
const RootStack = createNativeStackNavigator<RootStackParamList>();
//const BottomTab = createBottomTabNavigator<RootTabParamList>();
const Drawer = createDrawerNavigator();

function RootNavigator() {  
  const authState = useSelector((state: RootState) => state.auth);

  if (!authState.authenticated) {
    return (
      <LoginStack.Navigator>
        <LoginStack.Screen
          name="Login"
          component={LoginScreen}
          options={{headerShown: false}}
        />
        <LoginStack.Screen name="ForgotPassword"
          component={ForgotPasswordScene}
          options={{title: '', headerShown: false}} />
        <LoginStack.Screen name="SignUp" component={SignUpScreen} options={{  
          headerLeft: () => <BackButton />,
          headerTitleAlign: 'center',
          title: "Sign Up" }} /> 
      </LoginStack.Navigator>
    );
  } else {
    if (authState.subscribed) {
      return (    
        <Drawer.Navigator>
          <Drawer.Screen 
            name="Main" 
            component={RootStackNavigator} 
            options={{headerShown: false}} />
        </Drawer.Navigator>
      );
    } else {
      return (  
        <SubscribeStack.Navigator>
          <SubscribeStack.Screen
            name="Subscribe"
            component={SubscribeScreen}
            options={{title: "Subscribe to Traxx",         
                      headerRight: () => <HamburgerMenu />,
                      headerLeft: () => <HeaderImage />,
                      headerTitleAlign: 'center'}}
          />
        </SubscribeStack.Navigator>
      );
    }
  }
}

/*
<Drawer.Screen name="Edit Profile" component={ProfileEditorScene}
              options={{
                title: 'Profile Editor',
                headerStyle: {
                  backgroundColor: colors.primary,
                },
                headerTintColor: '#fff',
                headerTitleStyle: {
                  fontWeight: 'bold',
                }}} />
        <Drawer.Screen name="Edit Preferences" component={PreferencesScene}
              options={{
                title: 'Preferences',
                headerStyle: {
                  backgroundColor: colors.primary,
                },
                headerTintColor: '#fff',
                headerTitleStyle: {
                  fontWeight: 'bold',
                }}} />*/
                
function RootStackNavigator() {
  const authState = useSelector((state: RootState) => state.auth);

  return (<RootStack.Navigator>
    <RootStack.Screen name="Dashboard" component={DashboardScreen} options={{         
        headerRight: () => <PreferencesMenu />,
        headerLeft: () => <HeaderImage />,
        headerTitleAlign: 'center',
        title: authState.organizationId != 0 ? authState.organization : "Home" }} />
    <RootStack.Screen name="Preferences" component={PreferencesScene} options={{         
        headerRight: () => <HamburgerMenu />,
        headerLeft: () => <BackButton />,
        headerTitleAlign: 'center',
        title: "Account"}} />
    <RootStack.Screen name="Vehicles" component={VehicleRegistration} options={{         
        headerRight: () => <HamburgerMenu />,
        headerLeft: () => <BackButton />,
        headerTitleAlign: 'center',
        title: "Trucks"}} />
    <RootStack.Screen name="Project" component={ProjectScreen} options={{  
      headerLeft: () => <HomeButton />,
      headerTitleAlign: 'center',
      title: "Project" }} />  
    <RootStack.Screen name="ProjectMap" component={ProjectMapScreen} options={{  
      headerLeft: () => <ProjectButton />,
      headerTitleAlign: 'center',
      title: "Project Tracker Map" }} /> 
    <RootStack.Screen name="ReuseMap" component={ReuseMapScreen} options={{  
      headerLeft: () => <HomeButton />,
      headerTitleAlign: 'center',
      title: "Haul Tracker Map" }} /> 
    <RootStack.Screen name="ReuseSites" component={ReuseSiteScene} options={{  
      headerLeft: () => <ProjectButton />,
      headerTitleAlign: 'center',
      title: "Reuse Sites" }} /> 
    <RootStack.Screen name="CompanyMap" component={CompanyMapScreen} options={{  
      headerLeft: () => <HomeButton />,
      headerTitleAlign: 'center',
      title: "Tracker Map" }} />  
    <RootStack.Screen name="CompanyDashboard" component={CompanyDashboardScreen} options={{  
      headerLeft: () => <BackButton />,
      headerTitleAlign: 'center',
      title: authState.organization }} />  
    <RootStack.Screen name="HaulerInfo" component={HaulerInfoScreen} options={{  
      headerLeft: () => <BackButton />,
      headerTitleAlign: 'center',
      title: "Hauler Info" }} /> 
    <RootStack.Screen name="ReportHaulLogProject" component={HaulLogProjectReport} options={{  
      headerLeft: () => <ProjectButton />,
      headerTitleAlign: 'center',
      title: "Haul Logs" }} /> 
    <RootStack.Screen name="ReportHaulLogReuse" component={HaulLogReuseReport} options={{  
      headerLeft: () => <HomeButton />,
      headerTitleAlign: 'center',
      title: "Haul Logs" }} /> 
    <RootStack.Screen name="ReportHaulLogCompany" component={HaulLogCompanyReport} options={{  
      headerLeft: () => <HomeButton />,
      headerTitleAlign: 'center',
      title: "Haul Logs" }} /> 
    <RootStack.Screen name="HaulLogEntryViewer" component={HaulLogEntryViewerScene} options={{  
      headerLeft: () => <BackButton />,
      headerTitleAlign: 'center',
      title: "Haul Log" }} />
    <RootStack.Screen name="HaulLogEntryEditor" component={HaulLogEntryEditorScene} options={{  
      headerLeft: () => <BackButton />,
      headerTitleAlign: 'center',
      title: "Haul Log Editor" }} /> 
    <RootStack.Screen name="Subscribe" component={SubscribeScreen} options={{  
      headerLeft: () => <BackButton />,
      headerTitleAlign: 'center',
      title: "Subscribe to Traxx" }} /> 
  </RootStack.Navigator>);
}

/*
function MainTabs() {
  return (<BottomTab.Navigator
    initialRouteName="Dashboard">
    <BottomTab.Screen
      name="Dashboard"
      component={DashboardScreen}
      options={({ navigation }: RootTabScreenProps<'Dashboard'>) => ({          
        headerRight: () => <HamburgerMenu />,
        headerLeft: () => <HeaderImage />,
        headerTitleAlign: 'center',
        title: "Home",
        tabBarIcon: ({ color }) => <TabBarIcon name="home" color={color} />,          
      })}
    />
  </BottomTab.Navigator>);
}*/

function TabBarIcon(props: {
  name: React.ComponentProps<typeof FontAwesome>['name'];
  color: string;
}) {
  return <FontAwesome size={30} style={{ marginBottom: -3 }} {...props} />;
}

function HeaderImage() {
  return <Image style={{width: 100, height: 20, marginLeft: 10, resizeMode: 'contain'}} source={require('../assets/images/logo.png')} />;
}