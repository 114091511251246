

import React, { useEffect } from 'react'

import {
  View,
  StyleSheet,
  ActivityIndicator,
  Dimensions,
  Image,
} from 'react-native';

import { Text } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import Colors from '../../constants/Colors';
import { RootState } from '../../services/store';
import GoogleMapReact from 'google-map-react';
import AccountApi from '../../services/modules/account/api';
import ProjectApi from '../../services/modules/project/api';
import Moment from 'react-moment';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { Account } from '../../services/types';
import { selectAccount } from '../../services/modules/account/actions';
import { useNavigation } from '@react-navigation/core';
import { TraxxText } from '../ui/TraxxText';

interface Props {
}

const ProjectMap: React.FC<Props> = ({ 
}) => {   
  const appState = useSelector((state: RootState) => state.app);
  const projectState = useSelector((state: RootState) => state.project);

  const dispatch = useDispatch();
  const navigation = useNavigation();

  const [loading, setLoading] = React.useState(true);
  const [locations, setLocations] = React.useState([]);
  const [latitude, setLatitude] = React.useState(0.0);
  const [longitude, setLongitude] = React.useState(0.0);
  const [noHauls, setNoHauls] = React.useState(false);
  
  const openHauler = (entry: Account) => {
    dispatch(selectAccount(entry));
    navigation.navigate("HaulerInfo");
  };

  const init = () => {
    setLoading(true)
    ProjectApi.getLocation(projectState.id!)
    .then((apiResponse: any) => {
      if (apiResponse.data.length > 0) {
        setLocations(apiResponse.data);
        setLatitude(apiResponse.data[0].lastLocation.latitude);
        setLongitude(apiResponse.data[0].lastLocation.longitude);
        setNoHauls(false);
      } else {
        setNoHauls(true);
      }
      setLoading(false)
    });
  }
  
  const MapPin = ({lat, lng}) => {
    return (<Image
      source={require('../../assets/images/map_marker.png')} 
      style={{height: 32, width:60 }} />)
  };

  useEffect ( () => {
    init();
  }, [projectState]);

  return (<View style={styles.componentContainer}>         
            {loading &&
              <View style={{marginTop: 40}}>
                <ActivityIndicator size="large" color="#3F51B5" />
                <TraxxText style={styles.loadingText}>Loading...</TraxxText>
              </View>
            }
            {!loading && noHauls && <TraxxText style={{margin:20, textAlign: 'center', fontSize: 20, fontWeight:'bold'}}>No hauls in progress.</TraxxText>}
            {!loading && !noHauls && <View style={{flexDirection: 'row'}}>              
              <View style={styles.map}>
                <GoogleMapReact 
                  style={{width: "96%", height: "96%"}}
                  bootstrapURLKeys={{ key: appState.googleKey }}
                  defaultCenter={{lat: latitude, lng: longitude}}
                  defaultZoom={16}
                >
                  {locations.length > 0 && locations.map((location: any, index) => (
                    <MapPin
                      key={location.accountId}
                      lat={location.latitude}
                      lng={location.longitude}
                    />
                  ))}
                </GoogleMapReact>
              </View>
              <View style={styles.panel}>
                <TraxxText style={styles.heading}>Haulers</TraxxText>
                {locations.map((location: any, index) => (
                  <TouchableOpacity style={styles.card} onPress={() => {openHauler(location.account)}} key={location.id}>
                    <View style={styles.cardInfoContainer}>
                      <TraxxText style={styles.cardTitle}>{location.account.firstName} {location.account.lastName}</TraxxText>
                      <TraxxText style={styles.cardSubTitle}>{location.organization}</TraxxText>
                      <View style={{flexDirection: 'row'}}>
                        <TraxxText style={styles.cardContent}>Load picked up </TraxxText><Moment element={Text} format="D-MMM-YYYY h:mm a" style={styles.cardContent}>{location.pickUpDate}</Moment>
                      </View>
                    </View>
                  </TouchableOpacity>
                ))}
              </View>
            </View>}
          </View>
  )
};

const styles = StyleSheet.create({ 
  componentContainer: {   
    width: "100%",
    height: Dimensions.get("screen").height,
  },
  loadingText: {
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold'
  },
  map: {
    backgroundColor: Colors.white, 
    width: "70%",    
    height: 600,
    borderWidth: 1, 
    borderRadius: 10, 
    borderColor: Colors.borderGrey,
    padding: 10,
  },
  panel: {
    backgroundColor: Colors.white, 
    width: "28%",
    marginHorizontal: "1%",
    borderWidth: 1, 
    borderRadius: 10, 
    borderColor: Colors.borderGrey,
    padding: 10,
  },

  heading: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  
  card: {
    borderColor: Colors.borderDarkGrey,
    borderRadius: 5,
    borderWidth: 1,
    padding: 10,
    marginTop: 10,
  }, 

  cardInfoContainer: {
  },

  cardTitle: {
    color: Colors.text,
    fontSize: 20,
    fontWeight: 'bold',
  },

  cardSubTitle: {
    color: Colors.text,
    fontSize: 16,
  },

  cardContent: {
    color: Colors.text,
    fontSize: 14,
  },
});
export default ProjectMap