import React, { useEffect } from 'react'

import {
  View,
  StyleSheet,
  ActivityIndicator,
  Modal,
} from 'react-native';

import { Text } from 'react-native';
import { TextInput } from 'react-native-gesture-handler';
import { useSelector } from 'react-redux';
import { RootState } from '../../services/store';
import { AxiosResponse } from 'axios';
import ProjectApi from '../../services/modules/project/api';
import Button from '../ui/Button';
import Colors from '../../constants/Colors';
import SiteApi from '../../services/modules/site/api';
import { TraxxText } from '../ui/TraxxText';
import { TraxxTextInput } from '../ui/TraxxTextInput';

interface Props {
}

const ProjectContact: React.FC<Props> = ({ 
}) => {   
  const projectState = useSelector((state: RootState) => state.project);

  const [showEditor, setShowEditor] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [saving, setSaving] = React.useState(false);

  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  
  const [siteId, setSiteId] = React.useState(0);

  const saveContact = () => {
    if (name.trim().length == 0 && (email.trim().length == 0 || phone.trim().length == 0)) {
      alert("Please enter a contact name and either a phone or email.");
      return;
    }

    setSaving(true)
    SiteApi.updateContact(siteId, name, email, phone)
    .then((apiResponse: any) => {
      setShowEditor(false)
      setSaving(false)
      init();
    })
  }

  const init = () => {  
    setLoading(true);

    ProjectApi.load(projectState.id!)
    .then((apiResponse: AxiosResponse<any>) => {   
      const project = apiResponse.data;

      setSiteId(project.siteId);
      
      SiteApi.load(project.siteId)
      .then((apiResponse: AxiosResponse<any>) => {  
        if (apiResponse.data.contactName != null) {
          setName(apiResponse.data.contactName);
        }
        
        if (apiResponse.data.contactEmail != null) {
          setEmail(apiResponse.data.contactEmail);
        }
        
        if (apiResponse.data.contactPhone != null) {
          setPhone(apiResponse.data.contactPhone);
        }
        
        setLoading(false)
      })
    })
  }

  useEffect(() => {
    init();
  }, [projectState])

  return (<View style={styles.componentContainer}>     
            {showEditor && <Modal
              animationType="slide"
              transparent={false}
              style={styles.modalView}
              visible={showEditor}
              onRequestClose={() => {
                setShowEditor(!showEditor);
              }}>
              {!saving && <View style={styles.modalView}>
                <TraxxText style={styles.modalText}>Contact Information</TraxxText>
                <TraxxText style={styles.inputLabel}>Name</TraxxText>
                <View style={styles.inputGroup}>
                  <TraxxTextInput
                    style={styles.input}
                    placeholder="Name"
                    placeholderTextColor="#003f5c"
                    value={name}
                    onChangeText={(name: string) => {
                      setName(name);
                    }}
                  />
                </View>
                <TraxxText style={styles.inputLabel}>Phone</TraxxText>
                <View style={styles.inputGroup}>
                  <TraxxTextInput
                    style={styles.input}
                    placeholder="Phone"
                    placeholderTextColor="#003f5c"
                    value={phone}
                    onChangeText={(phone: string) => {
                      setPhone(phone);
                    }}
                  />
                </View>  
                <TraxxText style={styles.inputLabel}>Email</TraxxText>
                <View style={styles.inputGroup}>
                  <TraxxTextInput
                    style={styles.input}
                    placeholder="Email"
                    placeholderTextColor="#003f5c"
                    value={email}
                    onChangeText={(email: string) => {
                      setEmail(email);
                    }}
                  />
                </View>
                <Button style={styles.entryButton} onPress={() => saveContact()}
                  text="Save">
                </Button>
                <Button style={styles.entryButton} onPress={() => setShowEditor(!showEditor)}
                  text="Cancel">
                </Button>
              </View>}
            </Modal>}

            {saving &&
              <View>
                <ActivityIndicator size="large" color="#3F51B5" />
                <TraxxText style={styles.searchingText}>Saving...</TraxxText>
              </View>
            }

            {loading &&
              <View>
                <ActivityIndicator size="large" color="#3F51B5" />
                <TraxxText style={styles.searchingText}>Loading...</TraxxText>
              </View>
            }

            {!loading && !saving && !showEditor && <View>
                <View style={styles.inputContainer}>
                  <View style={styles.sectionContainer}>
                    <View style={styles.sectionHeaderView}>
                      <TraxxText style={styles.sectionHeader}>Contact Information</TraxxText>
                    </View>    
                    
                    <TraxxText style={[styles.subSubHeader, {marginTop: 10}]}>Name</TraxxText>
                    <TraxxText style={styles.subSubText}>{name.length > 0 ? name : "Not Specified"}</TraxxText>
                    <TraxxText style={[styles.subSubHeader, {marginTop: 10}]}>Phone</TraxxText>
                    <TraxxText style={styles.subSubText}>{phone.length > 0 ? phone : "Not Specified"}</TraxxText>
                    <TraxxText style={[styles.subSubHeader, {marginTop: 10}]}>Email</TraxxText>
                    <TraxxText style={styles.subSubText}>{email.length > 0 ? email : "Not Specified"}</TraxxText>
                  </View>
                </View>
                {projectState.accessLevel == 1 && <Button onPress={() => { setShowEditor(true); }}
                  style={styles.button}
                  text="Edit">
                </Button>}
              </View>}
          </View>
  )
};

const styles = StyleSheet.create({
  inputContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },

  componentContainer: {   
    backgroundColor: Colors.white, 
    padding: "1%",
    borderColor: Colors.borderGrey,
    borderWidth: 1,
    borderRadius: 10,
    width: "100%"
  },

  inputView: {
    backgroundColor: "#fff",
    borderRadius: 30,
    width: "70%",
    height: 45,
    marginBottom: 20,
    alignItems: "center",
  },

  inputGroup: {
    flexDirection: 'row',
    marginLeft: 5,
  },
  input: {
    height: 50,
    width: "100%",
    paddingVertical: 10,
    paddingHorizontal: 20,
    margin: 10,
    flex: 1,
    borderColor: Colors.borderGrey,
    borderWidth: 1,
    borderRadius: 20
  },

  inputBox: {
    borderRadius: 5,
    borderWidth: 1,
    borderColor: Colors.borderGrey,    
    fontSize: 16,
    padding: 10,
  },

  inputText: {
    fontSize: 16,
    fontWeight: 'bold',
  },

  inputLabel: {
    fontSize: 16,
    marginTop: 10,
    marginLeft: 5,
  },

  searchingText: {
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold'
  },

  comingSoon: {
    fontSize: 20,
    color: "#fff"
  },

  button : {
    marginTop: 10,
  },
  buttonOn: {
    backgroundColor: Colors.primary,
    margin: 4,
  },
  buttonOff: {
    backgroundColor: Colors.primaryBrown,
    margin: 4,
  },
  sectionContainer: {
    width: "100%",
  },
  sectionHeader: {
    fontWeight: 'bold',
    fontSize: 20,
    flex: 1,
  },
  subHeader: {
    fontWeight: 'bold',
    fontSize: 18,
    flex: 1,
  },
  subSubHeader: {
    fontWeight: 'bold',
    fontSize: 16,
    flex: 1,
  },
  subText: {
    fontSize: 16
  },
  subSubText: {
    fontSize: 14
  },
  sectionHeaderView: {
    flexDirection: 'row',
  },
  sectionHeaderButton : {
    margin: 4,
  },
  modalView: {
    width: "98%",
    margin: "1%",
    backgroundColor: Colors.white,
    borderColor: Colors.white
  },
  card: {
    borderColor: Colors.borderDarkGrey,
    borderRadius: 5,
    borderWidth: 1,
    padding: 10,
  }, 

  cardInfoContainer: {
    flexDirection: 'row'
  },

  cardTitle: {
    color: Colors.text,
    fontSize: 20,
    fontWeight: 'bold',
  },
  modalText: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 20,
    margin: 20,
  },
  entryButton: {
    marginLeft: 10,
    marginTop: 10,
  },
});
export default ProjectContact