import { SELECT_PROJECT, ProjectDispatchTypes, ProjectState } from "./types";

export const initialState: ProjectState = { id: 0, title: '', accessLevel: 0 };

export const projectReducer = (
  state: ProjectState = initialState,
  action: ProjectDispatchTypes
) => {
  switch (action.type){
    case SELECT_PROJECT:
      return {
        ...state,
        id: action.payload?.project.id,
        title: action.payload?.project.title,
        accessLevel: action.payload?.accessLevel
      }
    default:
        return state;
  }
}