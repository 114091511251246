export default {
  borderGrey: '#111',
  borderLightGrey: '#999',
  primary: "#ff5f1f",
  primaryBrown: "#3c2415",
  white: '#fff',
  black: "#000",
  secondary: "#0b2240",
  text: "#0b2240",
  textGrey: "#888888",
  borderDarkGrey: "#ababab",
  offWhite: "#f3f3f3",
  green: "#00f12d",
  red: "#de0505",
};
