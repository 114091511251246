import { Payment } from "../../types";
import { SELECT_SUBSCRIPTION, PaymentDispatchTypes, SET_CODE, SET_INVITE_ID } from "./types";

export const initialState: Payment = { subscriptionId: 0, accountTypeId: 0, code: "", inviteId: 0 };

export const paymentReducer = (
  state: Payment = initialState,
  action: PaymentDispatchTypes
) => {
  switch (action.type){
    case SELECT_SUBSCRIPTION:
      return {
        ...state,
        subscriptionId: action.payload.subscriptionId,
        accountTypeId: action.payload.accountTypeId
      }
    case SET_CODE:
      return {
        ...state,
        code: action.payload.code
      }
    case SET_INVITE_ID:
      return {
        ...state,
        inviteId: action.payload.inviteId
      }
    default:
        return state;
  }
}