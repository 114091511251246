import React from 'react'

import {
  View,
  StyleSheet,
  Dimensions,
  TouchableOpacity,
  ActivityIndicator,
} from 'react-native';

import {useFocusEffect, useIsFocused, useNavigation} from '@react-navigation/native';
import { Text } from 'react-native';
import { FlatList } from 'react-native-gesture-handler';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../ui/Button';
import { HaulLogEntry } from '../../services/types';
import HaulApi from '../../services/modules/haul/api';
import Moment from 'react-moment';
import Colors from '../../constants/Colors';
import { RootState } from '../../services/store';
import { convertToTons } from '../../utility/conversion';
import { addEntry, selectEntry } from '../../services/modules/haul/actions';
import { TraxxText } from '../ui/TraxxText';
import { getAPIDate } from '../../services/helpers';

interface Props {
  title: string;
  accountId?: string;
  organizationId?: number;
  showAdd?: boolean;
}

const HaulerList: React.FC<Props> = ({ 
  title,
  accountId,
  organizationId,
  showAdd
}) => {   
  const dispatch = useDispatch();
  const navigation = useNavigation();

  const authState = useSelector((state: RootState) => state.auth);

  const isFocused = useIsFocused()

  const [haulLog, setHaulLog] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [hasMore, setHasMore] = React.useState(false);
  const [haulLogEmpty, setHaulLogEmpty] = React.useState(true);
  const [weightUnits, setWeightUnits] = React.useState('');

  const [page, setPage] = React.useState(-1)
  const [filter, setFilter] = React.useState(0)

  const openHaulEntry = (entry: HaulLogEntry) => {
    dispatch(selectEntry(entry.id));
    navigation.navigate("HaulLogEntryViewer");
  };
  
  const getShowAdd = () => {
    if (showAdd == undefined) {
      return false;
    }
    
    return showAdd;
  }

  const drawCard = (({item} : {item: any}) => {
    return (<TouchableOpacity onPress={() => openHaulEntry(item)} key={item.id.toString()}>
              <View style={styles.card}>
                <View style={styles.cardInfoContainer}>
                  <View style={styles.cardDate}>
                    <Moment element={Text} format="ddd" style={styles.cardDateDay}>{getAPIDate(item.pickUpDate)}</Moment>
                    <Moment element={Text} format="D" style={styles.cardDateDate}>{getAPIDate(item.pickUpDate)}</Moment>
                    <Moment element={Text} format="MMM" style={styles.cardDateMonth}>{getAPIDate(item.pickUpDate)}</Moment>
                  </View>
                  <View style={styles.cardDetail}>
                    <TraxxText style={styles.cardDetailPickUp}>{item.pickUpSite.title}</TraxxText>
                    <TraxxText style={styles.cardDetailWeight}>{authState.metric == 0 ? convertToTons(item.weight).toFixed(2): item.weight.toFixed(2)} {weightUnits}</TraxxText>
                    <View>
                      <TraxxText style={styles.cardDetailStatus}>{item.status}</TraxxText>
                    </View>
                  </View>
                </View>
              </View>
            </TouchableOpacity>)
  })

  const init = () => {
    if (authState.metric == 0) {
      setWeightUnits("tonnes");
    } else {
      setWeightUnits("cu. meters");
    }

    loadHauls(0, 0)
  }

  const addHaulLog = () => {      
    dispatch(addEntry(authState.id, authState.organizationId));
    navigation.navigate("HaulLogEntryEditor");
  }

  const loadHauls = (page: number, filter: number) => {    
    setLoading(true);
    setPage(page)
    setFilter(filter)

    let currentHaulLog = haulLog

    if (page == 0) {
      currentHaulLog = []
    }

    if (accountId) {
      HaulApi.logForAccount(accountId, page, filter)
      .then((apiResponse: any) => {
        setHaulLog(currentHaulLog.concat(apiResponse.data));

        if (apiResponse.data.length == 0 && filter == 0) {
          setHaulLogEmpty(true)
        } else {
          setHaulLogEmpty(false)
        }

        if (apiResponse.data.length < 10) {
          setHasMore(false)
        } else {
          setHasMore(true)
        }
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {        
        setLoading(false);
      });  
    } else if (organizationId) {
      HaulApi.logForOrganizationAccount(accountId!, organizationId, page, filter)
      .then((apiResponse: any) => {
        setHaulLog(currentHaulLog.concat(apiResponse.data));

        if (apiResponse.data.length == 0 && filter == 0) {
          setHaulLogEmpty(true)
        } else {
          setHaulLogEmpty(false)
        }
        
        if (apiResponse.data.length < 10) {
          setHasMore(false)
        } else {
          setHasMore(true)
        }
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {        
        setLoading(false);
      });  
    } else {
      HaulApi.latestLogs(page, filter)
      .then((apiResponse: any) => {
        setHaulLog(currentHaulLog.concat(apiResponse.data));

        if (apiResponse.data.length == 0 && filter == 0) {
          setHaulLogEmpty(true)
        } else {
          setHaulLogEmpty(false)
        }
        
        if (apiResponse.data.length < 10) {
          setHasMore(false)
        } else {
          setHasMore(true)
        }
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {        
        setLoading(false);
      });  
    }
  }

  useFocusEffect (
    React.useCallback(() => {      
      if (isFocused) {
        init();
      }
    }, [isFocused])
  );

  /*
    <TouchableOpacity onPress={() => Linking.openURL(googlePlayURL)}>
      <Image style={styles.image} source={require('../../assets/images/getongoogle.png')} />
    </TouchableOpacity>
    <TouchableOpacity onPress={() => Linking.openURL(appleAppStoreURL)}>
      <Image style={[styles.image, {marginLeft: 10}]} source={require('../../assets/images/getonapple.png')} />
    </TouchableOpacity>
  */

  return (<View>  
            {loading &&
              <View style={{marginTop: 40}}>
                <ActivityIndicator size="large" color="#3F51B5" />
                <TraxxText style={styles.loadingText}>Loading...</TraxxText>
              </View>
            }
            {!loading && <View>
              <View>   
                {haulLogEmpty && <TraxxText style={styles.noLog}>Your haul log is empty</TraxxText>}                                 
                {getShowAdd() && <Button text='Add Haul' onPress={() => addHaulLog()} />}
              </View> 
              {!haulLogEmpty && <View>
                <TraxxText style={styles.header}>{title}</TraxxText>
                <View style={{flexDirection: 'row'}}>
                  <Button
                    text="All"
                    style={filter == 0 ? styles.buttonOn : styles.buttonOff}
                    onPress={() => { loadHauls(0, 0) }} />
                  <Button
                    text="In Progress"
                    style={filter == 1 ? styles.buttonOn : styles.buttonOff}
                    onPress={() => { loadHauls(0, 1) }} />
                  <Button
                    text="Completed"
                    style={filter == 2 ? styles.buttonOn : styles.buttonOff}
                    onPress={() => { loadHauls(0, 2) }} />
                  </View>
              </View>}
              {!haulLogEmpty && <FlatList
                  keyExtractor={(item: HaulLogEntry, index) => item.id.toString() + index}
                  data={haulLog}
                  renderItem={drawCard}
                  />}

              {hasMore && <Button
                text="Load More"
                onPress={() => loadHauls(page + 1, filter)} />}
            </View>}
          </View>
  )
};

const width = Dimensions.get('window').width;

const styles = StyleSheet.create({ 
  header: {
    marginVertical: 20,
    fontSize: 20,
    fontWeight: 'bold',
  },

  buttonOn: {
    backgroundColor: Colors.primary,
    color: Colors.borderDarkGrey,
    margin: 4,
  },

  buttonOff: {
    backgroundColor: Colors.primaryBrown,
    margin: 4,
  },

  button : {
    margin: 10,
    width: width - 20,
  },

  noLog: {
    textAlign: 'center',
    margin: 20,
    fontSize: 16,
    fontWeight: 'bold',
  },

  card: {
    backgroundColor: Colors.white,
    borderColor: Colors.borderDarkGrey,
    borderRadius: 5,
    borderWidth: 1,
    padding: 10,
    margin: 10,
  }, 

  cardInfoContainer: {
    flexDirection: 'row'
  },

  cardDetails: {

  },

  cardDate: {
    marginVertical: 10,
    marginLeft: 10,
    marginRight: 20,
  },

  cardDetail: {
    marginTop: 10,
    marginBottom: 10,
    paddingLeft: 20,
    borderColor: Colors.borderGrey,
    borderLeftWidth: 1,
  },

  cardDateDay: {
    color: Colors.textGrey,
    fontSize: 16,
    textAlign: 'center',
  },

  cardDateDate: {
    color: Colors.text,
    fontSize: 20,
    fontWeight: 'bold',
    textAlign: 'center',
  },

  cardDateMonth: {
    color: Colors.primary,
    fontSize: 16,
    textAlign: 'center',
  },

  cardDetailPickUp: {
    color: Colors.text,
    fontSize: 20,
    fontWeight: 'bold',
  },

  cardDetailWeight: {
    color: Colors.textGrey,
  },

  cardDetailStatus: {
    alignSelf: 'flex-start',
    marginTop: 10,
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderRadius: 10,
    backgroundColor: Colors.primary,
    color: Colors.white,
  },

  loadingText: {
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold'
  },
});
export default HaulerList