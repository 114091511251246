import React, { useEffect } from 'react'

import {
  View,
  StyleSheet,
  Dimensions,
  ActivityIndicator,
  Text,
  createElement,
  ScrollView,
} from 'react-native';

import {useNavigation} from '@react-navigation/native';
import { useSelector } from 'react-redux';
import Moment from 'react-moment';
import { RootState } from '../../services/store';
import { createAccount, createHaulLogEntry, createOrganization, createProject, createSite, formatDate, getAPIDate } from '../../services/helpers';
import HaulApi from '../../services/modules/haul/api';
import { convertToCubicMeters, convertToTons } from '../../utility/conversion';
import { Organization } from '../../services/types';
import OrganizationSearch from '../organization/OrganizationSearch';
import Button from '../ui/Button';
import SiteSearch from '../site/SiteSearch';
import ProjectSearch from '../project/ProjectSearch';
import Colors from '../../constants/Colors';
import { TraxxText } from '../ui/TraxxText';
import { TraxxTextInput } from '../ui/TraxxTextInput';
import OrganizationApi from '../../services/modules/organization/api';
import { Picker } from '@react-native-picker/picker';
import HaulerSearch from './HaulerSearch';

interface Props {
}

const HaulLogEntryEditorScene: React.FC<Props> = ({ 
}) => {   
  const navigation = useNavigation();

  const haulState = useSelector((state: RootState) => state.haul);
  const authState = useSelector((state: RootState) => state.auth);
  
  const [saved, setSaved] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const [showVehicleModal, setShowVehicleModal] = React.useState(false);
  const [loadingVehicles, setLoadingVehicles] = React.useState(false);
  const [weightNan, setWeightNan] = React.useState(false);
  const [saving, setSaving] = React.useState(false);

  const [haulLogEntry, setHaulLogEntry] = React.useState(createHaulLogEntry());
  const [project, setProject] = React.useState(createProject());
  const [pickUpSite, setPickUpSite] = React.useState(createSite());
  const [dropOffSite, setDropOffSite] = React.useState(createSite());
  const [driver, setDriver] = React.useState(createAccount());

  const [vehicleId, setVehicleId] = React.useState('');
  const [pickUpDate, setPickUpDate] = React.useState("");
  const [pickUpTime, setPickUpTime] = React.useState("");
  const [dropOffDate, setDropOffDate] = React.useState("");
  const [dropOffTime, setDropOffTime] = React.useState("");
  const [weight, setWeight] = React.useState("");
  
  const [vehicles, setVehicles] = React.useState([]);
  
  const loadVehicles = (organizationId: number) => {
    setLoadingVehicles(true)
    OrganizationApi.listVehicles(organizationId)
    .then((apiResponse: any) => {
      setVehicles(apiResponse.data);
      setLoadingVehicles(false);
    })
  }

  const confirmDeposit = () => {
    setSaving(true);
    HaulApi.confirmDeposit(haulLogEntry.id)
    .then(() => {
      setSaving(false);
      
      let haulInfo = {
        ...haulLogEntry,
        dropOffConfirmedName: authState.firstName + " " + authState.lastName,
        dropOffConfirmedDate: new Date()
      } 
      setHaulLogEntry(haulInfo)
    });
  }

  const DatePicker = ({value, onChange} : {value: Date, onChange: any}) => {
    const [date, time] = formatDate(value).split(' ');

    return createElement('input', {
      type: 'date',
      value: date,
      onChange: onChange,
    })
  }

  const TimePicker = ({value, onChange} : {value: Date, onChange: any}) => {
    const [date, time] = formatDate(value).split(' ');

    return createElement('input', {
      type: 'time',
      value: time,
      onChange: onChange,
    })
  }

  const onChangePickUpDate = (selectedDate: any) => {
    setPickUpDate(selectedDate.target.value)
    let storeDate = new Date(selectedDate.target.value + "T" + pickUpTime)
    setHaulLogEntry({
      ...haulLogEntry,
      pickUpDate: storeDate
    });
  };

  const onChangePickUpTime = (selectedDate: any) => {
    setPickUpTime(selectedDate.target.value)
    let storeDate = new Date(pickUpDate + "T" + selectedDate.target.value)
    setHaulLogEntry({
      ...haulLogEntry,
      pickUpDate: storeDate
    });
  };

  const onChangeDropOffDate = (selectedDate: any) => {
    setDropOffDate(selectedDate.target.value)
    let storeDate = new Date(selectedDate.target.value + "T" + dropOffTime)
    setHaulLogEntry({
      ...haulLogEntry,
      dropOffDate: storeDate
    });
  };

  const onChangeDropOffTime = (selectedDate: any) => {
    setDropOffTime(selectedDate.target.value)
    let storeDate = new Date(dropOffDate + "T" + selectedDate.target.value)
    setHaulLogEntry({
      ...haulLogEntry,
      dropOffDate: storeDate
    });
  };

  const saveHaulLogEntry = () => {
    var update = haulLogEntry;

    if (vehicleId.length == 0) {
      alert("Please select a vehicle");
      return;
    }

    update = {
      ...update,
      vehicleId: vehicleId
    }

    if (driver.id.length == 0) {
      alert("Please select a driver");
      return;
    }

    update = {
      ...update,
      accountId: driver.id
    }

    if (project.id == 0) {
      alert("Please select a project");
      return;
    }

    if (update.weight == 0) {
      alert("Please enter the weight of the load.");
      return;
    }

    let storeWeight = update.weight;

    if (update.weightType == 0) {
      storeWeight = convertToCubicMeters(update.weight);
    }

    update = {
      ...update,
      projectId: project.id,
      weight: storeWeight
    }

    if (pickUpSite.id == 0) {
      alert("Please select a pick up site");
      return;
    }

    if (dropOffSite.id == 0) {
      alert("Please select a drop off site");
      return;
    }

    update = {
      ...update,
      dropOffSiteId: dropOffSite.id,
      pickUpSiteId: pickUpSite.id,
      statusId: 2
    };
    
    setSaving(true);
    HaulApi.update(update)
    .then(() => {
      setSaving(false);
      setSaved(true)
      close();
    });
  }

  const changeHaulType = (type: number) => {
    let storeWeight = haulLogEntry.weight;

    if (type == 0) {
      storeWeight = parseFloat(convertToTons(haulLogEntry.weight).toFixed(2));
    } else {
      storeWeight = parseFloat(convertToCubicMeters(haulLogEntry.weight).toFixed(2));
    }
    
    setWeight(storeWeight.toFixed(2))

    setHaulLogEntry({
      ...haulLogEntry,
      weightType: type,
      weight: storeWeight
    });
  }

  const close = (() => {
    navigation.goBack();
  });

  const organizationSelected = (organization: Organization) => {
    setHaulLogEntry({...haulLogEntry, organizationId: organization.id, organization: organization});
  }

  const updateVehicleId = (text: string) => {
    setHaulLogEntry({...haulLogEntry, vehicleId: text});
  }

  useEffect(() => {
    loadVehicles(authState.organizationId);
    
    if (haulState.id > 0) {
      HaulApi.load(haulState.id)
        .then((apiResponse: any) => {   
          const haulEntry = apiResponse.data;

          setProject(haulEntry.project);
          setPickUpSite(haulEntry.pickUpSite);

          if (haulEntry.dropOffSite != null) {
            setDropOffSite(haulEntry.dropOffSite);
          }

          let account = createAccount();

          if (haulEntry.account) {
            account = haulEntry.account;
          }
        
          if (authState.metric == 0) {
            haulEntry.weight = parseFloat(convertToTons(haulEntry.weight).toFixed(2));
            haulEntry.weightType = 0;
          }
                
          if (authState.metric == 1) {
            haulEntry.weight = haulEntry.weight;
            haulEntry.weightType = 1;
          }

          setDriver(account)
          setWeight(haulEntry.weight)

          const [date, time] = formatDate(getAPIDate(haulEntry.pickUpDate)).split(' ');
          setPickUpDate(date)
          setPickUpTime(time)

          if (haulEntry.statusId > 1) {
            const [date, time] = formatDate(getAPIDate(haulEntry.dropOffDate)).split(' ');
            setDropOffDate(date)
            setDropOffTime(time)
          }
          
          setVehicleId(haulEntry.vehicleId)

          setHaulLogEntry({id: haulEntry.id,
            accountId: haulEntry.accountId,
            weight: haulEntry.weight,
            weightType: haulEntry.weightType,
            statusId: haulEntry.statusId,
            createdBy: haulEntry.createdBy,
            dateCreated: haulEntry.dateCreated,
            pickUpDate: getAPIDate(haulEntry.pickUpDate),
            pickUpSiteId: haulEntry.pickUpSiteId,
            pickUpSite: haulEntry.pickUpSite,
            dropOffDate: haulEntry.dropOffDate == null ? new Date() : getAPIDate(haulEntry.dropOffDate),
            dropOffSiteId: haulEntry.dropOffSiteId,
            dropOffSite: haulEntry.dropOffSite,
            projectId: haulEntry.projectId,
            vehicleId: haulEntry.vehicleId,
            organizationId: haulEntry.organizationId,
            project: {id: apiResponse.data.projectId, title: apiResponse.data.project.title},
            organization: apiResponse.data.organization,
            account: account,
            longitude: 0,
            latitude: 0,
            dropOffConfirmedName: haulEntry.dropOffConfirmedName,
            dropOffConfirmedDate: haulEntry.dropOffConfirmedDate
          });

          setLoaded(true)
        })
    } else {
      if (haulState.accountId.length > 0) {
        let account = createAccount();
        account.id = haulState.accountId;
        account.firstName = authState.firstName;
        account.lastName = authState.lastName;

        setDriver(account)

        let organization = createOrganization();
        organization.id = haulState.organizationId;

        if (haulState.organizationId > 0) {
          organization.title = authState.organization;
        }

        let storeDate = new Date();
        storeDate.setHours(8,0,0,0);
        
        const [pDate, pTime] = formatDate(storeDate).split(' ');
        setPickUpDate(pDate)
        setPickUpTime(pTime)

        const [dDate, dTime] = formatDate(storeDate).split(' ');
        setDropOffDate(dDate)
        setDropOffTime(dTime)
        
        setHaulLogEntry({...haulLogEntry, pickUpDate: storeDate, dropOffDate: storeDate, weight: 0, account: account, accountId: account.id, organization: organization, organizationId: organization.id})
      } else {        
        let organization = createOrganization();
        organization.id = authState.organizationId;
        organization.title = authState.organization;
        
        let storeDate = new Date();
        storeDate.setHours(8,0,0,0);
        
        const [pDate, pTime] = formatDate(storeDate).split(' ');
        setPickUpDate(pDate)
        setPickUpTime(pTime)

        const [dDate, dTime] = formatDate(storeDate).split(' ');
        setDropOffDate(dDate)
        setDropOffTime(dTime)
        
        setHaulLogEntry({...haulLogEntry, pickUpDate: storeDate, dropOffDate: storeDate, weight: 0, organization: organization, organizationId: organization.id})
      }

      setLoaded(true)
    }
  }, [haulState])

  /*
  <Button
    style={styles.sectionHeaderButton}
    text="Change"
    onPress={() => setShowVehicleModal(true)} />
  */
    
  return (<ScrollView
            contentInsetAdjustmentBehavior="automatic">    
            {showVehicleModal && <View>
              <TraxxText style={styles.modalText}>Vehicle License Plate</TraxxText>
              <View>
                <TraxxTextInput
                  style={[styles.input, {padding:15, margin: 5}]}
                  placeholder="Enter your license plate number"
                  placeholderTextColor="#003f5c"
                  autoCapitalize="characters"
                  selectTextOnFocus
                  value={haulLogEntry.vehicleId}
                  onChangeText={(vehicleId: string) => updateVehicleId(vehicleId)}
                />
              </View>
              <TraxxText style={styles.modalText}>Hauling Company</TraxxText>
              <View>
                {haulLogEntry.organization.id > 0 && <TraxxText style={[styles.inputText, {marginLeft: 10}]}>{haulLogEntry.organization.title}</TraxxText>}
              </View>
              <View>
                <OrganizationSearch
                  onItemSelected={organizationSelected}
                  showCancel={false}
                  placeholder="Start typing company name" />
              </View>
              <Button style={[styles.button, {marginTop: 40}]} onPress={() => setShowVehicleModal(false)}
                text="OK">
              </Button>
            </View>}

            {!loaded &&
              <View style={{marginTop: 40}}>
                <ActivityIndicator size="large" color="#3F51B5" />
                <TraxxText style={styles.searchingText}>Loading...</TraxxText>
              </View>
            }

            {saving &&
              <View style={{marginTop: 40}}>
                <ActivityIndicator size="large" color="#3F51B5" />
                <TraxxText style={styles.searchingText}>Saving...</TraxxText>
              </View>
            }

            {loaded && !saving && !showVehicleModal && <View style={styles.inputContainer}>
              {authState.role == "Reuse" && <View style={styles.sectionContainer}>
                <View style={styles.sectionHeaderView}>
                  <TraxxText style={styles.sectionHeader}>DEPOSIT CONFIRMATION</TraxxText>
                </View>
                {haulLogEntry.dropOffConfirmedName != undefined && <View style={{marginTop: 10}}>
                  <View style={{flexDirection: "row"}}><TraxxText style={styles.inputLabel}>Confirmed by </TraxxText><TraxxText style={{fontWeight: 'bold', fontSize: 16, marginTop: 10}}>{haulLogEntry.dropOffConfirmedName}</TraxxText></View>
                  <TraxxText style={styles.inputLabel}>On <Moment element={Text} format="D-MMM-yyyy H:mm A" style={styles.inputText}>{haulLogEntry.dropOffConfirmedDate}</Moment></TraxxText>
                </View>}
                {haulLogEntry.dropOffConfirmedName == undefined && <View style={{marginTop: 10}}>
                  <Button text='Confirm Deposit' onPress={() => confirmDeposit()} />
                </View>}
              </View>}
              <View style={styles.sectionContainer}>
                <View style={styles.sectionHeaderView}>
                  <TraxxText style={styles.sectionHeader}>DETAILS</TraxxText>
                </View>
                <TraxxText style={styles.inputLabel}>Weight</TraxxText>
                <View style={styles.inputGroup}>
                  <TraxxTextInput
                    style={[styles.input]}
                    placeholder="Enter load weight"
                    placeholderTextColor="#003f5c"
                    value={weight}
                    keyboardType="number-pad"
                    returnKeyType="next"
                    onChangeText={(value: string) => {
                      let numericValue = 0;

                      setWeight(value)
      
                      if (value.trim().length > 0) {
                        if (isNaN(+value)) {
                          setWeightNan(true)
                        } else {
                          setWeightNan(false)
                          numericValue = parseFloat(value.replace(/[^0-9.]/g, ''));
                        }
                      }

                      setHaulLogEntry({...haulLogEntry, weight: numericValue});
                    }}
                  />
                  <Button
                    style={haulLogEntry.weightType == 0 ? styles.buttonOn : styles.buttonOff}
                    text="Tonne"
                    onPress={() => { changeHaulType(0) }} />
                  <Button
                    style={haulLogEntry.weightType == 1 ? styles.buttonOn : styles.buttonOff}  
                    text="Cu. Meter"
                    onPress={() => { changeHaulType(1) }} />
                </View>
                {weightNan && <TraxxText style={styles.errorLabel}>Warning: weight must be a number</TraxxText>}
                <TraxxText style={styles.inputLabel}>Pick Up Date</TraxxText>
                <View style={styles.inputGroup}>
                  <View style={styles.inputBox}>                    
                    <DatePicker
                      value={haulLogEntry.pickUpDate}
                      onChange={onChangePickUpDate}
                    />
                  </View>
                </View>
                <TraxxText style={styles.inputLabel}>Pick Up Time</TraxxText>
                <View style={styles.inputGroup}>
                  <View style={styles.inputBox}>                    
                    <TimePicker
                      value={haulLogEntry.pickUpDate}
                      onChange={onChangePickUpTime}
                    />
                  </View>
                </View>
                <View>
                  <TraxxText style={styles.inputLabel}>Drop Off Date</TraxxText>
                  <View style={styles.inputGroup}>
                    <View style={styles.inputBox}>
                      <DatePicker
                        value={haulLogEntry.dropOffDate}
                        onChange={onChangeDropOffDate}
                      />
                    </View>
                  </View>
                  <TraxxText style={styles.inputLabel}>Drop Off Time</TraxxText>
                  <View style={styles.inputGroup}>
                    <View style={styles.inputBox}>                    
                      <TimePicker
                        value={haulLogEntry.dropOffDate}
                        onChange={onChangeDropOffTime}
                      />
                    </View>
                  </View>
                </View>
              </View>
              
              <View style={styles.sectionContainer}>
                <View style={styles.sectionHeaderView}>
                  <TraxxText style={styles.sectionHeader}>VEHICLE</TraxxText>
                  {authState.role == "Company" && <Button
                    style={styles.sectionHeaderButton}
                    text="Change"
                    onPress={() => setDriver(createAccount())} />}
                </View>
                <TraxxText style={styles.inputLabel}>Driver</TraxxText>
                {driver.id.length == 0 && <HaulerSearch onItemSelected={(data: any) => { setDriver(data)}} />}
                {driver.id.length > 0 && <View style={styles.inputBox}>
                  <TraxxText style={styles.inputText}>{driver.firstName} {driver.lastName}</TraxxText>
                </View>}
                <TraxxText style={styles.inputLabel}>License Plate</TraxxText>
                <View style={styles.inputBox}>                  
                  {loadingVehicles &&
                    <View style={{marginTop: 40}}>
                      <ActivityIndicator size="large" color="#3F51B5" />
                      <TraxxText style={styles.searchingText}>Loading...</TraxxText>
                    </View>
                  }
                  {vehicles.length == 0 && !loadingVehicles && <TraxxText style={{fontWeight: 'bold', textAlign: 'center'}}>No vehicles registered for this company</TraxxText>}
                  {vehicles.length > 0 && !loadingVehicles && 
                    <Picker
                      selectedValue={vehicleId}
                      onValueChange={(itemValue, itemIndex) =>
                        setVehicleId(itemValue)
                      }>
                        <Picker.Item key={0} label={"Please Select"} value={""} />
                        {
                          vehicles.map((item: any) => {
                            return (<Picker.Item key={item.id} label={item.vehicleId} value={item.vehicleId} />)
                          })
                        }
                    </Picker>
                  }
                </View>
                {haulLogEntry.organizationId != 0 && <TraxxText style={styles.inputLabel}>Hauling Company</TraxxText>}
                {haulLogEntry.organizationId != 0 && <View style={styles.inputBox}>
                  <TraxxText style={styles.inputText}>{haulLogEntry.organization.title}</TraxxText>
                </View>}
              </View>
              <View style={styles.sectionContainer}>
                <View style={styles.sectionHeaderView}>
                  <TraxxText style={styles.sectionHeader}>PROJECT</TraxxText>
                  {project.id != 0 && <Button
                    style={styles.sectionHeaderButton}
                    text="Change"
                    onPress={() => setProject(createProject())} />}
                </View>
                {project.id == 0 && <ProjectSearch
                  onItemSelected={(data: any) => { setProject(data); setPickUpSite(data.site)}}
                  showCancel={false}/>}

                {project.id != 0 && <View>
                  <TraxxText style={styles.inputLabel}>Name</TraxxText>
                  <View style={styles.inputBox}>
                    <TraxxText style={{marginBottom:4, fontWeight:'bold', fontSize: 16}}>{project.title}</TraxxText>
                  </View>
                </View>}
              </View>
              <View style={styles.sectionContainer}>
                <View style={styles.sectionHeaderView}>
                  <TraxxText style={styles.sectionHeader}>PICK UP SITE</TraxxText>
                  {pickUpSite.id != 0 && <Button
                    style={styles.sectionHeaderButton}
                    text="Change"
                    onPress={() => setPickUpSite(createSite())} />}
                </View>
                {pickUpSite.id == 0 && <SiteSearch
                  onItemSelected={(data: any) => { setPickUpSite(data); }}
                  showCancel={false}
                  showAdd={true} />}
                {pickUpSite.id != 0 && <View>
                  <TraxxText style={styles.inputLabel}>Address</TraxxText>
                  <View style={styles.inputBox}>
                    <TraxxText style={styles.inputText}>{pickUpSite.title}</TraxxText>
                    <TraxxText>{pickUpSite.address}</TraxxText>
                    {pickUpSite.addressOptional != null && <TraxxText>{pickUpSite.addressOptional}</TraxxText>}
                    <TraxxText>{pickUpSite.city}</TraxxText>
                    <TraxxText>{pickUpSite.province}, {pickUpSite.postalCode}</TraxxText>
                  </View>
                  <TraxxText style={styles.inputLabel}>Contact</TraxxText>
                  <View style={styles.inputBox}>               
                    {pickUpSite.contactName == null && <TraxxText>No contact specified</TraxxText>}      
                    <TraxxText style={styles.inputText}>{pickUpSite.contactName}</TraxxText>     
                    <TraxxText>{pickUpSite.contactEmail}</TraxxText>     
                    <TraxxText>{pickUpSite.contactPhone}</TraxxText>
                  </View>
                </View>}
              </View>
              <View style={styles.sectionContainer}>
                <View style={styles.sectionHeaderView}>
                  <TraxxText style={styles.sectionHeader}>DROP OFF SITE</TraxxText>
                  <Button
                    style={styles.sectionHeaderButton}
                    text="Change"
                    onPress={() => setDropOffSite(createSite())} />
                </View>                
                {dropOffSite.id == 0 && <SiteSearch
                  onItemSelected={(data: any) => { setDropOffSite(data); }}
                  showCancel={false}
                  showAdd={true} />}
                {dropOffSite.id != 0 && <View>
                  <TraxxText style={styles.inputLabel}>Address</TraxxText>
                  <View style={styles.inputBox}>
                    <TraxxText style={styles.inputText}>{dropOffSite.title}</TraxxText>
                    <TraxxText>{dropOffSite.address}</TraxxText>
                    {dropOffSite.addressOptional != null && <TraxxText>{dropOffSite.addressOptional}</TraxxText>}
                    <TraxxText>{dropOffSite.city}</TraxxText>
                    <TraxxText>{dropOffSite.province}, {dropOffSite.postalCode}</TraxxText>
                  </View>
                  <TraxxText style={styles.inputLabel}>Contact</TraxxText>
                  <View style={styles.inputBox}>        
                    {dropOffSite.contactName == null && <TraxxText>No contact specified</TraxxText>}             
                    <TraxxText style={styles.inputText}>{dropOffSite.contactName}</TraxxText>     
                    <TraxxText>{dropOffSite.contactEmail}</TraxxText>     
                    <TraxxText>{dropOffSite.contactPhone}</TraxxText>
                  </View>
                </View>}
              </View>
                 
              {saved &&
                <View>
                  <TraxxText>Saved!</TraxxText>
                </View>
              }
              
              <Button onPress={saveHaulLogEntry}
                style={styles.button}
                text="Save">
              </Button>

              <Button onPress={close}
                style={styles.button}
                text="Cancel">
              </Button>
            </View>}  
          </ScrollView>
  )
};

const width = Dimensions.get('window').width;
const height = Dimensions.get('window').height;

const styles = StyleSheet.create({
  inputContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    width: width,
  },

  inputView: {
    backgroundColor: "#fff",
    borderRadius: 30,
    width: "70%",
    height: 45,
    marginBottom: 20,
    alignItems: "center",
  },

  input: {
    borderRadius: 5,
    borderWidth: 1,
    borderColor: Colors.borderGrey,
    backgroundColor: Colors.white,
    fontSize: 16,
    paddingHorizontal: 10,
    fontWeight: 'bold',
  },

  inputGroup: {
    flexDirection: 'row',
    marginLeft: 5,
  },

  inputBox: {
    borderRadius: 5,
    borderWidth: 1,
    borderColor: Colors.borderGrey,    
    backgroundColor: Colors.white,
    fontSize: 16,
    padding: 10,
  },

  inputText: {
    fontSize: 16,
    fontWeight: 'bold',
  },

  inputLabel: {
    fontSize: 16,
    marginTop: 10,
    marginLeft: 5,
  },

  errorLabel: {
    fontWeight: 'bold',
    color: Colors.red,
    fontSize: 16,
    marginTop: 10,
    marginLeft: 5,
  },

  searchingText: {
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold'
  },

  comingSoon: {
    fontSize: 20,
    color: "#fff"
  },

  button : {
    width: width - 20,
    margin: 10,
  },
  buttonOn: {
    backgroundColor: Colors.primary,
    color: Colors.borderDarkGrey,
    margin: 4,
  },
  buttonOff: {
    backgroundColor: Colors.primaryBrown,
    margin: 4,
  },
  sectionContainer: {
    width: width - 20,
    margin: 10
  },
  sectionHeader: {
    fontWeight: 'bold',
    fontSize: 20,
    flex: 1,
    marginHorizontal: 4,
    marginTop: 8,
  },
  sectionHeaderView: {
    flexDirection: 'row',
  },
  sectionHeaderButton : {
    margin: 4,
  },
  modalView: {

  },
  modalText: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 20,
    margin: 20,
  },
  
  map: {
    height: 200
  },
});
export default HaulLogEntryEditorScene