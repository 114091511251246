import { HaulDispatchTypes, HaulState, SELECT_ENTRY, ADD_ENTRY, STARTED_TRACKING, STOPPED_TRACKING, USE_LOCATION } from "./types";

export const initialState: HaulState = { id: 0, weight: 0, weightType: 0, vehicleId: '', watchId: null, accountId: '', organizationId: 0 };

export const haulReducer = (
  state: HaulState = initialState,
  action: HaulDispatchTypes
) => {
  switch (action.type){
    case STARTED_TRACKING:
      return {
        ...state,
        watchId: action.payload
      }
    case STOPPED_TRACKING:
      return {
        ...state,
        watchId: null
      }
    case SELECT_ENTRY:
      return {
        ...state,
        id: action.payload
      }
    case ADD_ENTRY:
      return {
        ...state,
        id: 0,
        accountId: action.payload.accountId,
        organizationId: action.payload.organizationId
      }
    case USE_LOCATION:
      return {
        ...state,
        id: action.payload.id,
        siteId: action.payload.siteId,
        projectId: action.payload.projectId,
        projectTitle: action.payload.projectTitle,
        address: action.payload.address,
        city: action.payload.city,
        province: action.payload.province,
        postalCode: action.payload.postalCode,
        longitude: action.payload.longitude,
        latitude: action.payload.latitude,
      }
    default:
        return state;
  }
}