import React from 'react'

import {
  useColorScheme,
  View,
  StyleSheet,
  Dimensions,
  TouchableOpacity,
  ActivityIndicator,
  Alert,
  Modal,
} from 'react-native';

import {useFocusEffect, useIsFocused, useNavigation} from '@react-navigation/native';
import { Text } from 'react-native';
import { FlatList } from 'react-native-gesture-handler';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../ui/Button';
import { HaulLogEntry } from '../../services/types';
import HaulApi from '../../services/modules/haul/api';
import Moment from 'react-moment';
import { useEffect } from 'react';
import Colors from '../../constants/Colors';
import { RootState } from '../../services/store';
import { convertToCubicMeters, convertToTons } from '../../utility/conversion';
import { selectEntry } from '../../services/modules/haul/actions';
import { TraxxText } from '../ui/TraxxText';

interface Props {
}

const ReuseList: React.FC<Props> = ({ 
}) => {   
  const dispatch = useDispatch();
  
  const navigation = useNavigation();

  const authState = useSelector((state: RootState) => state.auth);

  const isDarkMode = useColorScheme() === 'dark';
  const isFocused = useIsFocused()

  const [haulLog, setHaulLog] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [hasMore, setHasMore] = React.useState(false);
  const [haulInProgress, setHaulInProgress] = React.useState(false);
  const [showUpdateModal, setShowUpdateModal] = React.useState(false);
  const [weightUnits, setWeightUnits] = React.useState('');

  const [page, setPage] = React.useState(-1)

  const openHaulEntry = (entry: HaulLogEntry) => {
    dispatch(selectEntry(entry.id));
    navigation.navigate("HaulLogEntryViewer");
  };
  
  const drawCard = (({item} : {item: any}) => {
    return (<TouchableOpacity onPress={() => openHaulEntry(item)} key={item.id.toString()}>
              <View style={styles.card}>
                <View style={styles.cardInfoContainer}>
                  <View style={styles.cardDate}>
                    <Moment element={Text} format="ddd" style={styles.cardDateDay}>{item.pickUpDate}</Moment>
                    <Moment element={Text} format="D" style={styles.cardDateDate}>{item.pickUpDate}</Moment>
                    <Moment element={Text} format="MMM" style={styles.cardDateMonth}>{item.pickUpDate}</Moment>
                  </View>
                  <View style={styles.cardDetail}>
                    <TraxxText style={styles.cardDetailPickUp}>{item.pickUpSite.title}</TraxxText>
                    <TraxxText style={styles.cardDetailWeight}>{authState.metric == 0 ? convertToTons(item.weight).toFixed(2): item.weight.toFixed(2)} {weightUnits}</TraxxText>
                    <View style={{flexDirection: 'row'}}>
                      <TraxxText style={styles.cardDetailStatus}>{item.status}</TraxxText>
                      {item.dropOffConfirmedId == null && <TraxxText style={styles.cardDetailStatusPending}>Unconfirmed</TraxxText>}
                    </View>
                  </View>
                </View>
              </View>
            </TouchableOpacity>)
  })

  const showMap = (() => {
    navigation.navigate("ReuseMap");
  });

  const init = () => {
    if (authState.metric == 0) {
      setWeightUnits("tonnes");
    } else {
      setWeightUnits("cu. meters");
    }

    setPage(0)
  }

  const loadHauls = () => {  
    HaulApi.reuseLogs(page)
    .then((apiResponse: any) => {
      setHaulLog(haulLog.concat(apiResponse.data));

      if (apiResponse.data.length < 10) {
        setHasMore(false)
      } else {
        setHasMore(true)
      }

      setLoading(false);
    })
    .catch(error => {
      console.log(error)
    });
  }

  useFocusEffect (
    React.useCallback(() => {      
      if (isFocused) {
        setLoading(true);
        init();
      }
    }, [isFocused])
  );

  useEffect(() => {
    if (page > -1) {
      loadHauls();
    }
  }, [page]);

  return (<View style={styles.componentContainer}>   
            {loading &&
              <View style={{marginTop: 40}}>
                <ActivityIndicator size="large" color="#3F51B5" />
                <TraxxText style={styles.loadingText}>Loading...</TraxxText>
              </View>
            }
            {!loading && 
              <View style={styles.sectionContainer}>
                <View style={styles.sectionHeaderView}>
                  <TraxxText style={styles.sectionHeader}>HAUL LOGS</TraxxText>
                </View>
                <View> 
                  {haulLog.length == 0 && <TraxxText style={styles.noLog}>Your haul log is empty</TraxxText>}                                    
                  {haulLog.length > 0 && <FlatList
                      keyExtractor={(item: HaulLogEntry, index) => item.id.toString() + index}
                      data={haulLog}
                      renderItem={drawCard}
                      />}

                  {hasMore && <Button
                    text="Load More"
                    onPress={() => setPage(page + 1)} />}
                </View>
            </View>}
          </View>
  )
};

const width = Dimensions.get('window').width;

const styles = StyleSheet.create({ 
  button : {
    margin: 10,
  },

  noLog: {
    textAlign: 'center',
    margin: 20,
    fontSize: 20,
    fontWeight: 'bold',
  },
  componentContainer: {   
    backgroundColor: Colors.white, 
    padding: "1%",
    borderColor: Colors.borderGrey,
    borderWidth: 1,
    borderRadius: 10,
    width: "100%"
  },

  sectionContainer: {
    width: "100%",
  },
  sectionHeader: {
    fontWeight: 'bold',
    fontSize: 20,
    flex: 1,
    margin: 4,
  },
  sectionHeaderButton : {
    margin: 4,
  },
  sectionHeaderView: {
    flexDirection: 'row',
  },

  card: {
    backgroundColor: Colors.white,
    borderColor: Colors.borderDarkGrey,
    borderRadius: 5,
    borderWidth: 1,
    padding: 10,
    margin: 10,
  }, 

  cardInfoContainer: {
    flexDirection: 'row'
  },

  cardDetails: {

  },

  cardDate: {
    marginVertical: 10,
    marginLeft: 10,
    marginRight: 20,
  },

  cardDetail: {
    marginTop: 10,
    marginBottom: 10,
    paddingLeft: 20,
    borderColor: Colors.borderGrey,
    borderLeftWidth: 1,
  },

  cardDateDay: {
    color: Colors.textGrey,
    fontSize: 16,
    textAlign: 'center',
  },

  cardDateDate: {
    color: Colors.text,
    fontSize: 20,
    fontWeight: 'bold',
    textAlign: 'center',
  },

  cardDateMonth: {
    color: Colors.primary,
    fontSize: 16,
    textAlign: 'center',
  },

  cardDetailPickUp: {
    color: Colors.text,
    fontSize: 20,
    fontWeight: 'bold',
  },

  cardDetailWeight: {
    color: Colors.textGrey,
  },

  cardDetailStatus: {
    alignSelf: 'flex-start',
    marginTop: 10,
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderRadius: 10,
    backgroundColor: Colors.primary,
    color: Colors.white,
  },

  cardDetailStatusPending: {
    alignSelf: 'flex-start',
    marginTop: 10,
    marginLeft: 10,
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderRadius: 10,
    backgroundColor: Colors.red,
    color: Colors.white,
  },

  loadingText: {
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold'
  },
});
export default ReuseList