/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import { LinkingOptions } from '@react-navigation/native';
import * as Linking from 'expo-linking';

import { RootStackParamList } from './types';

const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [Linking.makeUrl('/')],
  config: {
    screens: {
      Project: 'Project',
      ProjectMap: 'ProjectMap',
      ReuseMap: 'ReuseMap',
      ReuseSites: 'ReuseSites',
      CompanyMap: 'CompanyMap',
      CompanyDashboard: 'CompanyDashboard',
      HaulerInfo: 'HaulerInfo',
      HaulLogEntryViewer: 'HaulLogEntryViewer',
      HaulLogEntryEditor: 'HaulLogEntryEditor',
      ReportHaulLogProject: 'ReportHaulLogProject',
      ReportHaulLogReuse: 'ReportHaulLogReuse',
      ReportHaulLogCompany: 'ReportHaulLogCompany',
      Dashboard: 'Dashboard',
      Preferences: 'Preferences',
      Vehicles: 'Vehicles',
      Subscribe: 'Subscribe',
      SignUp: 'SignUp',
      Login: {
        screens: {
          Login: 'Login',
          ForgotPassword: 'ForgotPassword',
        },
      }, 
    },
  },
};

export default linking;
