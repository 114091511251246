import React from 'react'

import {
  View,
  StyleSheet,
  Dimensions,
} from 'react-native';

import { Text } from 'react-native';
import { useSelector } from 'react-redux';
import Colors from '../../constants/Colors';
import { RootState } from '../../services/store';
import { TraxxText } from '../ui/TraxxText';

interface Props {
}

const HaulerInfo: React.FC<Props> = ({ 
}) => {   
  const accountState = useSelector((state: RootState) => state.account);

  const [loading, setLoading] = React.useState(true);
  
  const init = () => {
    setLoading(true)
  }
  
  return (<View style={styles.componentContainer}>  
            <View style={styles.sectionContainer}>
              <View style={styles.sectionHeaderView}>
                <TraxxText style={styles.sectionHeader}>{accountState.firstName} {accountState.lastName}</TraxxText>
              </View>                  
              <TraxxText style={[styles.subHeader, {marginTop: 10}]}>Vehicle</TraxxText>
              <TraxxText style={styles.subText}>{accountState.vehicleId}</TraxxText>
              <TraxxText style={[styles.subHeader, {marginTop: 10}]}>Email</TraxxText>
              <TraxxText style={styles.subText}>{accountState.email}</TraxxText>
            </View>
          </View>
  )
};

const styles = StyleSheet.create({ 
  componentContainer: {   
    width: "100%",
  },
  sectionContainer: {
    width: "100%",
    margin: 10
  },
  sectionHeader: {
    fontWeight: 'bold',
    fontSize: 22,
    flex: 1,
    margin: 4,
  },
  sectionHeaderView: {
    flexDirection: 'row',
    width: "96%"
  },
  loadingText: {
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold'
  },
  subHeader: {
    fontWeight: 'bold',
    fontSize: 18,
    flex: 1,
  },
  subText: {
    fontSize: 16
  },
  map: {
    backgroundColor: Colors.white, 
    width: "70%",    
    height: 600,
    borderWidth: 1, 
    borderRadius: 10, 
    borderColor: Colors.borderGrey,
    padding: 10,
  },
  panel: {
    backgroundColor: Colors.white, 
    width: "28%",
    marginHorizontal: "1%",
    borderWidth: 1, 
    borderRadius: 10, 
    borderColor: Colors.borderGrey,
    padding: 10,
  },

  heading: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  
  card: {
    borderColor: Colors.borderDarkGrey,
    borderRadius: 5,
    borderWidth: 1,
    padding: 10,
    marginTop: 10,
  }, 

  cardInfoContainer: {
  },

  cardTitle: {
    color: Colors.text,
    fontSize: 20,
    fontWeight: 'bold',
  },

  cardSubTitle: {
    color: Colors.text,
    fontSize: 14,
  },
});
export default HaulerInfo