import React from 'react'

import {
  useColorScheme,
  View,
  StyleSheet,
  Dimensions,
  TouchableOpacity,
  ActivityIndicator,
  Modal,
} from 'react-native';

import {useFocusEffect, useIsFocused, useNavigation} from '@react-navigation/native';
import { Text } from 'react-native';
import { FlatList, TextInput } from 'react-native-gesture-handler';
import { useDispatch, useSelector } from 'react-redux';
import { Project, Site } from '../../services/types';
import { AxiosResponse } from 'axios';
import ProjectApi from '../../services/modules/project/api';
import { selectProject } from '../../services/modules/project/actions';
import Button from '../ui/Button';
import Animated from 'react-native-reanimated';
import Colors from '../../constants/Colors';
import PlacesSearchBar from '../location/PlacesSearchBar';
import { TraxxText } from '../ui/TraxxText';
import { TraxxTextInput } from '../ui/TraxxTextInput';
import { RootState } from '../../services/store';

interface Props {
}

const ProjectList: React.FC<Props> = ({ 
}) => {   
  const authState = useSelector((state: RootState) => state.auth)
  
  const dispatch = useDispatch();

  const navigation = useNavigation();

  const isFocused = useIsFocused()

  const [projects, setProjects] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [saving, setSaving] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [newProjectTitle, setNewProjectTitle] = React.useState("");
  const [newProjectAddress, setNewProjectAddress] = React.useState("");
  const [newProjectPostalCode, setNewProjectPostalCode] = React.useState("");
  const [newProjectProvince, setNewProjectProvince] = React.useState("");
  const [newProjectCity, setNewProjectCity] = React.useState("");
  
  const openProject = (entry: Project, accessLevel: number) => {
    dispatch(selectProject(entry, accessLevel));
    navigation.navigate("Project");
  };

  const addNewProject = () => {
    setNewProjectTitle("")
    setNewProjectAddress("")
    setNewProjectCity("")
    setNewProjectProvince("")
    setNewProjectPostalCode("")
    setShowModal(true)
  };

  const saveNewProject = () => {
    if (newProjectTitle.trim().length == 0 || newProjectAddress.trim().length == 0 || newProjectCity.trim().length == 0 || newProjectProvince.trim().length == 0 || newProjectPostalCode.trim().length == 0) {
      alert("Please enter a project title and address.");
      return;
    }

    setSaving(true);
    ProjectApi.save(newProjectTitle, newProjectAddress, newProjectCity, newProjectProvince, newProjectPostalCode)
    .then((apiResponse: any) => {
      setSaving(false);
      setShowModal(false);
      dispatch(selectProject(apiResponse.data.project, apiResponse.data.accessLevel));
      navigation.navigate("Project");
    })
  }

  const init = () => {
    setLoading(true);
    ProjectApi.my()
    .then((apiResponse: any) => {
      if (apiResponse.data.length > 0) {
        setProjects(apiResponse.data);
      }
      setLoading(false);
    });
  }

  const drawCard = (({item} : {item: any}) => {
    let capacity =  (item.volume / item.project.targetVolume) * 100;

    if (item.project.targetVolume == 0) {
      capacity = 100;
    }
    
    return (
      <TouchableOpacity onPress={() => openProject(item.project, item.accessLevel)}>
        <View style={styles.card}>
          <View style={styles.cardHeaderContainer}>
            <TraxxText style={[styles.cardHeader, {flex: 1}]}>{item.project.title}</TraxxText>
            {item.project.complete && <TraxxText style={styles.completeChip}>Complete</TraxxText>}
            {!item.project.complete && <TraxxText style={styles.incompleteChip}>In Progress</TraxxText>}
          </View>
          <View style={styles.cardInfoContainer}>
            <View style={styles.progressBar}>
              {capacity >= 80 && item.project.targetVolume > 0 && <Animated.View style={[StyleSheet.absoluteFill, {backgroundColor: Colors.red, height: 31, borderRadius: 10, width: capacity + "%"}]}/>}
              {capacity < 80 && item.project.targetVolume > 0 && <Animated.View style={[StyleSheet.absoluteFill, {backgroundColor: Colors.green, height: 31, borderRadius: 10, width: capacity + "%"}]}/>}
              {capacity >= 80 && item.project.targetVolume > 0 && <TraxxText style={[styles.cardDetail, {color: Colors.white}]}>{item.volume} of {item.project.targetVolume} Cu. Meters ({Math.round(capacity)}%)</TraxxText>}
              {capacity < 80 && item.project.targetVolume > 0 && <TraxxText style={styles.cardDetail}>{item.volume} of {item.project.targetVolume} Cu. Meters ({Math.round(capacity)}%)</TraxxText>}
              {item.project.targetVolume == 0 && <TraxxText style={styles.cardDetail}>{item.volume} Cu. Meters (no target volume set)</TraxxText>}
            </View>            
          </View>
        </View>
      </TouchableOpacity>)
  })

  useFocusEffect (
    React.useCallback(() => {      
      if (isFocused) {
        init();
      }
    }, [isFocused])
  );

  return (<View
            style={styles.componentContainer}>    
            {showModal && <Modal
              style={styles.modalView}
              animationType="slide"
              transparent={false}
              visible={showModal}
              onRequestClose={() => {
                setShowModal(!showModal);
              }}
            >
              {!saving && <View>
                <TraxxText style={styles.modalText}>New Project</TraxxText>    
                <TraxxText style={styles.inputLabel}>Project Title</TraxxText>
                <View style={styles.inputGroup}>
                  <TraxxTextInput
                    style={styles.input}
                    placeholder="Project title"
                    placeholderTextColor="#003f5c"
                    value={newProjectTitle}
                    onChangeText={(newProjectTitle: string) => {
                      setNewProjectTitle(newProjectTitle);
                    }}
                  />
                </View>  
                <TraxxText style={styles.inputLabel}>Street</TraxxText>
                <View style={styles.inputGroup}>
                  <TraxxTextInput
                    style={styles.input}
                    placeholder="Address"
                    placeholderTextColor="#003f5c"
                    value={newProjectAddress}
                    onChangeText={(newProjectAddress: string) => {
                      setNewProjectAddress(newProjectAddress);
                    }}
                  />
                </View>  
                <TraxxText style={styles.inputLabel}>City</TraxxText>
                <View style={styles.inputGroup}>
                  <TraxxTextInput
                    style={styles.input}
                    placeholder="City"
                    placeholderTextColor="#003f5c"
                    value={newProjectCity}
                    onChangeText={(newProjectCity: string) => {
                      setNewProjectCity(newProjectCity);
                    }}
                  />
                </View>
                <TraxxText style={styles.inputLabel}>Province</TraxxText>
                <View style={styles.inputGroup}>
                  <TraxxTextInput
                    style={styles.input}
                    placeholder="Province"
                    placeholderTextColor="#003f5c"
                    value={newProjectProvince}
                    onChangeText={(newProjectProvince: string) => {
                      setNewProjectProvince(newProjectProvince);
                    }}
                  />
                </View>
                <TraxxText style={styles.inputLabel}>Postal Code</TraxxText>
                <View style={styles.inputGroup}>
                  <TraxxTextInput
                    style={styles.input}
                    placeholder="Postal code"
                    placeholderTextColor="#003f5c"
                    value={newProjectPostalCode}
                    onChangeText={(newProjectPostalCode: string) => {
                      setNewProjectPostalCode(newProjectPostalCode);
                    }}
                  />
                </View>
                <Button style={styles.entryButton} onPress={() => saveNewProject()}
                  text="Save">
                </Button>
                <Button style={styles.entryButton} onPress={() => setShowModal(!showModal)}
                  text="Cancel">
                </Button>
              </View>}
            </Modal>}       
            {loading &&
              <View style={{marginTop: 40}}>
                <ActivityIndicator size="large" color="#3F51B5" />
                <TraxxText style={styles.loadingText}>Loading...</TraxxText>
              </View>
            }  
            {saving &&
              <View style={{marginTop: 40}}>
                <ActivityIndicator size="large" color="#3F51B5" />
                <TraxxText style={styles.loadingText}>Saving...</TraxxText>
              </View>
            }
            {!loading && !saving && !showModal &&        
              <View style={styles.sectionContainer}>
                <View style={styles.sectionHeaderView}>
                  <TraxxText style={styles.sectionHeader}>MY PROJECTS</TraxxText>
                  {authState.subscriptionId < 4 && <Button
                    style={styles.sectionHeaderButton}
                    text="Add"
                    onPress={addNewProject} />}
                </View>
                <View>
                  {projects.length == 0 && <TraxxText style={styles.noLog}>You do not belong to any projects</TraxxText>} 
                  {projects.length > 0 && <FlatList
                    keyExtractor={(item: any, index) => item.project.id.toString() + index}
                    data={projects}
                    renderItem={drawCard}
                    />}
                </View>
              </View>}
          </View>
  )
};

const styles = StyleSheet.create({ 
  modalView: {
    width: "98%",
    margin: "1%",
    backgroundColor: Colors.white,
    borderColor: Colors.white
  },
  modalText: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 20,
    margin: 20,
  },
  componentContainer: {   
    backgroundColor: Colors.white, 
    padding: "1%",
    borderColor: Colors.borderGrey,
    borderWidth: 1,
    borderRadius: 10,
    width: "100%"
  },
  entryButton: {
    width: "100%",
    marginLeft: 10,
    marginTop: 10,
  },

  inputLabel: {
    fontWeight: 'bold',
    fontSize: 16,
    marginTop: 10,
    marginLeft: 15,
  },

  inputGroup: {
    flexDirection: 'row',
    marginLeft: 5,
  },
  input: {
    height: 50,
    width: "100%",
    paddingVertical: 10,
    paddingHorizontal: 20,
    margin: 10,
    flex: 1,
    borderColor: Colors.borderGrey,
    borderWidth: 1,
    borderRadius: 20
  },

  noLog: {
    marginTop: 10,
    marginLeft: 5,
    fontSize: 20,
  },

  card: {
    padding: 10,
    margin: 10,
  }, 

  cardHeaderContainer: {
    marginBottom: 3,
    flexDirection: 'row'
  },

  cardHeader: {
    color: Colors.text,
    fontSize: 20,
    fontWeight: 'bold',
  },

  cardInfoContainer: {
  },

  cardDetail: {
    color: Colors.text,
    fontSize: 14,
    zIndex: 10,
    fontWeight: 'bold',
  },

  completeChip: {
    backgroundColor: Colors.primaryBrown,
    borderColor: Colors.primaryBrown,
    borderRadius: 20,
    borderWidth: 1,
    paddingHorizontal: 10,
    paddingVertical: 5,
    marginLeft: 10,
    color: Colors.white,
    fontWeight: 'bold',
  },

  incompleteChip: {
    backgroundColor: Colors.primaryBrown,
    borderColor: Colors.primaryBrown,
    borderRadius: 20,
    borderWidth: 1,
    paddingHorizontal: 10,
    paddingVertical: 5,
    marginLeft: 10,
    color: Colors.white,
    fontWeight: 'bold',
  },

  loadingText: {
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold'
  },
  sectionContainer: {
    width: "100%",
  },
  sectionHeader: {
    fontWeight: 'bold',
    fontSize: 20,
    flex: 1,
    margin: 4,
  },
  sectionHeaderButton : {
    margin: 4,
  },
  sectionHeaderView: {
    flexDirection: 'row',
  },
  progressBar: {
    height: 33,
    flexDirection: "row",
    width: '100%',
    backgroundColor: 'white',
    borderColor: '#000',
    borderWidth: 1,
    borderRadius: 10,
    padding: 5,
  },
});
export default ProjectList