import { AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { LOGIN_SUCCESS, LOGIN_FAILURE, LOGIN_LOADING, AuthDispatchTypes, REFRESH_TOKEN, LOG_OUT, UPDATE_ACCOUNT, UPDATE_VEHICLE, UPDATE_PREFERENCES, AUTHENTICATION_ERROR, UPDATE_SUBSCRIBED, UPDATE_ORGANIZATION, UPDATE_FIRSTTIME, UPDATE_FIRSTTIMETRUCKS } from './types';
import AuthApi from './api'
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Account } from '../../types';

export const loginSuccess = (account: Account) => async (dispatch: Dispatch<AuthDispatchTypes>) => {
  dispatch({type: LOGIN_SUCCESS, payload: account});
}

export const loginFailure = () => async (dispatch: Dispatch<AuthDispatchTypes>) => {
  dispatch({type: LOGIN_FAILURE});
}

export const authenticationError = (errorMessage: string) => async (dispatch: Dispatch<AuthDispatchTypes>) => {
  dispatch({type: AUTHENTICATION_ERROR, payload: errorMessage});
}

export const updateAccount = (firstName: string, lastName: string) => async (dispatch: Dispatch<AuthDispatchTypes>) => {
  dispatch({type: UPDATE_ACCOUNT, payload: {firstName: firstName, lastName:lastName}});
}

export const updateOrganization = (id: number, title: string, subscribed: boolean, subscriptionId: number) => async (dispatch: Dispatch<AuthDispatchTypes>) => {
  dispatch({type: UPDATE_ORGANIZATION, payload: {id: id, title:title, subscribed:subscribed, subscriptionId: subscriptionId}});
}

export const autoLogin = (id: string, firstName: string, lastName: string, token: string, refreshToken: string, vehicleId: string, role: string, metric: number, organizationId: number, organization: string, subscribed: boolean, subscriptionOwner: boolean, subscriptionId: number) => async (dispatch: Dispatch<AuthDispatchTypes>) => {
  dispatch({type: LOGIN_SUCCESS, payload: {id: id, firstName: firstName, lastName:lastName, token: token, refreshToken: refreshToken, vehicleId: vehicleId, email: '', password: '', profilePhoto: '', role: role, metric: metric, organizationId: organizationId, organization: organization, subscribed: subscribed, subscriptionOwner, subscriptionId: subscriptionId}});
}

export const refresh = (token: string, refreshToken: string) => async (dispatch: Dispatch<AuthDispatchTypes>) => {
  dispatch({type: REFRESH_TOKEN, payload: {token: token, refreshToken: refreshToken}});
}

export const updateVehicle = (vehicleId: string) => async (dispatch: Dispatch<AuthDispatchTypes>) => {
  dispatch({type: UPDATE_VEHICLE, payload: vehicleId});
}

export const updatePreferences = (metric: number) => async (dispatch: Dispatch<AuthDispatchTypes>) => {
  dispatch({type: UPDATE_PREFERENCES, payload: metric});
}

export const updateSubscribed = (subscribed: boolean, subscriptionOwner: boolean, subscriptionId: number) => async (dispatch: Dispatch<AuthDispatchTypes>) => {
  dispatch({type: UPDATE_SUBSCRIBED, payload: { subscribed: subscribed, subscriptionOwner: subscriptionOwner, subscriptionId: subscriptionId }});
}

export const updateFirstTime = (firstTime: boolean) => async (dispatch: Dispatch<AuthDispatchTypes>) => {
  dispatch({type: UPDATE_FIRSTTIME, payload: firstTime});
}

export const updateFirstTimeTrucks = (firstTimeTrucks: boolean) => async (dispatch: Dispatch<AuthDispatchTypes>) => {
  dispatch({type: UPDATE_FIRSTTIMETRUCKS, payload: firstTimeTrucks});
}

export const logout = () => async (dispatch: Dispatch<AuthDispatchTypes>) => {
  await logOut();
  dispatch({type: LOG_OUT, payload: {id: '', firstName: '', lastName:'', token: '', refreshToken: '', email: '', password: '', profilePhoto: '', organizationId: 0, organization: ''}});
}

const logOut = (async() => {
  try {
    await AsyncStorage.removeItem('user.id');
    await AsyncStorage.removeItem('user.firstName');
    await AsyncStorage.removeItem('user.lastName');
    await AsyncStorage.removeItem('user.role');
    await AsyncStorage.removeItem('user.token');
    await AsyncStorage.removeItem('user.refreshToken'); 
    await AsyncStorage.removeItem('user.vehicleId');   
    await AsyncStorage.removeItem('user.metric');    
    await AsyncStorage.removeItem('user.organization'); 
    await AsyncStorage.removeItem('user.organizationId'); 
    await AsyncStorage.removeItem('user.subscribed');      
    await AsyncStorage.removeItem('user.subscriptionOwner'); 
    await AsyncStorage.removeItem('user.subscriptionId');      
  } catch(error) {
  }
});
