import React, { useEffect } from 'react'

import {
  View,
  StyleSheet,
  Dimensions,
  ActivityIndicator,
  Text,
} from 'react-native';

import Button from '../components/ui/Button'
import {useNavigation} from '@react-navigation/native';
import { ScrollView, TextInput } from 'react-native-gesture-handler';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../services/store';
import { logout, updateOrganization, updatePreferences } from '../services/modules/auth/actions';
import AuthApi from '../services/modules/auth/api';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Colors from '../constants/Colors';
import { TraxxText } from '../components/ui/TraxxText';
import { TraxxTextInput } from '../components/ui/TraxxTextInput';
import AccountApi from '../services/modules/account/api';
import PlacesSearchBar from '../components/location/PlacesSearchBar';
import { Site } from '../services/types';
import PaymentApi from '../services/modules/payment/api';

interface Props {
}

const PreferencesScene: React.FC<Props> = ({ 
}) => {   
  const dispatch = useDispatch();

  const navigation = useNavigation();

  const authState = useSelector((state: RootState) => state.auth);

  const [metric, setMetric] = React.useState(0);
  const [saving, setSaving] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  const [email, setEmail] = React.useState('');
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [organizationName, setOrganizationName] = React.useState('');  
  const [organizationAddress, setOrganizationAddress] = React.useState("");
  const [organizationCity, setOrganizationCity] = React.useState("");
  const [organizationProvince, setOrganizationProvince] = React.useState("");
  const [organizationPostalCode, setOrganizationPostalCode] = React.useState("");
  const [contactPhone, setContactPhone] = React.useState('');
  const [contactEmail, setContactEmail] = React.useState('');
  const [contactName, setContactName] = React.useState('');
  const [subscriptionTitle, setSubscriptionTitle] = React.useState('');
  const [subscribed, setSubscribed] = React.useState(false);

  const placeSelected = (site: Site) => {
    setOrganizationAddress(site.address);
    setOrganizationPostalCode(site.postalCode);
    setOrganizationProvince(site.province);
    setOrganizationCity(site.city);
  }

  const cancelSubscription = () => {
    if (confirm("You will no longer be able to access you data, are you sure?")) {
      setSaving(true);
      PaymentApi.cancelSubscription()
      .then(() => {
        dispatch(logout());
      });
    }
  }

  const savePreferences = () => {
    setSaving(true);
    AuthApi.updatePreferences(metric)
    .then(async () => {
      await AsyncStorage.setItem(
        'user.metric',
        metric.toString()
      ); 
 
      if (authState) {
        dispatch(updatePreferences(metric))
      }

      if (firstName.trim().length == 0 || lastName.trim().length == 0 || email.trim().length == 0) {
        alert("Please fill in all fields");  
        return;
      }

      if (authState.role == "Reuse" || authState.role == "Company") {
        if (organizationName.trim().length == 0 || organizationAddress.trim().length == 0 || organizationCity.trim().length == 0 || organizationProvince.trim().length == 0 || organizationPostalCode.trim().length == 0) {
          alert("Please fill in all fields.");    
          return;
        }

        dispatch(updateOrganization(authState.organizationId, organizationName, authState.subscribed, authState.subscriptionId))
      }
      
      if (authState.role == "Reuse") {
        if (contactName.trim().length == 0 || contactPhone.trim().length == 0) {
          alert("Please fill in all required fields.");    
          return;
        }
      }

      AccountApi.updateFull(firstName, lastName, email, organizationName, organizationAddress, organizationCity, organizationProvince, organizationPostalCode, contactName, contactPhone, contactEmail)
      .then((apiResponse: any) => {
        close();
      })
      .catch((error: any) => {
        alert(error.data.message);
      })
      .finally(() => {
        setSaving(false)
      })
    });
  }

  const close = (() => {
    navigation.goBack();
  });

  const updateMetric = (selectedIndex: number) => {
    setMetric(selectedIndex);
  }

  useEffect(() => {
    if (authState) {
      setMetric(authState.metric);

      AccountApi.load()
      .then((apiResponse: any) => {
        setFirstName(apiResponse.data.firstName);
        setLastName(apiResponse.data.lastName);
        setEmail(apiResponse.data.userName);
        setOrganizationName(apiResponse.data.organizationName);
        setOrganizationAddress(apiResponse.data.address);
        setOrganizationCity(apiResponse.data.city);
        setOrganizationProvince(apiResponse.data.province);
        setOrganizationPostalCode(apiResponse.data.postalCode);
        setContactName(apiResponse.data.contactName);
        setContactPhone(apiResponse.data.contactPhone);
        setContactEmail(apiResponse.data.contactEmail);
        setSubscriptionTitle(apiResponse.data.subscriptionTitle);
        setSubscribed(apiResponse.data.subscribed);
        setLoading(false);
      })
    }
  }, [authState])

  /*
    <PlacesSearchBar
      onPlaceSelected={placeSelected}
      placeholder="Quick find (start typing address or place)"
    />
  */

  return (<ScrollView
            contentInsetAdjustmentBehavior="automatic">    
            
            {saving &&
              <View>
                <ActivityIndicator size="large" color="#3F51B5" />
                <TraxxText style={styles.savingText}>Saving...</TraxxText>
              </View>
            }
            
            {loading &&
              <View>
                <ActivityIndicator size="large" color="#3F51B5" />
                <TraxxText style={styles.savingText}>Loading...</TraxxText>
              </View>
            }

            {!saving && <View>              
              <View style={styles.sectionContainer}>
                <View style={styles.sectionHeaderView}>
                  <TraxxText style={styles.sectionHeader}>Weight Preference</TraxxText>
                </View>
                <TraxxText style={styles.inputLabel}>Display weight in</TraxxText>
                <View style={{flexDirection: 'row'}}>
                  <Button style={metric == 0 ? styles.buttonOn : styles.buttonOff} onPress={() => updateMetric(0)} text="Tonne" />
                  <Button style={[metric == 1 ? styles.buttonOn : styles.buttonOff, {marginLeft: 10}]} onPress={() => updateMetric(1)} text="Cu. Meters" />
                </View>
              </View>          
              <View style={styles.sectionContainer}>
                <View style={styles.sectionHeaderView}>
                  <TraxxText style={styles.sectionHeader}>Account</TraxxText>
                </View>
                <TraxxText style={styles.inputLabel}>First name</TraxxText>
                <TraxxTextInput
                  style={styles.input}
                  placeholder="Enter your first name"
                  placeholderTextColor="#003f5c"
                  value={firstName}
                  onChangeText={(firstName: string) => setFirstName(firstName)}
                  returnKeyType={'next'}
                />
                <TraxxText style={styles.inputLabel}>Last name</TraxxText>                
                <TraxxTextInput
                  style={styles.input}
                  placeholder="Enter your last name"
                  placeholderTextColor="#003f5c"
                  value={lastName}
                  onChangeText={(lastName: string) => setLastName(lastName)}
                  returnKeyType={'next'}
                />
                <TraxxText style={styles.inputLabel}>Email address</TraxxText>
                <TraxxTextInput
                  style={styles.input}
                  placeholder="Enter your email"
                  placeholderTextColor="#003f5c"
                  keyboardType="email-address"
                  value={email}
                  onChangeText={(email: string) => setEmail(email)}
                  returnKeyType={'next'}
                />
              </View>                   
              {authState.role != "Driver" && authState.role != "Manager" && <View style={styles.sectionContainer}>
                <View style={styles.sectionHeaderView}>
                  <TraxxText style={styles.sectionHeader}>Organization</TraxxText>
                </View> 
                <TraxxText style={styles.inputLabel}>Name</TraxxText>
                <TraxxTextInput
                  style={styles.input}
                  placeholder="Enter your organization name"
                  placeholderTextColor="#003f5c"
                  value={organizationName}
                  onChangeText={(organizationName: string) => setOrganizationName(organizationName)}
                  returnKeyType={'next'}
                />
                <TraxxText style={styles.inputLabel}>Address</TraxxText>
                <TraxxTextInput
                  style={styles.input}
                  placeholder="Address"
                  placeholderTextColor="#003f5c"
                  value={organizationAddress}
                  onChangeText={(organizationAddress: string) => {
                    setOrganizationAddress(organizationAddress);
                  }}
                />
                <TraxxText style={styles.inputLabel}>City</TraxxText>
                <TraxxTextInput
                  style={styles.input}
                  placeholder="City"
                  placeholderTextColor="#003f5c"
                  value={organizationCity}
                  onChangeText={(organizationCity: string) => {
                    setOrganizationCity(organizationCity);
                  }}
                />
                <TraxxText style={styles.inputLabel}>Province</TraxxText>
                <TraxxTextInput
                  style={styles.input}
                  placeholder="Province"
                  placeholderTextColor="#003f5c"
                  value={organizationProvince}
                  onChangeText={(organizationProvince: string) => {
                    setOrganizationProvince(organizationProvince);
                  }}
                />
                <TraxxText style={styles.inputLabel}>Postal code</TraxxText>
                <TraxxTextInput
                  style={styles.input}
                  placeholder="Postal code"
                  placeholderTextColor="#003f5c"
                  value={organizationPostalCode}
                  onChangeText={(organizationPostalCode: string) => {
                    setOrganizationPostalCode(organizationPostalCode);
                  }}
                />
              </View>}
                  
              {authState.role == "Reuse" && <View style={styles.sectionContainer}>
                <View style={styles.sectionHeaderView}>
                  <TraxxText style={styles.sectionHeader}>Site Contact</TraxxText>
                </View> 
                <TraxxText style={styles.inputLabel}>Name</TraxxText>
                <TraxxTextInput
                  style={styles.input}
                  placeholder="Enter the contact person's name"
                  placeholderTextColor="#003f5c"
                  value={contactName}
                  onChangeText={(contactName: string) => setContactName(contactName)}
                  returnKeyType={'next'}
                />
                <TraxxText style={styles.inputLabel}>Phone number</TraxxText>
                <TraxxTextInput
                  style={styles.input}
                  placeholder="Enter a phone number"
                  placeholderTextColor="#003f5c"
                  keyboardType="phone-pad"
                  value={contactPhone}
                  onChangeText={(contactPhone: string) => setContactPhone(contactPhone)}
                  returnKeyType={'next'}
                />
                <TraxxText style={styles.inputLabel}>Email address (optional)</TraxxText>
                <TraxxTextInput
                  style={styles.input}
                  placeholder="Enter an email address (optional)"
                  placeholderTextColor="#003f5c"
                  keyboardType="email-address"
                  value={contactEmail}
                  onChangeText={(contactEmail: string) => setContactEmail(contactEmail)}
                  returnKeyType={'next'}
                />
              </View>}
    
              {subscribed && <View style={styles.sectionContainer}>
                <View style={styles.sectionHeaderView}>
                  <TraxxText style={styles.sectionHeader}>Subscription</TraxxText>
                </View>
                <TraxxText style={styles.inputLabel}>{subscriptionTitle}</TraxxText>
                <Button onPress={cancelSubscription}
                  style={[styles.button, {marginTop: 10}]}
                  text="Cancel Subscription">
                </Button>
              </View>}

              <View style={{flexDirection: 'row', alignSelf: 'center'}}>
                <Button onPress={savePreferences}
                  style={[styles.button, {marginTop: 30}]}
                  text="Save">
                </Button>

                <Button onPress={close}
                  style={[styles.button, {marginTop: 30, marginLeft: 10}]}
                  text="Cancel">
                </Button>
              </View>
            </View>}  
          </ScrollView>
  )
};

const width = Dimensions.get('window').width;
const height = Dimensions.get('window').height;

const styles = StyleSheet.create({
  inputContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 10,
  },

  inputView: {
    backgroundColor: "#fff",
    borderRadius: 30,
    width: "70%",
    height: 45,
    marginBottom: 20,
    alignItems: "center",
  },

  input: {
    height: 50,
    width: width - 20,
    paddingVertical: 10,
    paddingHorizontal: 20,
    margin: 10,
    flex: 1,
    borderColor: Colors.borderGrey,
    borderWidth: 1,
    borderRadius: 20,
    backgroundColor: Colors.white
  },

  inputLabel: {
    fontSize: 16,
    marginTop: 10,
    marginLeft: 5,
  },

  comingSoon: {
    fontSize: 20,
    color: "#fff"
  },

  button : {
    width: 260,
  },
  
  savingText: {
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold'
  },

  buttonOn: {
    backgroundColor: Colors.primaryBrown,
    color: Colors.borderDarkGrey,
    width: 125,
    margin: 5
  },

  buttonOff: {
    backgroundColor: Colors.primary,
    width: 125,
    margin: 5
  },

  preferenceButton: {
    width: (width / 2) - 10,
    margin: 5
  },

  sectionContainer: {
    width: width - 20,
    margin: 10
  },

  sectionHeader: {
    fontWeight: 'bold',
    fontSize: 20,
    flex: 1,
    marginHorizontal: 4,
    marginTop: 8,
  },
  
  sectionHeaderView: {
    flexDirection: 'row',
  },

  sectionHeaderButton : {
    margin: 4,
  },
});
export default PreferencesScene