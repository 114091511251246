import React from 'react'

import {
  StyleSheet,
  View,
  ScrollView,
  Dimensions,
  Text,
} from 'react-native';

import {useNavigation} from '@react-navigation/native';
import SiteSearch from '../components/site/SiteSearch';

interface Props {
}

const ReuseSiteScene: React.FC<Props> = ({ 
}) => {   
  const navigation = useNavigation();

  return (
    <ScrollView
      contentInsetAdjustmentBehavior="automatic">
        <SiteSearch
          onItemSelected={(data: any) => {  }}
          onCancel={() => {navigation.goBack()}}
          showAdd={true}
          verified={true}
          admin={true} />
    </ScrollView>
  )
};

const width = Dimensions.get('window').width;

const styles = StyleSheet.create({
  inputContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
 
  image: {
    flex: 1,
    width: 300,
    resizeMode: 'contain'
  },

  button: {
    width: width - 20,
    marginVertical: 5,
    marginHorizontal: 10,
  },

  signUpContainer: {
    marginTop: 60
  },

  title: {
    fontSize: 20,
    textAlign: 'center',
  },

  subTitle: {
    fontWeight: 'bold',
    marginTop: 20,
    textAlign: 'center',
    fontSize: 20,
  }
});

export default ReuseSiteScene